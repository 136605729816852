
import { defineComponent } from "@vue/runtime-core";

export default defineComponent({
	name: "SnackBar",
	props: {
		icon: {
			type: String,
			default: "",
		},
		message: {
			type: String,
			required: true,
		},
	},
});
