
import { defineComponent } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
export default defineComponent({
	name: "Toggle",
	props: {
		modelValue: [Boolean, Array],
		value: {
			default: null,
		},
		labelOnLeft: {
			type: Boolean,
			default: false,
		},
		overrideId: {
			type: String,
			default: "",
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		this.id = uuid4();
	},
	data() {
		return {
			id: "",
		};
	},
	computed: {
		proxyModelValue: {
			get() {
				return this.modelValue;
			},
			set(val: unknown) {
				this.$emit("update:modelValue", val);
			},
		},
	},
});
