import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex w-full items-center mb-12" }
const _hoisted_2 = { class: "mb-0 ml-2 mr-auto" }
const _hoisted_3 = { class: "flex w-full mt-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_TextInput = _resolveComponent("TextInput")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_Btn, {
        variant: 'icon',
        outline: true,
        class: "-ml-2"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: "material-icons-outlined",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeEditor')))
          }, " close")
        ]),
        _: 1
      }),
      _createElementVNode("h1", _hoisted_2, _toDisplayString(_ctx.isExistingNode
						? _ctx.$t("course_tree.update_topic_title")
						: _ctx.$t("course_tree.new_topic_title")), 1)
    ]),
    _createElementVNode("div", null, [
      _createVNode(_component_TextInput, {
        modelValue: _ctx.modelValue.name,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.onNodeChange('name', $event, false)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("course_tree.topic_node_name")), 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_Btn, {
        disabled: _ctx.blockingSaving || _ctx.modelValue.name.trim().length === 0,
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSave())),
        variant: 'primary-borderless',
        class: "ml-auto"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.blockingSaving
						? _ctx.$t("misc.wait")
						: _ctx.isExistingNode
						? _ctx.$t("course_tree.save")
						: _ctx.$t("course_tree.create")), 1)
        ]),
        _: 1
      }, 8, ["disabled"]),
      _createVNode(_component_Btn, {
        disabled: _ctx.blockingSaving,
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('closeEditor'))),
        variant: 'primary-borderless',
        class: "-mr-6"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("dialog.default_cancel_text")), 1)
        ]),
        _: 1
      }, 8, ["disabled"])
    ])
  ]))
}