
const SELECTED_LOCALE_KEY = "locale";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "./Btn.vue";
import DropdownMenu from "./DropdownMenu.vue";
export default defineComponent({
	name: "LocaleSelector",
	props: {},
	created() {
		if (SELECTED_LOCALE_KEY in localStorage) {
			this.$i18n.locale = localStorage.getItem(SELECTED_LOCALE_KEY) as string;
		}
	},
	methods: {
		onLocaleChange(locale: string) {
			this.$i18n.locale = locale;
			localStorage.setItem(SELECTED_LOCALE_KEY, locale);
			this.expanded = false;
		},
	},
	computed: {},
	components: { DropdownMenu },
	data() {
		return {
			expanded: false,
		};
	},
});
