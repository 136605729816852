
import { defineComponent, Ref, ref } from "@vue/runtime-core";
import Spinner from "./components/ui/Spinner.vue";
import Notification from "./components/ui/Notification.vue";
import { getTranslatedString as _ } from "./i18n";

import { debounce } from "lodash";
import { Course, CoursePrivilege } from "./models";
import Tooltip from "./components/ui/Tooltip.vue";
import SideBar from "./components/ui/SideBar.vue";
import { RouterView } from "vue-router";
import { SidebarOption } from "./navigation/sidebar";
import { metaStore } from "./store/meta";
import { isDemoMode } from "./utils";
import AppBar from "./components/ui/AppBar.vue";
import SnackBar from "./components/ui/SnackBar.vue";
import ErrorView from "./views/shared/ErrorView.vue";
import Btn from "./components/ui/Btn.vue";
import { Breadcrumbs } from "@sentry/browser/dist/integrations";
import BreadCrumbs from "./components/ui/BreadCrumbs.vue";
import DraggablePopup from "./components/ui/DraggablePopup.vue";
import GamificationContextPanel from "./components/student/GamificationContextPanel.vue";
import { coursePrivilegeMixin } from "./mixins";

//import { typesetTex } from "./utils";
const SIDEBAR_COLLASPED_LOCALSTORAGE = "sidebar_collapsed";
export default defineComponent({
	setup() {
		// TODO temporary workaround for a werid issue: if use import like this:
		//import { useMainStore } from "./stores/mainStore";
		// the app won't render as useMainStore will be undefined in other components; investigate
		const useMainStore = require("./stores/mainStore").useMainStore;
		const useMetaStore = require("./stores/metaStore").useMetaStore;
		const mainStore = useMainStore();
		const metaStore = useMetaStore();
		const logOut = require("./utils").logOut;

		return {
			mainStore,
			metaStore,
			logOut,
		};
	},
	beforeCreate(): void {
		this.metaStore.initStore();
	},
	mixins: [coursePrivilegeMixin],
	mounted() {
		// TODO refactor
		setTimeout(() => {
			this.routerViewPaddingLeft =
				document.getElementById("desktop-nav")?.clientWidth ?? 0;

			const sidebarCollapsedLocalStorage =
				localStorage.getItem(SIDEBAR_COLLASPED_LOCALSTORAGE) ?? "false";

			let sidebarCollapsed;
			try {
				sidebarCollapsed = JSON.parse(sidebarCollapsedLocalStorage);
			} catch {
				sidebarCollapsed = false;
			}

			this.initializingSidebar = true;
			this.sidebarCollapsed = sidebarCollapsed;
			// TODO use on animation end trigger
			setTimeout(() => (this.initializingSidebar = false), 50);
		}, 1);
		// adjust router view padding and width according to screen size breakpoint
		const mq = window.matchMedia("(min-width: 768px)");
		mq.addEventListener("change", event => (this.mediaQueryMd = mq.matches));
		this.mediaQueryMd = mq.matches;
	},
	beforeUnmount() {
		window.removeEventListener("beforeunload", this.beforeWindowUnload);
	},
	components: {
		Spinner,
		Notification,
		Tooltip,
		SideBar,
		AppBar,
		SnackBar,
		ErrorView,
		Btn,
		BreadCrumbs,
		DraggablePopup,
		GamificationContextPanel,
	},
	async created() {
		if (this.metaStore.isAuthenticated) {
			// TODO export to router - this is its concern
			try {
				await this.mainStore.getCourses();
				// redirect to student view if attempting to access a teacher view unprivileged,
				// or to unprivileged route specified in route meta
				if (!this.hasAnyPrivileges && this.isTeacherRoute) {
					const redirectTo = this.$route.meta.unprivilegedRedirect as string | undefined;
					this.$router.push({
						name:
							redirectTo ??
							(this.metaStore.user?.is_teacher
								? "TeacherCourseList"
								: "StudentCourseList"),
					});
				}
			} catch (e) {
				console.log("Caught", e);
			}
		}
		window.addEventListener("beforeunload", this.beforeWindowUnload);

		this.typesetTex = debounce(this.typesetTex, 100);
	},
	watch: {
		dirtyTex(newVal) {
			if (newVal) {
				this.typesetTex();
				this.metaStore.dirtyTex = false;
			}
		},
		$route(newVal) {
			if (this.showMobileSidebar) {
				this.onToggleMobileSidebar();
			}
		},
	},
	data() {
		return {
			showUnsavedChangesNotification: false,
			showGamificationPanel: false,
			showMobileSidebar: false,
			routerViewPaddingLeft: 0,
			mediaQueryMd: false,
			initializingSidebar: false,
			sidebarCollapsed: true,
		};
	},
	methods: {
		//logOut,
		onToggleCollapseSidebar() {
			this.sidebarCollapsed = !this.sidebarCollapsed;
			localStorage.setItem(
				SIDEBAR_COLLASPED_LOCALSTORAGE,
				JSON.stringify(this.sidebarCollapsed),
			);
		},
		onToggleMobileSidebar() {
			this.showMobileSidebar = !this.showMobileSidebar;
		},
		typesetTex() {
			(window as any).MathJax?.typeset?.();
		},
		beforeWindowUnload(e: { preventDefault: () => void; returnValue: string }) {
			if (
				this.metaStore.unsavedChanges &&
				!window.confirm(_("misc.confirm_exiting_unsaved_changes"))
			) {
				// Cancel the event
				e.preventDefault();
				// Chrome requires returnValue to be set
				e.returnValue = "";
			}
		},
	},
	computed: {
		showAppBar() {
			return this.$route.name !== "Login";
		},
		showFooter() {
			return this.$route.name !== "Login";
		},
		currentCourse() {
			return this.mainStore.getCourseById(
				this.$router.currentRoute.value.params.courseId as any as string,
			);
		},
		currentCourseName() {
			return this.currentCourse?.name ?? "";
		},
		sidebarOptions(): SidebarOption[] {
			const sidebarOptions = (this.$route.meta?.sidebarOptions ?? []) as SidebarOption[];

			return [
				...sidebarOptions.filter(
					r =>
						r.routeName !== "StudentCourseList" &&
						this.hasPrivileges(r.requiredPrivileges),
				),
				...(sidebarOptions.filter(r => r.routeName === "StudentCourseList").length === 1
					? [
							{
								...sidebarOptions.filter(r => r.routeName === "StudentCourseList")[0],
								// override path for course list for teachers so it goes to /teacher/courses
								// instead of /student/courses
								...(this.metaStore.user.is_teacher
									? { routeName: "TeacherCourseList" }
									: {}),
							},
					  ]
					: []),
			];
		},
		showSidebar() {
			return ((this.$route.meta?.sidebarOptions ?? []) as SidebarOption[]).length > 0;
		},
		dirtyTex() {
			return this.metaStore.dirtyTex;
		},
		isDemoMode() {
			return JSON.parse(process.env.VUE_APP_DEMO_MODE ?? "false");
		},
		hasAnyPrivileges(): boolean {
			if (this.$router.currentRoute.value.params.courseId) {
				// check user has privileges for course currrently visited
				const myPrivileges: CoursePrivilege[] =
					this.mainStore.courses.find(
						(c: Course) =>
							c.id == (this.$router.currentRoute.value.params.courseId ?? ""),
					)?.privileges ?? [];
				return myPrivileges.length > 0;
			}

			// check user has any privileges at all if not visiting a
			// course (i.e. in course list) or that the user is a teacher
			return (
				this.metaStore.user?.is_teacher ||
				(this.mainStore.courses as Course[])
					.map(c => c.privileges ?? [])
					.some(p => p.length > 0)
			);
		},
		isTeacherRoute(): boolean {
			return !!this.$route.meta.teacherRoute;
		},
		showHelpCenterButton() {
			return this.isTeacherRoute;
		},
		showLocaleSelector() {
			return true;
		},
		showSecondaryHeader(): boolean {
			// hide header in routes that have a sidebar
			return (
				!this.$route.matched.map(m => m.name).includes("StudentCourseDashboard") &&
				!this.isTeacherRoute &&
				this.$route.name !== "StudentCourseList" &&
				this.$route.name !== "Login"
			);
		},
	},
});
