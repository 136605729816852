import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Transition as _Transition, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      class: "tooltip w-max",
      onMouseover: _cache[4] || (_cache[4] = ($event: any) => (_ctx.allowHoverOnText ? _ctx.showText() : null)),
      onMouseleave: _cache[5] || (_cache[5] = ($event: any) => (_ctx.allowHoverOnText ? _ctx.hideText() : null))
    }, [
      (!_ctx.$slots.default)
        ? (_openBlock(), _createElementBlock("span", {
            key: 0,
            onMouseover: _cache[0] || (_cache[0] = ($event: any) => (!_ctx.allowHoverOnText ? _ctx.showText() : null)),
            onMouseleave: _cache[1] || (_cache[1] = ($event: any) => (!_ctx.allowHoverOnText ? _ctx.hideText() : null)),
            class: _normalizeClass([{
					'hover:bg-gray-200 hover:bg-opacity-80 rounded-md px-1.5 transition-colors duration-100 tooltip-handle': true,
				}, "text-base cursor-default select-none opacity-80 material-icons-outlined hover:text-primary-dark"])
          }, " help_outline ", 34))
        : (_openBlock(), _createElementBlock("div", {
            key: 1,
            onMouseover: _cache[2] || (_cache[2] = ($event: any) => (!_ctx.allowHoverOnText ? _ctx.showText() : null)),
            onMouseleave: _cache[3] || (_cache[3] = ($event: any) => (!_ctx.allowHoverOnText ? _ctx.hideText() : null)),
            class: "tooltip-handle"
          }, [
            _renderSlot(_ctx.$slots, "default")
          ], 32)),
      (_ctx.textCode || _ctx.textValue)
        ? (_openBlock(), _createBlock(_Transition, {
            key: 2,
            name: _ctx.noArrow ? 'tooltip-fade-bounce' : 'tooltip-fade'
          }, {
            default: _withCtx(() => [
              (_ctx.show)
                ? (_openBlock(), _createElementBlock("div", {
                    key: 0,
                    class: _normalizeClass(["z-20 max-w-xs md:max-w-max tooltip-text", {
						'tooltip-right': _ctx.placement === 'right',
						'tooltip-bottom': _ctx.placement === 'bottom',
						'tooltip-top': _ctx.placement === 'top',
						'tooltip-left': _ctx.placement === 'left',
						'tooltip-no-arrow tooltip': _ctx.noArrow,
					}])
                  }, [
                    _createElementVNode("p", null, _toDisplayString(_ctx.helpText), 1),
                    _renderSlot(_ctx.$slots, "body")
                  ], 2))
                : _createCommentVNode("", true)
            ]),
            _: 3
          }, 8, ["name"]))
        : _createCommentVNode("", true)
    ], 32)
  ]))
}