
import { defineComponent } from "@vue/runtime-core";
import moment from "moment";
import { v4 as uuid4 } from "uuid";

import DatePicker from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import "vue-datepicker-next/locale/it";

export default defineComponent({
	name: "CalendarInput",
	components: { DatePicker },
	props: ["modelValue"],
	created() {
		this.elementId = uuid4();
	},
	data() {
		return {
			calendarOpen: false,
			elementId: "",
			momentFormat: {
				//[optional] Date to String
				stringify: (date: moment.MomentInput) => {
					return date ? moment(date).format("LLL") : "";
				},
				//[optional]  String to Date
				parse: (value: moment.MomentInput) => {
					return value ? moment(value, "LLL").toDate() : null;
				},
				//[optional] getWeekNumber
				getWeek: (date: any) => {
					return; // a number
				},
			},
		};
	},
	methods: {
		onOpen() {
			this.calendarOpen = true;
			this.$emit("open");
		},
		onClose() {
			this.calendarOpen = false;
			this.$emit("close");
		},
		close() {
			this.calendarOpen = false;
		},
	},
	computed: {
		proxyModelValue: {
			get() {
				if (!this.modelValue) {
					return null;
				}
				if (typeof this.modelValue === "string") {
					// TODO move this to converter in API module
					return new Date(this.modelValue.replace(" ", "T"));
				}

				return this.modelValue;
			},
			set(val: unknown) {
				this.$emit("update:modelValue", val);
			},
		},
	},
});
