
import { Event, EventState } from "@/models";
import { defineComponent, inject, PropType } from "@vue/runtime-core";
import { icons as eventStateIcons } from "@/assets/eventStateIcons";
import { getTranslatedString as _ } from "@/i18n";
//import Card from "@/components/ui/Card.vue";
import Btn from "@/components/ui/Btn.vue";
import { getExamPermalink, getFormattedTimestamp } from "@/utils";
import Timestamp from "@/components/ui/Timestamp.vue";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";
import { loadingMixin } from "@/mixins";

export default defineComponent({
	components: {
		Btn,
		Timestamp,
		CopyToClipboard,
	},
	mixins: [loadingMixin],
	props: {
		modelValue: {
			type: Object as PropType<Event>,
			required: true,
		},
	},
	setup() {
		return {
			v$: inject("v$"),
		};
	},
	name: "EventStateEditor",
	data() {
		return {
			shakeErrorBanner: false,
		};
	},
	methods: {
		emitUpdate(value: EventState) {
			this.$emit("update:modelValue", value);
		},
		onInvalidSubmission() {
			(this.v$ as any).$touch();
			window.scrollTo(
				0,
				document.body.scrollHeight || document.documentElement.scrollHeight,
			);
			// scroll to bottom of page to account for error messages appearing
			// in order to keep error banner in view
			this.$nextTick(() =>
				window.scrollTo(
					0,
					document.body.scrollHeight || document.documentElement.scrollHeight,
				),
			);
			this.shakeErrorBanner = true;
		},
		publish() {
			this.emitUpdate(EventState.PLANNED);
		},
		revertToDraft() {
			this.emitUpdate(EventState.DRAFT);
		},
	},
	computed: {
		eventStateOptions() {
			return (Object.keys(EventState) as unknown[] as EventState[])
				.filter((key: string | number) => parseInt(key as string) == key) //(ExerciseType[key] as unknown) == 'number')
				.map(key => ({
					icons: eventStateIcons[key],
					value: key,
					content: _("event_states." + key),
					description: _("event_states_descriptions." + key),
				}));
		},
		isDraft() {
			return this.modelValue.state == EventState.DRAFT;
		},
		isPlanned() {
			return this.modelValue.state == EventState.PLANNED;
		},
		isOpen() {
			return this.modelValue.state == EventState.OPEN;
		},
		currentEventStateName() {
			return this.eventStateOptions[this.modelValue?.state]?.content?.toLowerCase();
		},
		currentEventStateDescription() {
			return this.eventStateOptions[this.modelValue?.state]?.description;
		},
		formattedTimestamp() {
			return getFormattedTimestamp(this.modelValue.begin_timestamp ?? "");
		},
		permalink(): string {
			return getExamPermalink(this.modelValue);
		},
		relevantErrors() {
			// remove the errors that are relative to event template rules as those
			// are displayed in the rule editor component
			return (
				(this.v$ as any).$errors
					// TODO find a less hacky way
					.filter(
						(e: { $uid: string }) =>
							![
								"rule_type-ruleTypeSet",
								"exercises-idBasedRulePopulated",
								"clauses-tagBasedRulePopulated",
								"satisfying-tagBasedRuleSatisfied",
							].includes(e.$uid.slice("modelValue".length + 1)),
					)
			);
		},
	},
});
