
import { v4 as uuid4 } from "uuid";

import { defineComponent, PropType } from "@vue/runtime-core";
export default defineComponent({
	name: "Timeline",
	props: {
		steps: {
			type: Array as PropType<{ eventType: any; timestamp: string; data: any }[]>,
			required: true,
		},
	},
	data() {
		return {
			id: uuid4(),
		};
	},
	methods: {},
	computed: {},
});
