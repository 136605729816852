import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "mt-4"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FullExercise = _resolveComponent("FullExercise")!
  const _component_ExerciseSolutionContainer = _resolveComponent("ExerciseSolutionContainer")!
  const _component_SkeletonCard = _resolveComponent("SkeletonCard")!

  return (!_ctx.firstLoading)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_FullExercise, { exercise: _ctx.exercise }, null, 8, ["exercise"]),
        _createVNode(_component_ExerciseSolutionContainer, {
          class: "mt-8",
          exercise: _ctx.exercise,
          solutions: _ctx.exerciseSolutions,
          showFirst: _ctx.solutionId ? [_ctx.solutionId] : [],
          standalone: true,
          onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadMore())),
          canLoadMore: !_ctx.paginatedExerciseSolutions.isLastPage
        }, null, 8, ["exercise", "solutions", "showFirst", "canLoadMore"])
      ]))
    : (_openBlock(), _createBlock(_component_SkeletonCard, { key: 1 }))
}