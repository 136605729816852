import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = {
  key: 0,
  class: "focus-within:text-lg material-icons-outlined text-muted -mb-2 pt-2.5",
  style: {"margin-left":"13px"}
}
const _hoisted_3 = {
  style: {"font-size":"20px !important"},
  class: "mx-auto text-lg material-icons-outlined text-success"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_Tooltip = _resolveComponent("Tooltip")!

  return (_openBlock(), _createElementBlock("div", {
    title: _ctx.$t('event_participation_states.' + _ctx.params.value),
    class: _normalizeClass([_ctx.params.value === _ctx.EventParticipationState.IN_PROGRESS ? 'mt-1' : 'mt-1.5'])
  }, [
    (_ctx.params.value == _ctx.EventParticipationState.IN_PROGRESS)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.participationStateIcons[_ctx.params.value as EventParticipationState][0]), 1))
      : (_openBlock(), _createBlock(_component_Tooltip, {
          key: 1,
          placement: 'top',
          noArrow: true,
          "text-value": _ctx.$t('event_monitor.undo_turn_in'),
          class: "fixed pl-1.5",
          style: {"z-index":"999999 !important"}
        }, {
          default: _withCtx(() => [
            _createVNode(_component_Btn, {
              variant: 'icon',
              outline: true
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.participationStateIcons[_ctx.params.value as EventParticipationState][0]), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["text-value"]))
  ], 10, _hoisted_1))
}