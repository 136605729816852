import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "mx-auto my-auto font-semibold" }
const _hoisted_2 = ["src"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showFallbackavatar || !_ctx.user.avatar_url)
    ? (_openBlock(), _createElementBlock("div", {
        key: 0,
        class: "flex rounded-full bg-primary-light text-primary bg-opacity-30",
        style: _normalizeStyle(_ctx.avatarSize)
      }, [
        _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.fullName[0].toLocaleUpperCase()), 1)
      ], 4))
    : (_openBlock(), _createElementBlock("div", {
        key: 1,
        style: _normalizeStyle(_ctx.avatarSize),
        class: "flex rounded-full overflow-hidden"
      }, [
        _createElementVNode("img", {
          referrerpolicy: "no-referrer",
          onError: _cache[0] || (_cache[0] = ($event: any) => (_ctx.showFallbackavatar = true)),
          src: _ctx.user.avatar_url
        }, null, 40, _hoisted_2)
      ], 4))
}