
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "./Btn.vue";
import LinearProgress from "./LinearProgress.vue";
export default defineComponent({
	name: "DropdownMenu",
	props: {
		tooltip: {
			type: String,
			default: "",
		},
		label: {
			type: String,
			default: "",
		},
		icon: {
			type: String,
			default: "more_vert",
		},
		trailingIcon: {
			type: String,
			default: "",
		},
		expanded: {
			type: Boolean,
			required: true,
		},
		placement: {
			type: String as PropType<"left" | "right">,
			default: "left",
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	methods: {},
	computed: {},
	components: { Btn, LinearProgress },
});
