
// TODO have a way to add permissions to users that haven't logged in for the first time yet
import { courseIdMixin, coursePrivilegeMixin, loadingMixin, savingMixin } from "@/mixins";
import { defineComponent } from "@vue/runtime-core";

import { getTranslatedString as _ } from "@/i18n";
import { CoursePrivilege, getBlankUser, User } from "@/models";
import { icons as coursePrivilegeIcons } from "@/assets/coursePrivilegeIcons";
import Dialog from "@/components/ui/Dialog.vue";
import CheckboxGroup from "@/components/ui/CheckboxGroup.vue";
import { SelectableOption } from "@/interfaces";
import { mapStores } from "pinia";
import { useMetaStore } from "@/stores/metaStore";
import { useMainStore } from "@/stores/mainStore";
import { getCurrentUserId, setErrorNotification, setPageWideError } from "@/utils";
import Combobox from "@/components/ui/Combobox.vue";
import Avatar from "@/components/ui/Avatar.vue";
import Tooltip from "@/components/ui/Tooltip.vue";
import Chipset from "@/components/ui/Chipset.vue";
import Btn from "@/components/ui/Btn.vue";
import { throttle } from "lodash";
import Spinner from "@/components/ui/Spinner.vue";

export default defineComponent({
	name: "CoursePermissions",
	components: {
		Dialog,
		CheckboxGroup,
		Combobox,
		Avatar,
		Tooltip,
		Chipset,
		Btn,
		Spinner,
	},
	mixins: [courseIdMixin, loadingMixin, savingMixin, coursePrivilegeMixin],
	async created() {
		await this.withLoading(async () => {
			// get all users with privileges
			await this.mainStore.getPrivilegedUsersForCourse({ courseId: this.courseId });
			// initially, fetch teachers to give some data
			// to start with when searching users
			await this.mainStore.getUsersForCourse({
				courseId: this.courseId,
				teachersOnly: true,
			});
		}, setPageWideError);
	},
	data() {
		return {
			showDialog: false,
			editingUserId: null as string | null,
			// used when editing privileges for a user that doesn't exist yet
			editingUserEmail: null as string | null,
			coursePrivilegeIcons,
			loadingUsers: false,
		};
	},
	methods: {
		hideDialog() {
			this.showDialog = false;
			this.editingUserId = null;
			this.editingUserEmail = null;
		},
		onShowUserPermissionsDialog(userId: string | null, email: string | null) {
			this.editingUserId = userId;
			this.editingUserEmail = email;
			this.showDialog = true;
		},
		isCourseCreator(user: User) {
			return this.currentCourse?.creator?.id == user.id;
		},
		getUserPermissionsAsOptions(user: User) {
			return (user.course_privileges ?? []).map(key => ({
				value: key,
				content: _("course_privileges_short." + key),
				icons: coursePrivilegeIcons[key],
			}));
		},
		async onSetUserPrivileges(
			userId: string | null,
			email: string | null,
			privileges: CoursePrivilege[],
		) {
			await this.withLoading(async () => {
				const updatedUser = await this.mainStore.updateUserCoursePrivileges({
					courseId: this.courseId,
					userId,
					email,
					privileges,
				});
				if (email !== null) {
					// user was just created using provided email address, now switch to editing
					// the newly created user instead of using the email address only
					this.editingUserId = updatedUser.id;
					this.editingUserEmail = null;
				}
			}, setErrorNotification);
		},
		async onRemoveUserPrivilege(user: User, privilege: CoursePrivilege) {
			await this.onSetUserPrivileges(
				user.id,
				null,
				(user.course_privileges ?? []).filter(p => p != privilege),
			);
		},
		// for combobox
		filterUser(search: string, userOption: SelectableOption) {
			const searchTokens = search.toLowerCase().split(/\s/);
			const user = this.mainStore.getUserById(userOption.value);
			const fullName = (user?.full_name ?? "").toLowerCase().replace(/\s/g, "");
			const email = (user?.email ?? "").toLowerCase().replace(/\s/g, "");

			return searchTokens.every(t => fullName.includes(t) || email.includes(t));
		},
		highlightMatchingText(search: string, text: string) {
			const words = text.split(/\s/);
			return words
				.map(w => {
					for (const searchWord of search.split(/\s/)) {
						const matchIndex = w.toLowerCase().indexOf(searchWord.toLowerCase());
						if (matchIndex !== -1) {
							return (
								w.substring(0, matchIndex) +
								`<strong class="font-bold">${w.substring(
									matchIndex,
									matchIndex + searchWord.length,
								)}</strong>` +
								w.substring(matchIndex + searchWord.length, w.length)
							);
						}
					}

					return w;
				})
				.join(" ");
		},
		async onUserSearch(search: string) {
			this.loadingUsers = true;
			await this.throttledGetUsers(search);
		},
		isValidEmailAddress(text: string) {
			// TODO improve to match django logic for validating an email
			const input = document.createElement("input");
			input.type = "email";
			input.required = true;
			input.value = text;
			return typeof input.checkValidity === "function"
				? input.checkValidity()
				: /\S+@\S+\.\S+/.test(text);
		},
		throttledGetUsers: throttle(async function (this: any, search) {
			try {
				await this.mainStore.getUsersForCourse({
					courseId: this.courseId,
					teachersOnly: false,
					search,
				});
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.loadingUsers = false;
			}
		}, 500),
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		editingUser(): User | undefined {
			if (this.editingUserId !== null) {
				// an actual user is being edited
				return this.mainStore.getUserById(this.editingUserId);
			}
			if (this.editingUserEmail !== null) {
				// a nonexistent user is being edited - the backend will create its account
				// as soon as the first request is issued using the email address
				return {
					...getBlankUser(),
					full_name: this.editingUserEmail,
					course_privileges: [],
				};
			}
			return undefined;
		},
		editingUserPrivileges: {
			get() {
				return this.editingUser?.course_privileges ?? [];
			},
			async set(val: CoursePrivilege[]) {
				await this.onSetUserPrivileges(this.editingUserId, this.editingUserEmail, val);
			},
		},
		privilegedUsers() {
			return [
				...this.mainStore.privilegedUsers.filter(u => this.isCourseCreator(u)),
				...this.mainStore.privilegedUsers
					.filter(u => !this.isCourseCreator(u) && (u.course_privileges ?? []).length > 0)
					.sort(
						(a, b) =>
							(b.course_privileges ?? []).length - (a.course_privileges ?? []).length,
					),
			];
		},
		usersAsOptions(): SelectableOption[] {
			return this.mainStore.paginatedUsers.data.map(u => ({
				value: u.id,
				content: u.full_name,
				data: u,
			}));
		},
		privilegesAsOptions(): SelectableOption[] {
			return Object.values(CoursePrivilege).map(key => ({
				value: key,
				content: _("course_privileges_short." + key),
				icons: coursePrivilegeIcons[key],
				description: _("course_privileges." + key),
			}));
		},
		canManagePermissions() {
			return this.hasPrivileges([CoursePrivilege.UPDATE_COURSE]);
		},
		currentUserId() {
			return getCurrentUserId();
		},
	},
});
