
/* eslint-disable @typescript-eslint/no-extra-semi */
/* eslint-disable @typescript-eslint/no-explicit-any */

import { adComponentMixin, courseIdMixin, loadingMixin } from "@/mixins";
import { defineComponent } from "@vue/runtime-core";

import EventParticipationPreview from "@/components/student/EventParticipationPreview.vue";
import SkeletonCard from "@/components/ui/SkeletonCard.vue";
import Card from "@/components/ui/Card.vue";
import { Event, EventParticipation, EventType, getBlankPractice } from "@/models";
import Dialog from "@/components/ui/Dialog.vue";
import PracticeTemplateEditor from "@/components/student/PracticeTemplateEditor.vue";
import { getTranslatedString as _ } from "@/i18n";
import { sum } from "lodash";
import { demoStudentTourSteps, MAX_PRACTICE_EXERCISE_COUNT, tourOptions } from "@/const";
import Btn from "@/components/ui/Btn.vue";
import VueEternalLoading from "@ts-pro/vue-eternal-loading/src/components/VueEternalLoading/VueEternalLoading.vue";
import Spinner from "@/components/ui/Spinner.vue";
import { LoadAction } from "@ts-pro/vue-eternal-loading";
import { EventParticipationSearchFilter } from "@/api";
import { isDemoMode, setErrorNotification } from "@/utils";
import { logAnalyticsEvent } from "@/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";

const DEMO_TOUR_KEY = "demo_student_tour_taken";

export default defineComponent({
	components: {
		EventParticipationPreview,
		SkeletonCard,
		Card,
		Dialog,
		PracticeTemplateEditor,
		Btn,
		VueEternalLoading,
		Spinner,
	},
	name: "PracticeList",
	mixins: [courseIdMixin, loadingMixin, adComponentMixin],
	async created() {
		await this.withFirstLoading(async () => {
			await this.mainStore.getTags({
				courseId: this.courseId,
				includeExerciseCount: true,
			});
			// await this.mainStore.getCourse({ courseId: this.courseId });
			await this.mainStore.getCourseEventParticipations({
				courseId: this.courseId,
				fromFirstPage: true,
				filter: {
					event_type: EventType.SELF_SERVICE_PRACTICE,
				} as EventParticipationSearchFilter,
			});
			if (isDemoMode() && !(DEMO_TOUR_KEY in localStorage)) {
				setTimeout(() => ((this as any).$tours["demoStudentTour"] as any).start(), 50);
				localStorage.setItem(DEMO_TOUR_KEY, "true");
			}
		});
	},
	data() {
		return {
			isInitialInfiniteLoad: false,
			isEditingRule: false,
			MAX_PRACTICE_EXERCISE_COUNT,
			showNotRecent: true,
			showBookmarkedOnly: false,
			loadingParticipations: new Set<string>(),
			tourOptions,
			demoStudentTourSteps,
		};
	},
	methods: {
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			try {
				const moreResults = await this.mainStore.getCourseEventParticipations({
					courseId: this.courseId,
					fromFirstPage: false,
					filter: {
						event_type: EventType.SELF_SERVICE_PRACTICE,
					} as EventParticipationSearchFilter,
				});

				if (!moreResults) {
					noMore();
				} else {
					loaded();
				}
			} catch {
				error();
			}
		},
		onBeginPractice(event: Event) {
			logAnalyticsEvent("beginPractice", { courseId: this.courseId });
			this.mainStore.setEditingEvent(null);
			this.$router.push({
				name: "PracticeParticipationPage",
				params: {
					examId: event.id,
				},
			});
		},
		onResumePractice(event: Event) {
			this.mainStore.setEditingEvent(event);
		},
		async onBookmark(participation: EventParticipation) {
			try {
				logAnalyticsEvent("bookmarkedPractice", { courseId: this.courseId });
				this.loadingParticipations.add(participation.id);
				await this.mainStore.partialUpdateEventParticipation({
					courseId: this.courseId,
					// TODO assumes participation contains Event
					eventId: participation.event.id,
					participationId: participation.id,
					changes: {
						bookmarked: !(participation.bookmarked ?? false),
					},
				});
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.loadingParticipations.delete(participation.id);
			}
		},
		async onCreatePractice() {
			logAnalyticsEvent("createPractice", { courseId: this.courseId });
			if (this.loading) {
				return;
			}
			if (this.currentCourse.public_exercises_count === 0) {
				// TODO if you use this.setErrorNotification, it's undefined - investigate
				setErrorNotification(_("student_course_dashboard.no_public_exercises"), true);
				return;
			}
			await this.withLoading(async () => {
				const newPracticeEvent = await this.mainStore.createEvent({
					courseId: this.courseId,
					event: getBlankPractice(),
				});
				this.mainStore.setEditingEvent(newPracticeEvent);
			}, setErrorNotification);
		},
	},
	computed: {
		...mapStores(useMainStore),
		filteredPracticeParticipations() {
			return this.mainStore.practiceParticipations.filter(
				(p, i) =>
					(this.showNotRecent || i < 3 || (p.bookmarked && this.showBookmarkedOnly)) &&
					(!this.showBookmarkedOnly || p.bookmarked),
			);
		},
		isResumingUnstartedPractice(): boolean {
			return (
				this.mainStore.editingEvent?.id ===
				this.currentCourse.unstarted_practice_events?.[0]?.id
			);
		},
		totalRuleAmount(): number {
			if (!this.mainStore.editingEvent) {
				return 0;
			}
			return sum(
				(this.mainStore.editingEvent as Event).template?.rules.map(r =>
					parseInt(String(r.amount)),
				),
			);
		},
	},
});
