
// ! TODO test, this requires migrating ExerciseEditor first
import { getTranslatedString as _ } from "@/i18n";
import { icons as exerciseTypesIcons } from "@/assets/exerciseTypesIcons";
import { icons as exerciseStatesIcons } from "@/assets/exerciseStatesIcons";
import {
	CoursePrivilege,
	Exercise,
	ExerciseState,
	ExerciseType,
	getBlankExercise,
} from "@/models";

import { VueEternalLoading, LoadAction } from "@ts-pro/vue-eternal-loading";
import { DialogData, SelectableOption } from "@/interfaces";
import Btn from "@/components/ui/Btn.vue";
import ExerciseEditorWrapper from "@/components/teacher/ExerciseEditor/ExerciseEditorWrapper.vue";
import { defineComponent } from "@vue/runtime-core";
import Spinner from "@/components/ui/Spinner.vue";
import ExerciseSearchFilters from "@/components/teacher/ExerciseSearchFilters.vue";
import {
	forceFileDownload,
	getClonedExercise,
	getDebouncedForFilter,
	setErrorNotification,
} from "@/utils";
import { courseIdMixin, coursePrivilegeMixin, loadingMixin } from "@/mixins";
import ExerciseEditorWrapperSkeleton from "@/components/ui/skeletons/ExerciseEditorWrapperSkeleton.vue";
import { getBlankExerciseSearchFilters, isEmptyFilter } from "@/api/utils";
import Dialog from "@/components/ui/Dialog.vue";
import ExerciseImporter from "@/components/teacher/ExerciseImporter.vue";
import DropdownMenu from "@/components/ui/DropdownMenu.vue";
import { exportCourseExercises } from "@/api";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
export default defineComponent({
	name: "CourseExercises",
	props: {
		modelValue: Array,
		value: {
			default: null,
		},
		options: Array,
	},
	mixins: [courseIdMixin, loadingMixin, coursePrivilegeMixin],
	watch: {
		searchFilter: {
			async handler() {
				this.isInitialInfiniteLoad = true;
				await this.onFilterChange();
			},
			deep: true,
		},
	},
	components: {
		ExerciseEditorWrapper,
		VueEternalLoading,
		Btn,
		Spinner,
		ExerciseSearchFilters,
		ExerciseEditorWrapperSkeleton,
		Dialog,
		ExerciseImporter,
		DropdownMenu,
	},
	async created() {
		this.onFilterChange = getDebouncedForFilter(this.onFilterChange);
		this.withFirstLoading(async () => {
			await this.mainStore.getExercises({
				courseId: this.courseId,
				fromFirstPage: true,
				filters: null,
			});
			await this.mainStore.getTags({
				courseId: this.courseId,
				includeExerciseCount: false,
			});
		});
	},
	mounted() {
		setTimeout(() => {
			if (this.$route.hash) {
				// TODO refactor and also handle possibility of editing exercises that are not in view
				const expandId = this.$route.hash.split(/#editor-(.*)/)[1];

				const editorRef: any =
					this.$refs["course-" + this.courseId + "-exercise-" + expandId]?.[0];
				if (editorRef) {
					editorRef.showEditor = true;
				}
			}
		}, 500);
	},
	data() {
		return {
			isInitialInfiniteLoad: false,
			expandResultFilter: true,
			searchFilter: getBlankExerciseSearchFilters(),
			showDialog: false,
			dialogData: {} as DialogData,
			showExerciseImporter: false,
			importedExercises: [] as Exercise[],
			importLoading: false,
			dropdownExpanded: false,
		};
	},
	methods: {
		getBlankExerciseSearchFilters,
		async onExportExercises() {
			await this.withLoading(async () => {
				const MAX_PAGE_SIZE = 999999;
				const exercises = (await exportCourseExercises(this.courseId)).data;
				forceFileDownload({ data: JSON.stringify(exercises, null, 4) }, "exercises.json");
			}, setErrorNotification);
		},
		async onFilterChange() {
			await this.withLoading(
				async () =>
					await this.mainStore.getExercises({
						courseId: this.courseId,
						fromFirstPage: true,
						filters: this.searchFilter,
					}),
			);
		},
		async onImportDone() {
			await this.withLoading(
				async () => {
					this.importLoading = true;
					await this.mainStore.bulkCreateExercises({
						courseId: this.courseId,
						exercises: this.importedExercises,
					});
					this.importLoading = false;
					this.metaStore.showSuccessFeedback();
					this.importedExercises = [];
					this.showExerciseImporter = false;
				},
				e => {
					setErrorNotification(e);
					this.importLoading = false;
				},
			);
		},
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			try {
				const moreResults = await this.mainStore.getExercises({
					courseId: this.courseId,
					fromFirstPage: false,
					filters: this.searchFilter,
				});
				if (!moreResults) {
					noMore();
				} else {
					loaded();
				}
			} catch {
				error();
			}
		},
		async onCloneExercise(exercise: Exercise) {
			const newExercise = await this.onAddExercise(getClonedExercise(exercise));
			// show notification
			this.metaStore.showSuccessFeedback();
			// open new editor
			document
				.getElementById("course-" + this.courseId + "-exercise-" + newExercise.id)
				?.scrollIntoView();
		},
		async onAddExercise(cloned?: Exercise): Promise<Exercise> {
			const wrapperFunc = cloned ? this.withLoading : this.withLocalLoading;
			return (await wrapperFunc(async () => {
				const newExercise = await this.mainStore.createExercise({
					courseId: this.courseId,
					exercise: cloned ?? getBlankExercise(),
				});

				const editorRef: any =
					this.$refs["course-" + this.courseId + "-exercise-" + newExercise.id]?.[0];
				editorRef.showEditor = true;

				return newExercise;
			})) as Exercise;
		},
		async onDeleteExercise(exercise: Exercise) {
			this.showDialog = true;
			if (exercise.state !== ExerciseState.DRAFT) {
				this.dialogData = {
					confirmOnly: true,
					text: _("course_exercises.cannot_delete_nondraft"),
					onYes: () => (this.showDialog = false),
				};
			} else {
				this.dialogData = {
					confirmOnly: false,
					text: _("course_exercises.confirm_delete_exercise"),
					onYes: async () => {
						await this.mainStore.deleteExercise({
							courseId: this.courseId,
							exerciseId: exercise.id,
						});
						this.showDialog = false;
					},
					onNo: () => (this.showDialog = false),
					yesText: _("misc.delete"),
					noText: _("dialog.default_cancel_text"),
					title: _("course_exercises.confirm_delete_exercise_title"),
				};
			}
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		emptyFilters() {
			return isEmptyFilter(this.searchFilter);
		},
		tagsOptions() {
			return this.mainStore.tags.map(t => ({
				value: t.name,
				content: t.name,
			}));
		},
		exerciseTypeOptions(): SelectableOption[] {
			return (Object.keys(ExerciseType) as unknown[] as ExerciseType[])
				.filter((key: string | number) => parseInt(key as string) == key) //(ExerciseType[key] as unknown) == 'number')
				.map(key => ({
					icons: exerciseTypesIcons[key],
					value: key,
					content: _("exercise_types." + key),
				}));
		},
		exerciseStateOptions(): SelectableOption[] {
			return (Object.keys(ExerciseState) as unknown[] as ExerciseState[])
				.filter((key: string | number) => parseInt(key as string) == key) //(ExerciseType[key] as unknown) == 'number')
				.map(key => ({
					icons: exerciseStatesIcons[key],
					value: key,
					content: _("exercise_states." + key),
					description: _("exercise_states_descriptions." + key),
				}));
		},
		canCreateAndEditExercises() {
			return this.hasPrivileges([CoursePrivilege.MANAGE_EXERCISES]);
		},
	},
});
