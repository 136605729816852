import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "card shadow-elevation hover-shadow-elevation-2" }
const _hoisted_2 = { class: "mb-4 user-content" }
const _hoisted_3 = { key: 0 }
const _hoisted_4 = { key: 1 }
const _hoisted_5 = { class: "w-1/2 text-lg text-muted" }
const _hoisted_6 = { class: "ml-4" }
const _hoisted_7 = { key: 2 }
const _hoisted_8 = { class: "flex card card-border" }
const _hoisted_9 = { class: "w-10/12 overflow-auto max-h-36" }
const _hoisted_10 = { class: "w-2/12 ml-auto text-right" }
const _hoisted_11 = { key: 0 }
const _hoisted_12 = {
  key: 3,
  class: "grid grid-cols-1 md:grid-cols-2 md:gap-18"
}
const _hoisted_13 = { class: "ml-1" }
const _hoisted_14 = { key: 4 }
const _hoisted_15 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ProcessedTextFragment = _resolveComponent("ProcessedTextFragment")!
  const _component_Bar = _resolveComponent("Bar")!
  const _component_ExerciseTestCase = _resolveComponent("ExerciseTestCase")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ProcessedTextFragment, {
        value: _ctx.exercise.text
      }, null, 8, ["value"])
    ]),
    (_ctx.isMultipleChoice)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Bar, {
            "chart-data": _ctx.selectedChoicesFrequencyChartData,
            "chart-options": _ctx.exerciseChoicesBarChartOptions,
            height: _ctx.mediaQueryMdMatches ? 30 : 100,
            width: 100
          }, null, 8, ["chart-data", "chart-options", "height"])
        ]))
      : (_ctx.isProgrammingExercise)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createVNode(_component_Bar, {
              "chart-data": _ctx.programmingExerciseScoreFrequencyChartData,
              "chart-options": _ctx.passedTestCasesBarChartOptions,
              height: _ctx.mediaQueryMdMatches ? 30 : 100,
              width: 100
            }, null, 8, ["chart-data", "chart-options", "height"]),
            _createElementVNode("div", null, [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(Object.entries(
						_ctx.programmingExerciseScoresFrequency.testCasePassingRate,
					), (record) => {
                return (_openBlock(), _createElementBlock("div", {
                  key: record[0] + '-passed-data',
                  class: "flex items-center my-6 space-x-12"
                }, [
                  _createVNode(_component_ExerciseTestCase, {
                    class: "w-full",
                    showDescription: false,
                    small: true,
                    testCase: _ctx.exercise.testcases?.find(t => t.id == record[0])
                  }, null, 8, ["testCase"]),
                  _createElementVNode("div", _hoisted_5, [
                    _createTextVNode(_toDisplayString(_ctx.$t("event_stats.testcase_passed_in")) + " ", 1),
                    _createElementVNode("strong", null, _toDisplayString(record[1]), 1),
                    _createTextVNode(" " + _toDisplayString(_ctx.$t("event_stats.submissions")) + " " + _toDisplayString(_ctx.$t("misc.out_of")) + " " + _toDisplayString(_ctx.slots.length) + " ", 1),
                    _createElementVNode("strong", _hoisted_6, "(" + _toDisplayString(Math.floor((record[1] / _ctx.slots.length) * 1000) / 10) + "%)", 1)
                  ])
                ]))
              }), 128))
            ])
          ]))
        : (_ctx.isOpenAnswerExercise)
          ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
              _createElementVNode("h3", null, _toDisplayString(_ctx.$t("event_stats.answers")), 1),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.slots, (slot) => {
                return (_openBlock(), _createElementBlock("div", {
                  class: "my-4",
                  key: 'stats-slot-' + slot.id
                }, [
                  _createElementVNode("div", _hoisted_8, [
                    _createElementVNode("div", _hoisted_9, [
                      _createVNode(_component_ProcessedTextFragment, {
                        value: slot.answer_text,
                        defaultValue: _ctx.$t('misc.no_answer')
                      }, null, 8, ["value", "defaultValue"])
                    ]),
                    _createElementVNode("div", _hoisted_10, [
                      _createElementVNode("p", {
                        class: _normalizeClass({ 'text-muted': slot.score === null })
                      }, [
                        _createTextVNode(_toDisplayString(slot.score !== null ? slot.score : _ctx.$t("event_stats.not_yet_assessed")) + " ", 1),
                        (slot.score !== null)
                          ? (_openBlock(), _createElementBlock("span", _hoisted_11, _toDisplayString(slot.score === 1 ? _ctx.$t("misc.scored_singular") : _ctx.$t("misc.scored_plural")), 1))
                          : _createCommentVNode("", true)
                      ], 2)
                    ])
                  ])
                ]))
              }), 128))
            ]))
          : (_ctx.isClozeExercise)
            ? (_openBlock(), _createElementBlock("div", _hoisted_12, [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.clozeSubExercisesChoicesFrequencyChartData, (subExerciseData, index) => {
                  return (_openBlock(), _createElementBlock("div", {
                    key: 'cloze-' + index + 'data'
                  }, [
                    _createElementVNode("h5", _hoisted_13, _toDisplayString(_ctx.$t("event_stats.cloze_stats_title")) + " " + _toDisplayString(index + 1), 1),
                    _createVNode(_component_Bar, {
                      "chart-data": subExerciseData,
                      "chart-options": _ctx.exerciseChoicesBarChartOptions,
                      height: _ctx.mediaQueryMdMatches ? 30 : 100,
                      width: 100
                    }, null, 8, ["chart-data", "chart-options", "height"])
                  ]))
                }), 128))
              ]))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, [
                _createElementVNode("p", _hoisted_15, _toDisplayString(_ctx.$t("event_stats.no_stats_available_for_exercise")), 1)
              ]))
  ]))
}