
const RUN_COOL_DOWN = 10;

import { programmingExerciseTabsOptions, ProgrammingExerciseTabs } from "@/const";
import {
	EventParticipationSlotSubmission,
	Exercise,
	ExerciseType,
	getEmptySubmission,
	ProgrammingExerciseType,
	programmingExerciseTypeToLanguageId,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import { loadingMixin, texMixin } from "@/mixins";
import { SelectableOption } from "@/interfaces";
import Tabs from "@/components/ui/Tabs.vue";
import CodeEditor from "@/components/ui/CodeEditor.vue";
import CodeExecutionResults from "../CodeExecutionResults.vue";
import Spinner from "@/components/ui/Spinner.vue";
import ExerciseTestCase from "../ExerciseTestCase.vue";
import { exerciseProps } from "./shared";
import AbstractExercise from "./AbstractExercise.vue";
import CodeFragment from "@/components/ui/CodeFragment.vue";
export default defineComponent({
	name: "ProgrammingExercise",
	mixins: [loadingMixin, texMixin],
	props: {
		...exerciseProps,
		running: {
			type: Boolean,
		},
	},
	data() {
		return {
			ProgrammingExerciseTabs,
			programmingExerciseTabsOptions,
			currentTab: ProgrammingExerciseTabs.TEXT,
			showExecutingMessage: false,
			runCoolDown: 0,
			ExerciseType,
		};
	},
	watch: {
		isRunning(newVal, oldVal) {
			if (oldVal && !newVal) {
				this.currentTab = ProgrammingExerciseTabs.EXECUTION_RESULTS;
			}
		},
	},
	methods: {
		onRun() {
			this.$emit("runCode");
			this.runCoolDown = RUN_COOL_DOWN;
			const coolDownHandle = setInterval(() => {
				this.runCoolDown--;
				if (this.runCoolDown <= 0) {
					clearInterval(coolDownHandle);
				}
			}, 1000);
		},
	},
	computed: {
		proxyModelValue: {
			get() {
				return this.submission.answer_text;
			},
			set(val: string) {
				this.$emit("updateSubmission", ["answer_text", val]);
			},
		},
		filteredTabsOptions(): SelectableOption[] {
			return this.programmingExerciseTabsOptions.filter(
				o => !this.readOnly || o.value === ProgrammingExerciseTabs.TEXT,
			);
		},
		executionState() {
			return this.submission.execution_results?.state;
		},
		languageCode() {
			return programmingExerciseTypeToLanguageId[
				this.exercise.exercise_type as ProgrammingExerciseType
			];
		},
		isRunning() {
			return this.running || this.executionState === "running";
		},
	},
	components: {
		Tabs,
		CodeEditor,
		CodeExecutionResults,
		Spinner,
		ExerciseTestCase,
		AbstractExercise,
		CodeFragment,
	},
});
