
import { defineComponent } from "@vue/runtime-core";
import CourseListItem from "@/components/shared/CourseListItem.vue";

import { loadingMixin } from "@/mixins";
import CourseListItemSkeleton from "@/components/ui/skeletons/CourseListItemSkeleton.vue";
import NumberInput from "@/components/ui/NumberInput.vue";
import Btn from "@/components/ui/Btn.vue";
import { Course } from "@/models";
import { getBlankCourseSearchFilters } from "@/api/utils";
import { CourseSearchFilter } from "@/api/interfaces";
import CourseSearchFilters from "@/components/shared/CourseSearchFilters.vue";
import { demoCourseTourSteps, tourOptions } from "@/const";
import { isDemoMode } from "@/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";

const DEMO_COURSES_TOUR_KEY = "courses_tour_taken";

export default defineComponent({
	name: "CourseList",
	mixins: [loadingMixin],
	components: {
		CourseListItem,
		CourseListItemSkeleton,
		NumberInput,
		Btn,
		CourseSearchFilters,
	},
	async created() {
		await this.withFirstLoading(async () => this.mainStore.getCourses());
		this.searchFilters.withPrivileges = this.metaStore.user.is_teacher;
		this.searchFilters.hidden = this.metaStore.user.is_teacher;

		if (isDemoMode() && !(DEMO_COURSES_TOUR_KEY in localStorage)) {
			setTimeout(() => ((this as any).$tours["demoCourseTour"] as any).start(), 50);
			localStorage.setItem(DEMO_COURSES_TOUR_KEY, "true");
		}
	},
	data() {
		return {
			dirtyMat: "",
			searchFilters: getBlankCourseSearchFilters(),
			demoCourseTourSteps,
			tourOptions,
		};
	},
	watch: {
		// searchFilters: {
		//   handler(newVal) {},
		//   deep: true,
		// },
	},
	methods: {
		async onSaveMat() {
			await this.withLocalLoading(async () =>
				this.metaStore.patchUser({
					userId: this.metaStore.user.id,
					changes: { mat: this.dirtyMat },
				}),
			);
			this.metaStore.showSuccessFeedback();
		},
		async onCourseToggleFavorite(course: Course) {
			const remove = !!course.bookmarked;
			await this.mainStore.bookmarkCourse({ courseId: course.id, remove });
			// TODO if adding a bookmark and courses moves out of view, show some kind of feedback
		},
		sortCourses(courses: Course[]) {
			return courses.sort((a, b) => {
				if (a.creator?.id == this.metaStore.user.id) {
					// courses the user is creator of go first
					return b.creator?.id == this.metaStore.user.id ? 0 : -1;
				}
				return b.creator?.id == this.metaStore.user.id
					? 1
					: // then come the courses that user has privileges over
					  (b.privileges?.length ?? 0) - (a.privileges?.length ?? 0) ||
							// as a last resort, sort by id
							(a.id < b.id ? -1 : 1);
			});
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		isDemoMode() {
			return isDemoMode();
		},
		coursesFiltered() {
			const filters = this.searchFilters;
			return this.coursesSorted.filter(
				c =>
					(filters.name.length === 0 ||
						c.name.toLowerCase().includes(filters.name.toLowerCase()) ||
						(c.creator?.full_name ?? "")
							.toLowerCase()
							.includes(filters.name.toLowerCase())) &&
					(!filters.withPrivileges || (c.privileges?.length ?? 0) > 0) &&
					(filters.hidden || !c.hidden),
			);
		},
		coursesSorted() {
			const bookmarkedCourses = this.mainStore.courses.filter(c => c.bookmarked);
			const otherCourses = this.mainStore.courses.filter(c => !c.bookmarked);
			return [...this.sortCourses(bookmarkedCourses), ...this.sortCourses(otherCourses)];
		},
	},
});
