import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AbstractEventParticipationSlot = _resolveComponent("AbstractEventParticipationSlot")!

  return (_openBlock(), _createBlock(_component_AbstractEventParticipationSlot, {
    modelValue: _ctx.exerciseWrapperSlot,
    allowEditAssessment: false,
    allowEditSubmission: false,
    showAnswer: false,
    showTags: _ctx.showTags
  }, null, 8, ["modelValue", "showTags"]))
}