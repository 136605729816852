
const RUN_COOL_DOWN = 10;

import { defineComponent, PropType } from "@vue/runtime-core";
import TextEditor from "@/components/ui/TextEditor.vue";
import {
	CodeExecutionResults as ICodeExecutionResults,
	Exercise,
	ExerciseSolution,
	ExerciseSolutionState,
} from "@/models";
import Btn from "@/components/ui/Btn.vue";
import CloudSaveStatus from "@/components/ui/CloudSaveStatus.vue";
import { mediaQueryMixin, texMixin } from "@/mixins";
import CodeEditor from "@/components/ui/CodeEditor.vue";
import FullExercise from "../FullExercise.vue";
import { ProgrammingExerciseTabs, programmingExerciseTabsOptions } from "@/const";
import CodeExecutionResults from "../CodeExecutionResults.vue";
import SegmentedControls from "@/components/ui/SegmentedControls.vue";
import Spinner from "@/components/ui/Spinner.vue";

export default defineComponent({
	name: "ExerciseSolutionEditor",
	mixins: [texMixin, mediaQueryMixin],
	props: {
		modelValue: {
			type: Object as PropType<ExerciseSolution>,
			required: true,
		},
		exercise: {
			type: Object as PropType<Exercise>,
			required: true,
		},
		saving: {
			type: Boolean,
			required: true,
		},
		savingError: {
			type: Boolean,
			required: true,
		},
		editorType: {
			type: String as PropType<"text" | "typescript" | "c" | "python">,
			required: true,
		},
		publishing: {
			type: Boolean,
			default: false,
		},
	},
	beforeUnmount() {
		const bodyContainsOverflowHidden =
			document.body.classList.contains("overflow-y-hidden");
		if (bodyContainsOverflowHidden) {
			document.body.classList.remove("overflow-y-hidden");
		}
		this.ws?.close();
	},
	created() {
		// prevent scrolling of the underlying page when open
		const bodyContainsOverflowHidden =
			document.body.classList.contains("overflow-y-hidden");
		if (!bodyContainsOverflowHidden) {
			document.body.classList.add("overflow-y-hidden");
		}
		this.triggerTexRender();
	},
	methods: {
		onUpdate<K extends keyof ExerciseSolution>(key: K, value: ExerciseSolution[K]) {
			this.$emit("updateSolution", { key, value });
		},
		onSubmit() {
			this.onUpdate(
				"state",
				this.modelValue.state === ExerciseSolutionState.DRAFT
					? ExerciseSolutionState.SUBMITTED
					: this.modelValue.state,
			);
		},
		// async runCode() {
		// 	const taskId = uuid4();
		// 	const taskMessage = {
		// 		task_id: taskId,
		// 		exercise_id: this.exercise.id,
		// 		code: this.modelValue.content,
		// 		action: "run_code",
		// 	};
		// 	this.runCoolDown = RUN_COOL_DOWN;
		// 	this.intervalHandle = setInterval(() => {
		// 		this.runCoolDown--;
		// 		if (this.runCoolDown === 0) {
		// 			clearInterval(this.intervalHandle as number);
		// 			this.intervalHandle = null;
		// 		}
		// 	}, 1000);
		// 	this.runningCode = true;
		// 	this.ws = await openAuthenticatedWsConnection(
		// 		"code_runner",
		// 		s => s.send(JSON.stringify(taskMessage)),
		// 		m => {
		// 			const payload = JSON.parse(m.data);
		// 			if (payload.action === "execution_results") {
		// 				this.executionResults = JSON.parse(payload.data);
		// 				this.runningCode = false;
		// 				this.currentTab = ProgrammingExerciseTabs.EXECUTION_RESULTS;
		// 			}
		// 		},
		// 	);
		// },
	},
	data() {
		return {
			ExerciseSolutionState,
			runCoolDown: 0,
			runningCode: false,
			ws: null as null | WebSocket,
			executionResults: {} as ICodeExecutionResults,
			ProgrammingExerciseTabs,
			programmingExerciseTabsOptions: programmingExerciseTabsOptions.filter(o =>
				[
					ProgrammingExerciseTabs.TEXT,
					ProgrammingExerciseTabs.EXECUTION_RESULTS,
				].includes(o.value),
			),
			currentTab: ProgrammingExerciseTabs.TEXT,
			intervalHandle: null as null | number,
		};
	},
	computed: {},
	components: {
		TextEditor,
		Btn,
		CloudSaveStatus,
		CodeEditor,
		FullExercise,
		CodeExecutionResults,
		SegmentedControls,
		Spinner,
	},
});
