
import { defineComponent, PropType } from "@vue/runtime-core";
import SegmentedControls from "./SegmentedControls.vue";
export default defineComponent({
	name: "Tabs",
	props: ["options", "modelValue", "disabled"],
	methods: {},
	computed: {},
	components: { SegmentedControls },
});
