import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SegmentedControls = _resolveComponent("SegmentedControls")!

  return (_openBlock(), _createBlock(_component_SegmentedControls, {
    options: _ctx.options,
    modelValue: _ctx.modelValue,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('update:modelValue', $event)))
  }, null, 8, ["options", "modelValue"]))
}