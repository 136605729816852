
import Dialog from "@/components/ui/Dialog.vue";
import { EventTemplateRule, EventTemplateRuleType, Exercise } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "@/components/ui/Btn.vue";
import ExercisePicker from "@/components/teacher/ExercisePicker.vue";
import MinimalExercisePreview from "../ExerciseEditor/MinimalExercisePreview.vue";
import { getExercisesById } from "@/api/exercises";
import SkeletonCard from "@/components/ui/SkeletonCard.vue";
import { courseIdMixin, loadingMixin } from "@/mixins";
import TagBasedEventTemplateRuleEditor from "./TagBasedEventTemplateRuleEditor.vue";
import Tag from "@/components/ui/Tag.vue";
import { getTranslatedString as _ } from "@/i18n";
import useVuelidate from "@vuelidate/core";
import { eventTemplateRuleValidation } from "@/validation/models";
import NumberInput from "@/components/ui/NumberInput.vue";
import Tooltip from "@/components/ui/Tooltip.vue";
import { setErrorNotification } from "@/utils";

export default defineComponent({
	components: {
		Dialog,
		Btn,
		ExercisePicker,
		MinimalExercisePreview,
		SkeletonCard,
		TagBasedEventTemplateRuleEditor,
		Tag,
		NumberInput,
		Tooltip,
	},
	name: "EventTemplateRuleEditor",
	setup() {
		return {
			v$: useVuelidate(),
		};
	},
	validations() {
		return { modelValue: eventTemplateRuleValidation };
	},
	watch: {
		"modelValue.exercises": {
			deep: true,
			async handler(newVal: string[]) {
				console.log("Changed", newVal);
				// keep local exercises synced with rule's exercises
				const strNewVal = newVal.map(i => String(i));
				const localExercisesIds = this.previewExercises.map(e => String(e.id));

				const toFetch = strNewVal.filter(i => !localExercisesIds.includes(i));
				const toRemove = localExercisesIds.filter(i => !strNewVal.includes(i));

				try {
					this.loadingPreview = true;
					if (toFetch.length > 0) {
						const fetched = await getExercisesById(this.courseId, toFetch);
						this.previewExercises.push(...fetched);
					}
					this.loadingPreview = false;
				} catch (e) {
					setErrorNotification(e);
				}

				this.previewExercises = this.previewExercises.filter(
					e => !toRemove.includes(String(e.id)),
				);
			},
		},
	},
	props: {
		modelValue: {
			type: Object as PropType<EventTemplateRule>,
			required: true,
		},
		globallySelectedExercises: {
			type: Object as PropType<string[]>,
			required: true,
		},
		randomOrder: {
			type: Boolean,
			required: true,
		},
		ordering: {
			type: Number,
			required: true,
		},
		lockRuleType: {
			type: Boolean,
			default: false,
		},
		expanded: {
			type: Boolean,
			default: true,
		},
		reduced: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [courseIdMixin, loadingMixin],
	// async created() {
	// 	// fetch exercises related to this rule
	// 	if (
	// 		this.modelValue.rule_type == EventTemplateRuleType.ID_BASED &&
	// 		(this.modelValue.exercises?.length ?? 0) > 0
	// 	) {
	// 		this.loadingPreview = true;
	// 		try {
	// 			this.previewExercises = await getExercisesById(
	// 				this.courseId,
	// 				this.modelValue.exercises as string[],
	// 			);
	// 		} catch (e) {
	// 			this.setErrorNotification(e);
	// 		} finally {
	// 			this.loadingPreview = false;
	// 		}
	// 	}
	// },
	data() {
		return {
			showDialog: false,
			// rule is ID-based and only one exercise is allowed (same for everyone)
			pickOneExerciseOnly: null as boolean | null,
			previewExercises: [] as Exercise[],
			EventTemplateRuleType,
			loadingPreview: false,
		};
	},
	methods: {
		onCloseDialog() {
			this.showDialog = false;
			this.$emit("ruleDialogClose");
			this.v$.$touch();
		},
		emitUpdate(key: keyof EventTemplateRule, value: unknown) {
			this.$emit("updateRule", {
				field: key,
				value,
			});
		},
		async onAddExercise(exercise: Exercise) {
			if (this.pickOneExerciseOnly) {
				this.emitUpdate("exercises", [exercise.id]);
			} else {
				this.emitUpdate("exercises", [
					exercise.id,
					...(this.modelValue.exercises as string[]),
				]);
			}
			// fetch exercise from server and add to local array for preview
			// const preview = await getExercisesById(this.courseId, [exercise.id]);
			// this.previewExercises.push(...preview);
		},
		onRemoveExercise(exercise: Exercise) {
			// emit modelValue update of new exercise list without removed one
			this.emitUpdate(
				"exercises",
				this.modelValue?.exercises?.filter(e => e != exercise.id),
			);
			// remove exercise from local array of exercises for preview
			//this.previewExercises = this.previewExercises.filter(e => e.id != exercise.id);
		},
		showRuleDialog() {
			this.showDialog = true;
		},
		setRuleMode(ruleType: EventTemplateRuleType, pickOne = false) {
			if (
				ruleType === null &&
				!confirm(_("event_template_rule_editor.unset_rule_type_confirmation"))
			) {
				return;
			}
			// TODO if ruleType === null reset slot (clear exercises and clauses)
			this.emitUpdate("rule_type", ruleType);
			this.pickOneExerciseOnly = pickOne;
		},
	},
	computed: {
		displayedOrdering(): String {
			/**
			 * Returns the appropriate slot order text for the rule: if all
			 * rules have amount === 1, it's simply the target slot number,
			 * otherwise it takes into account the actual amount of exercises
			 * that precede this rule (sum of amounts of preceding rules) and
			 * the amount of this rule
			 */
			const baseOrdering = this.ordering + 1;
			if (this.modelValue.amount === 1) {
				return String(baseOrdering);
			}
			return (
				_("misc.from") +
				" " +
				baseOrdering +
				" " +
				_("misc.to") +
				" " +
				// make sure we're dealing with numbers
				(parseInt(String(baseOrdering)) + parseInt(String(this.modelValue.amount)) - 1)
			);
		},
		ruleExercises(): Exercise[] {
			if (this.modelValue.rule_type != EventTemplateRuleType.ID_BASED) {
				return [];
			}
			return this.previewExercises;
		},
		isSlotPopulated() {
			if (this.modelValue.rule_type == EventTemplateRuleType.ID_BASED) {
				return (this.modelValue.exercises?.length ?? 0) > 0;
			}
			if (this.modelValue.rule_type == EventTemplateRuleType.TAG_BASED) {
				// there's at least a non-empty clause
				return (
					(this.modelValue.clauses?.length ?? 0) > 0 &&
					this.modelValue.clauses?.some(c => c.tags.length > 0)
				);
			}
			return false;
		},
	},
});
