
import { EventParticipationSlot } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "@/components/ui/Btn.vue";
export default defineComponent({
	name: "EventParticipationSlotScoreRenderer",
	props: {
		params: {
			type: Object as PropType<{ value: EventParticipationSlot }>,
			required: true,
		},
	},
	methods: {},
	computed: {
		hasAnswer() {
			return (
				// TODO add has_answer to interface
				(this.params.value as any).has_answer
			);
		},
	},
	components: {},
});
