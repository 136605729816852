import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = ["innerHTML"]
const _hoisted_2 = { class: "text-xs text-muted" }
const _hoisted_3 = { class: "text-xs text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CodeFragment = _resolveComponent("CodeFragment")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex flex-col space-y-4", { 'text-sm': _ctx.small }])
  }, [
    (!!_ctx.testCase.text && _ctx.showDescription)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["w-full mb-4 user-content", [_ctx.small ? 'py-2.5 mt-2' : '']]),
          innerHTML: _ctx.testCase.text
        }, null, 10, _hoisted_1))
      : _createCommentVNode("", true),
    (!!_ctx.testCase.code)
      ? (_openBlock(), _createBlock(_component_CodeFragment, {
          key: 1,
          collapsible: true,
          small: _ctx.small,
          class: "w-full",
          value: _ctx.testCase.code
        }, null, 8, ["small", "value"]))
      : _createCommentVNode("", true),
    (!!_ctx.testCase.stdin)
      ? (_openBlock(), _createElementBlock("div", {
          key: 2,
          class: _normalizeClass([[!!_ctx.testCase.text ? 'md:w-1/2 w-full' : 'w-full'], "flex flex-col"])
        }, [
          _createElementVNode("div", _hoisted_2, _toDisplayString(_ctx.$t("programming_exercise.testcase_stdin")), 1),
          _createVNode(_component_CodeFragment, {
            collapsible: true,
            small: _ctx.small,
            value: _ctx.testCase.stdin
          }, null, 8, ["small", "value"])
        ], 2))
      : _createCommentVNode("", true),
    (!!_ctx.testCase.expected_stdout)
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          class: _normalizeClass([[!!_ctx.testCase.text ? 'md:w-1/2 w-full' : 'w-full'], "flex flex-col"])
        }, [
          _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t("programming_exercise.testcase_expected_stdout")), 1),
          _createVNode(_component_CodeFragment, {
            collapsible: true,
            small: _ctx.small,
            value: _ctx.testCase.expected_stdout
          }, null, 8, ["small", "value"])
        ], 2))
      : _createCommentVNode("", true)
  ], 2))
}