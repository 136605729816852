
import { Tag as ITag } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import ProcessedTextFragment from "@/components/ui/ProcessedTextFragment.vue";
import { exerciseProps } from "./shared";
import { getExerciseTitle } from "@/utils";
import Tag from "@/components/ui/Tag.vue";
export default defineComponent({
	name: "AbstractExercise",
	props: {
		...exerciseProps,
	},
	methods: {},
	computed: {
		exerciseTitle(): string {
			return getExerciseTitle(this.exercise);
		},
		tags(): ITag[] {
			return [
				...(this.showPrivateTags ? this.exercise.private_tags ?? [] : []),
				...(this.showPublicTags ? this.exercise.public_tags ?? [] : []),
			];
		},
	},
	components: { ProcessedTextFragment, Tag },
});
