
import { useMetaStore } from "@/stores/metaStore";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import Avatar from "../ui/Avatar.vue";
import Btn from "../ui/Btn.vue";
import TextInput from "../ui/TextInput.vue";
export default defineComponent({
	name: "MessageEditor",
	emits: {
		sendMessage({
			message,
			resolveFn,
			rejectFn,
		}: {
			message: string;
			resolveFn: (v: unknown) => void;
			rejectFn: (v: unknown) => void;
		}) {
			return true;
		},
	},
	props: {
		posting: {
			type: Boolean,
			required: true,
		},
	},
	data() {
		return {
			draft: "",
		};
	},
	methods: {
		async onSend() {
			if (!this.isValidMessage) {
				return;
			}
			await new Promise((resolve, reject) => {
				this.$emit("sendMessage", {
					message: this.draft,
					resolveFn: resolve,
					rejectFn: reject,
				});
			});
			this.draft = "";
		},
	},
	computed: {
		...mapStores(useMetaStore),
		isValidMessage() {
			return this.draft.trim().length > 0;
		},
	},
	components: { Avatar, TextInput, Btn },
});
