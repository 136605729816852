import { vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = { class: "absolute z-50 w-full h-full opacity-0 -top-1" }
const _hoisted_4 = { key: 0 }
const _hoisted_5 = { key: 0 }
const _hoisted_6 = {
  key: 0,
  class: "px-2 ml-2 font-semibold rounded-full bg-primary bg-opacity-20 text-primary"
}
const _hoisted_7 = { class: "flex flex-col md:items-start items-center md:flex-row md:space-y-0 md:mr-4 space-y-2" }
const _hoisted_8 = { class: "" }
const _hoisted_9 = { class: "" }
const _hoisted_10 = { class: "flex md:mt-4 lg:mt-0 w-full lg:w-max md:flex-row flex-col md:space-y-0 space-y-2 md:space-x-2 items-center" }
const _hoisted_11 = { class: "flex items-center w-full md:w-max space-x-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_Chipset = _resolveComponent("Chipset")!
  const _component_Tag = _resolveComponent("Tag")!
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _withDirectives(_createElementVNode("div", _hoisted_3, null, 512), [
          [_vShow, _ctx.editingRule]
        ]),
        (_ctx.tagsAsSelectableOptions.length === 0)
          ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
              _createVNode(_component_NumberInput, {
                min: 0,
                class: "w-full mt-12",
                modelValue: _ctx.firstRule?.amount ?? 0,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (
							_ctx.rulesAutoSaveInstances[_ctx.firstRule?.id ?? '']?.onChange({
								amount: $event,
							})
						))
              }, {
                rightHint: _withCtx(() => [
                  _createTextVNode(" /" + _toDisplayString(_ctx.currentCourse?.public_exercises_count ?? ""), 1)
                ]),
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t("practice_template_editor.exercise_amount")) + " ", 1)
                ]),
                _: 1
              }, 8, ["modelValue"])
            ], 512)), [
              [_vShow, _ctx.modelValue.rules.length !== 0]
            ])
          : (_openBlock(), _createBlock(_component_Chipset, {
              key: 1,
              options: _ctx.tagsAsSelectableOptions,
              modelValue: _ctx.proxyModelValue,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.proxyModelValue) = $event)),
              allowMultiple: true,
              class: _normalizeClass({ 'opacity-20': _ctx.editingRule })
            }, {
              default: _withCtx(({ optionValue }) => [
                (_ctx.tagsToRules[optionValue] && _ctx.selectedTags.includes(String(optionValue)))
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                      (parseInt(_ctx.tagsToRules[optionValue]?.amount ?? 0) > 0)
                        ? (_openBlock(), _createElementBlock("span", _hoisted_6, _toDisplayString(_ctx.tagsToRules[optionValue].amount), 1))
                        : _createCommentVNode("", true)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["options", "modelValue", "class"]))
      ]),
      (_ctx.editingRuleTagSeenExercises > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass([_ctx.editingRule ? 'visible' : 'invisible', 'mt-8'])
          }, [
            _createElementVNode("p", null, [
              _createTextVNode(_toDisplayString(_ctx.$t("practice_template_editor.seen_exercises_wont_be_selected") + " ") + " ", 1),
              _createElementVNode("strong", null, _toDisplayString(_ctx.editingRuleTagSeenExercises), 1),
              _createTextVNode(" " + _toDisplayString(" " + _ctx.$t("practice_template_editor.exercises_with_this_tag")), 1)
            ])
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: _normalizeClass(["flex flex-col flex-wrap mt-4 md:items-center md:flex-row md:static fixed top-1/2 md:transform-none md:w-max w-10/12 transform -translate-y-1/2 p-3 md:p-0 left-1/2 -translate-x-1/2 rounded md:rounded-none bg-light shadow-popup md:shadow-none md:bg-transparent z-50", [_ctx.editingRule ? 'visible' : 'invisible']])
      }, [
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("practice_template_editor.rule_amount_1")), 1),
          (_ctx.editingRuleTag)
            ? (_openBlock(), _createBlock(_component_Tag, {
                key: 0,
                class: "md:mx-2",
                tag: _ctx.editingRuleTag
              }, null, 8, ["tag"]))
            : _createCommentVNode("", true),
          _createElementVNode("p", _hoisted_9, _toDisplayString(_ctx.$t("practice_template_editor.rule_amount_2")), 1)
        ]),
        _createElementVNode("div", _hoisted_10, [
          _createVNode(_component_NumberInput, {
            modelValue: _ctx.editingRuleDirtyAmount,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.editingRuleDirtyAmount) = $event)),
            class: "mt-2 md:w-32 w-full md:mt-0",
            min: 0,
            max: _ctx.editingRuleTag?.public_exercises_not_seen ?? 100000000
          }, {
            rightHint: _withCtx(() => [
              _createTextVNode(" /" + _toDisplayString(_ctx.editingRuleTag?.public_exercises_not_seen ?? ""), 1)
            ]),
            _: 1
          }, 8, ["modelValue", "max"]),
          _createElementVNode("div", _hoisted_11, [
            _createVNode(_component_Btn, {
              class: "my-auto md:w-max w-full",
              disabled: _ctx.editingRuleDirtyAmount < 1,
              onClick: _ctx.saveRule
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialog.default_ok_text")), 1)
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]),
            _createVNode(_component_Btn, {
              class: "my-auto md:w-max w-full",
              outline: true,
              onClick: _ctx.discardRule
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("dialog.default_cancel_text")), 1)
              ]),
              _: 1
            }, 8, ["onClick"])
          ])
        ])
      ], 2)
    ])
  ]))
}