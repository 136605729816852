import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex items-center mb-2 space-x-2"
}
const _hoisted_2 = {
  key: 0,
  class: "flex items-center mb-2 space-x-2"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CheckboxGroup = _resolveComponent("CheckboxGroup")!
  const _component_RadioGroup = _resolveComponent("RadioGroup")!
  const _component_AbstractExercise = _resolveComponent("AbstractExercise")!

  return (_openBlock(), _createBlock(_component_AbstractExercise, _normalizeProps(_guardReactiveProps(_ctx.$props)), {
    submissionControls: _withCtx(() => [
      (_ctx.exercise.exercise_type === _ctx.ExerciseType.MULTIPLE_CHOICE_MULTIPLE_POSSIBLE)
        ? (_openBlock(), _createBlock(_component_CheckboxGroup, {
            key: 0,
            options: _ctx.exerciseChoicesAsOptions,
            modelValue: _ctx.selectedChoicesProxy,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedChoicesProxy) = $event)),
            disabled: _ctx.readOnly
          }, {
            item: _withCtx(({ description }) => [
              (_ctx.showSolution)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createElementVNode("p", {
                      class: _normalizeClass([{
								'text-success': description === 'done',
								'text-danger-dark': description === 'close',
							}, "text-sm font-semibold text-muted material-icons-outlined"])
                    }, _toDisplayString(description), 3)
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["options", "modelValue", "disabled"]))
        : (
					_ctx.exercise.exercise_type === _ctx.ExerciseType.MULTIPLE_CHOICE_SINGLE_POSSIBLE
				)
          ? (_openBlock(), _createBlock(_component_RadioGroup, {
              key: 1,
              options: _ctx.exerciseChoicesAsOptions,
              modelValue: _ctx.selectedChoicesProxy,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.selectedChoicesProxy) = $event)),
              disabled: _ctx.readOnly
            }, {
              item: _withCtx(({ description }) => [
                (_ctx.showSolution)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                      _createElementVNode("p", {
                        class: _normalizeClass([{
								'text-success': description === 'done',
								'text-danger-dark': description === 'close',
							}, "text-sm font-semibold text-muted material-icons-outlined"])
                      }, _toDisplayString(description), 3)
                    ]))
                  : _createCommentVNode("", true)
              ]),
              _: 1
            }, 8, ["options", "modelValue", "disabled"]))
          : _createCommentVNode("", true)
    ]),
    _: 1
  }, 16))
}