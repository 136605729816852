
import { EventParticipation } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import AbstractEventParticipationSlot from "../shared/AbstractEventParticipationSlot.vue";
export default defineComponent({
	name: "ParticipationThumbnail",
	props: {
		participation: {
			type: Object as PropType<EventParticipation>,
			required: true,
		},
	},
	methods: {},
	computed: {},
	components: { AbstractEventParticipationSlot },
});
