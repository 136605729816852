import { resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mt-2 mb-12" }
const _hoisted_2 = { class: "flex flex-col mb-3 flex-wrap md:items-center md:flex-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_Toggle = _resolveComponent("Toggle")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_TextInput, {
        searchBar: true,
        leftIcon: 'search',
        placeholder: _ctx.$t('course_list.filter_courses'),
        class: _normalizeClass([
					!_ctx.anyCourseHasPrivileges && !_ctx.metaStore.user.is_teacher
						? 'w-full'
						: 'w-full  md:w-1/2',
					'mt-2 mr-auto',
				]),
        modelValue: _ctx.modelValue.name,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.emitUpdate('name', $event)))
      }, null, 8, ["placeholder", "class", "modelValue"]),
      (_ctx.anyCourseHasPrivileges)
        ? (_openBlock(), _createBlock(_component_Toggle, {
            key: 0,
            "label-on-left": true,
            class: "mt-2 mr-4",
            modelValue: _ctx.modelValue.withPrivileges,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.emitUpdate('withPrivileges', $event)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("course_list.courses_i_teach")), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      (_ctx.metaStore.user.is_teacher)
        ? (_openBlock(), _createBlock(_component_Toggle, {
            key: 1,
            class: "mt-2 md:mt-4 lg:mt-2",
            "label-on-left": true,
            modelValue: _ctx.modelValue.hidden,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.emitUpdate('hidden', $event)))
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t("course_list.hidden_courses")), 1)
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}