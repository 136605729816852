
import { defineComponent, PropType } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
import MultiIcon from "@/components/ui/MultiIcon.vue";
import DropdownMenu from "./DropdownMenu.vue";
import { SelectableOption } from "@/interfaces";
import LinearProgress from "./LinearProgress.vue";

export default defineComponent({
	name: "Dropdown",
	props: {
		options: {
			type: Array as PropType<SelectableOption[]>,
			required: true,
		},
		modelValue: {
			required: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		MultiIcon,
		DropdownMenu,
		LinearProgress,
	},
	created() {
		this.id = uuid4();
		if (this.modelValue != null) {
			this.expanded = false;
		}
	},
	data() {
		return {
			expanded: true,
			showFeedback: false,
			id: "",
		};
	},
	methods: {
		onInput(value: string, inputEvent: Event) {
			console.log("INPUT", value);

			// if (this.modelValue != value) {
			// 	this.expanded = false;
			// 	this.showFeedback = true;
			// 	setTimeout(() => (this.showFeedback = false), 1000);
			// }

			// prevent target from automatically being checked -
			// it will if its value is equal to modelValue

			// eslint-disable-next-line @typescript-eslint/no-extra-semi
			(inputEvent.target as unknown as { checked: boolean }).checked = false;
			inputEvent.preventDefault();
			this.$emit("update:modelValue", value);
			this.expanded = false;
		},
	},
	computed: {
		selectedOption() {
			return this.options.find(o => o.value == this.modelValue);
		},
	},
});
