
import { defineComponent, PropType } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
import Btn from "./Btn.vue";
export default defineComponent({
	name: "FadedEdgesScrollableFragment",
	props: {},
	mounted() {
		this.element = document.getElementById(this.elementId);
		this.element?.addEventListener("scroll", this.debounce(this.onScroll), {
			passive: true,
		});
		this.onScroll();
		// (ugly workaround) trigger method to make the right arrow disappear if element isn't scrollable
		setTimeout(() => this.onScroll(), 50);
	},
	data() {
		return {
			atBeginX: true,
			atEndX: false,
			atBeginY: true,
			atEndY: false,
			elementId: uuid4(),
			element: null as null | HTMLElement,
			isScrollable: false,
		};
	},
	methods: {
		debounce(fn: any) {
			// This holds the requestAnimationFrame reference, so we can cancel it if we wish
			let frame: number;
			// The debounce function returns a new function that can receive a variable number of arguments
			return (...params: any[]) => {
				// If the frame variable has been defined, clear it now, and queue for next frame
				if (frame) {
					cancelAnimationFrame(frame);
				}
				// Queue our function call for the next frame
				frame = requestAnimationFrame(() => {
					// Call our function and pass any params we received
					fn(...params);
				});
			};
		},
		onScroll() {
			const el = this.element as HTMLElement;
			this.isScrollable =
				el.scrollWidth > el.clientWidth &&
				["scroll", "auto"].indexOf(getComputedStyle(el).overflowX) >= 0;
			this.atEndX =
				!this.isScrollable || -(el.scrollLeft + el.clientWidth) + el.scrollWidth <= 1;
			this.atBeginX = el.scrollLeft === 0;
		},
		scrollBy(perc: number) {
			const el = this.element as HTMLElement;
			const amount = (el.scrollWidth * perc) / 100;
			el.scrollLeft = el.scrollLeft + amount;
		},
	},
	computed: {},
	components: { Btn },
});
