import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ag_grid_vue = _resolveComponent("ag-grid-vue")!

  return (_openBlock(), _createBlock(_component_ag_grid_vue, {
    style: _normalizeStyle(_ctx.style),
    suppressRowClickSelection: true,
    alwaysShowHorizontalScroll: true,
    class: "ag-theme-material",
    suppressRowHoverHighlight: "true",
    suppressCellSelection: "false",
    columnDefs: _ctx.columnDefs,
    rowSelection: 'multiple',
    isRowSelectable: _ctx.isRowSelectable,
    getRowClass: _ctx.getRowClass,
    rowClassRules: _ctx.rowClassRules,
    rowData: _ctx.rowData,
    getRowNodeId: _ctx.getRowId,
    immutableData: true,
    localeTextFunc: _ctx.localeTextFunc,
    onCellClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cellClicked', $event))),
    onSelectionChange: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('selectionChanged', $event))),
    onFirstDataRendered: _ctx.onGridReady,
    onFilterChanged: _ctx.onFilterChanged
  }, null, 8, ["style", "columnDefs", "isRowSelectable", "getRowClass", "rowClassRules", "rowData", "getRowNodeId", "localeTextFunc", "onFirstDataRendered", "onFilterChanged"]))
}