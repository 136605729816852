
import {
	EventParticipationSlot,
	Exercise,
	ExerciseChoice,
	ExerciseType,
	multipleChoiceExerciseTypes,
	programmingExerciseTypes,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import ProcessedTextFragment from "../ui/ProcessedTextFragment.vue";

import { Bar } from "vue-chartjs";
import { TChartData } from "vue-chartjs/dist/types";
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
	CoreChartOptions,
	DatasetChartOptions,
	ElementChartOptions,
	PluginChartOptions,
	ScaleChartOptions,
	BarControllerChartOptions,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/types/utils";
import { getTranslatedString as _ } from "@/i18n";

import { SelectableOption } from "@/interfaces";

import {
	getTestCasePassingFrequencyFor,
	getChoiceSelectionFrequencyFor,
	passedTestCasesBarChartOptions,
	exerciseChoicesBarChartOptions,
	scoreChartDatasetSettings,
	exerciseChoiceDatasetSettings,
} from "@/reports/examParticipations";
import { makeLabelText, DataFrequency } from "@/reports/misc";
import ExerciseTestCase from "../shared/ExerciseTestCase.vue";
import { mediaQueryMixin } from "@/mixins";
import { getCorrectChoices } from "../shared/Exercise/utils";

ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

export default defineComponent({
	name: "ExerciseWithStats",
	components: { Bar, ProcessedTextFragment, ExerciseTestCase },
	props: {
		exercise: {
			type: Object as PropType<Exercise>,
			required: true,
		},
		slots: {
			type: Array as PropType<EventParticipationSlot[]>,
			required: true,
		},
	},
	mixins: [mediaQueryMixin],
	data() {
		return {
			exerciseChoicesBarChartOptions,
			passedTestCasesBarChartOptions,
		};
	},
	methods: {
		getCorrectChoices(exercise: Exercise): string[] {
			return getCorrectChoices(exercise);
		},
	},
	computed: {
		// TODO import and use the functions in utils
		isMultipleChoice(): boolean {
			return multipleChoiceExerciseTypes.includes(
				this.exercise.exercise_type as ExerciseType,
			);
		},
		isProgrammingExercise(): boolean {
			return programmingExerciseTypes.includes(
				this.exercise.exercise_type as ExerciseType,
			);
		},
		isOpenAnswerExercise(): boolean {
			return this.exercise.exercise_type === ExerciseType.OPEN_ANSWER;
		},
		isClozeExercise(): boolean {
			return this.exercise.exercise_type === ExerciseType.COMPLETION;
		},
		selectedChoicesFrequency(): DataFrequency<ExerciseChoice>[] {
			if ((this.exercise.choices?.length ?? 0) === 0) {
				return [];
			}
			return getChoiceSelectionFrequencyFor(this.exercise, this.slots);
		},
		programmingExerciseScoresFrequency(): {
			scoreFrequency: DataFrequency<number>[];
			testCasePassingRate: Record<string, number>;
		} {
			if ((this.exercise.testcases?.length ?? 0) === 0) {
				return { scoreFrequency: [], testCasePassingRate: {} };
			}
			return getTestCasePassingFrequencyFor(this.exercise, this.slots);
		},
		clozeSubExercisesChoicesFrequencyChartData(): TChartData<"bar", number[], unknown>[] {
			return (this.exercise.sub_exercises ?? []).map(e => {
				const subExerciseChoiceFrequency = getChoiceSelectionFrequencyFor(
					e,
					this.slots.flatMap(s => s.sub_slots).filter(s => s.exercise.id == e.id),
				);
				return {
					labels: subExerciseChoiceFrequency.map(r =>
						makeLabelText(r.datum.text).slice(0, 100),
					),
					datasets: [
						{
							data: subExerciseChoiceFrequency.map(r => r.frequency),
							...exerciseChoiceDatasetSettings,
							backgroundColor: subExerciseChoiceFrequency.map(r =>
								this.getCorrectChoices(e).includes(r.datum.id)
									? "#10B981b3"
									: "#e5e7ebb3",
							),
						},
					],
				};
			});
		},
		selectedChoicesFrequencyChartData(): TChartData<"bar", number[], unknown> {
			return {
				labels: this.selectedChoicesFrequency.map(r =>
					makeLabelText(r.datum.text).slice(0, 100),
				),
				datasets: [
					{
						data: this.selectedChoicesFrequency.map(r => r.frequency),
						...exerciseChoiceDatasetSettings,
						backgroundColor: this.selectedChoicesFrequency.map(r =>
							this.getCorrectChoices(this.exercise).includes(r.datum.id)
								? "#10B981b3"
								: "#e5e7ebb3",
						),
					},
				],
			};
		},
		programmingExerciseScoreFrequencyChartData(): TChartData<"bar", number[], unknown> {
			return {
				labels: this.programmingExerciseScoresFrequency.scoreFrequency.map(r => r.datum),
				datasets: [
					{
						data: this.programmingExerciseScoresFrequency.scoreFrequency.map(
							r => r.frequency,
						),
						...scoreChartDatasetSettings,
						maxBarThickness: 100,
					},
				],
			};
		},
	},
});
