
const VISITED_INSIGHTS_TOUR_KEY = "VISITED_INSIGHTS_TOUR_KEY";

/* eslint-disable @typescript-eslint/no-explicit-any */
import DataTable from "@/components/ui/DataTable.vue";
import { blockingDialogMixin, courseIdMixin, eventIdMixin, loadingMixin } from "@/mixins";
import {
	AssessmentVisibility,
	Event,
	EventParticipation,
	EventParticipationSlot,
	EventParticipationSlotAssessment,
	EventParticipationState,
	EventState,
	EventTemplateRule,
	ParticipationAssessmentProgress,
} from "@/models";
import { defineComponent } from "@vue/runtime-core";

import { getTranslatedString as _ } from "@/i18n";
import { CellClickedEvent, ColDef, RowClassParams, RowNode } from "ag-grid-community";
import { icons as assessmentStateIcons } from "@/assets/assessmentStateIcons";
import { icons as participationStateIcons } from "@/assets/participationStateIcons";
import Dialog from "@/components/ui/Dialog.vue";
import AbstractEventParticipationSlot from "@/components/shared/AbstractEventParticipationSlot.vue";
import { DialogData } from "@/interfaces";
import Btn from "@/components/ui/Btn.vue";
import CsvParticipationDownloader from "@/components/teacher/CsvParticipationDownloader.vue";
import SkeletonCard from "@/components/ui/SkeletonCard.vue";
import {
	examInsightsPageTourSteps,
	getEventParticipationMonitorHeaders,
	tourOptions,
} from "@/const";

import Spinner from "@/components/ui/Spinner.vue";
import {
	areAllParticipationsFullyAssessed,
	getParticipationsAverageProgress,
} from "@/reports";

import EventParticipationSlotScoreRenderer from "@/components/datatable/EventParticipationSlotScoreRenderer.vue";
import EventParticipationSlotCompletionRenderer from "@/components/datatable/EventParticipationSlotCompletionRenderer.vue";
import EventParticipationEmailRenderer from "@/components/datatable/EventParticipationEmailRenderer.vue";
import EventParticipationStateRenderer from "@/components/datatable/EventParticipationStateRenderer.vue";
import EventParticipationAssessmentStateRenderer from "@/components/datatable/EventParticipationAssessmentStateRenderer.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import { setErrorNotification } from "@/utils";

export default defineComponent({
	components: {
		DataTable,
		Dialog,
		AbstractEventParticipationSlot,
		Btn,
		CsvParticipationDownloader,
		SkeletonCard,
		Spinner,

		/** Cell renderers required by Ag-grid */
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationSlotScoreRenderer,
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationSlotCompletionRenderer,
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationEmailRenderer,
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationStateRenderer,
		// eslint-disable-next-line vue/no-unused-components
		EventParticipationAssessmentStateRenderer,
	},
	name: "EventParticipationsMonitor",
	props: {
		refreshData: {
			type: Boolean,
			default: true,
		},
		allowEditParticipations: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [courseIdMixin, eventIdMixin, loadingMixin, blockingDialogMixin],
	async created() {
		await this.withFirstLoading(async () => {
			await this.mainStore.getEventParticipations({
				courseId: this.courseId,
				eventId: this.eventId,
				mutate: true,
			});
			// TODO gridApi could be null (doesn't really happen in production) - what did this line solve?
			//this.gridApi.refreshCells({ force: true });
			await this.mainStore.getEvent({
				courseId: this.courseId,
				eventId: this.eventId,
				includeDetails: false,
			});
		});

		if (this.resultsMode && !(VISITED_INSIGHTS_TOUR_KEY in localStorage)) {
			setTimeout(() => (this.$tours as any)["examInsightsTour"].start(), 200);
			localStorage.setItem(VISITED_INSIGHTS_TOUR_KEY, "true");
		}

		if (this.refreshData) {
			this.setDataRefreshInterval(10000);
		}

		const runningSlotsCheckFn = () =>
			this.mainStore.eventParticipations
				.flatMap(p => p.slots)
				.some(s => s.execution_results && s.execution_results.state === "running");

		// if the exam is closed but there are participation slots with
		// code still running, periodically refetch data until all slots
		// are settled down
		if (this.resultsMode && runningSlotsCheckFn()) {
			this.setDataRefreshInterval(2000, () => {
				if (!runningSlotsCheckFn()) {
					clearInterval(this.refreshHandle as number);
					this.refreshHandle = null;
				}
			});
		}
		// setTimeout(() => this.columnApi.autoSizeAllColumns(false), 5000);
	},

	beforeRouteLeave() {
		if (this.refreshHandle != null) {
			clearInterval(this.refreshHandle);
		}
	},
	data() {
		return {
			refreshHandle: null as number | null,
			EventState,
			editingSlot: null as EventParticipationSlot | null,
			editingSlotDirty: null as EventParticipationSlot | null,
			editingFullName: "",
			editingParticipationId: "",
			gridApi: null as any,
			columnApi: null as any,
			selectedParticipations: [] as string[],
			showStats: false,
			dispatchingCall: false,

			// dialog functions
			showAssessmentEditorDialog: false,
			publishingResultsMode: false,
			closingExamsMode: false,
			reOpeningTurnedInParticipationMode: false,
			reOpeningClosedExamsMode: false,

			// show banners
			showThereAreUnpublishedResultsBanner: true,
			showRestrictedModeBanner: true,
			showThereArePendingAssessmentsBanner: true,
			showAllAssessmentsPublishedBanner: true,

			//tour
			tourOptions,
			examInsightsPageTourSteps,

			EventParticipationState,
			participationStateIcons,
		};
	},
	methods: {
		areAllParticipationsFullyAssessed,
		setDataRefreshInterval(interval: number, callback?: any) {
			this.refreshHandle = setInterval(async () => {
				await this.mainStore.getEventParticipations({
					courseId: this.courseId,
					eventId: this.eventId,
					mutate: true,
				});
				if (typeof callback === "function") {
					callback();
				}
			}, interval);
		},

		onUpdateSlotAssessment(event: {
			slot: EventParticipationSlot;
			payload: [keyof EventParticipationSlotAssessment, any];
		}) {
			if (this.editingSlotDirty) {
				this.editingSlotDirty[event.payload[0]] = event.payload[1];
			}
		},
		isRowSelectable(row: RowNode) {
			/**
			 * Used by ag grid to determine whether the row is selectable
			 */
			if (this.resultsMode) {
				return (
					row.data.state == ParticipationAssessmentProgress.FULLY_ASSESSED &&
					row.data.visibility != AssessmentVisibility.PUBLISHED
				);
			}
			return true;
		},
		getRowId(data: any) {
			return data.id;
		},
		getRowClassRules() {
			return {
				"bg-success-important hover:bg-success-important": (params: RowNode) =>
					this.resultsMode && params.data.visibility === AssessmentVisibility.PUBLISHED,
				"bg-danger-important hover:bg-danger-important": (params: RowNode) =>
					!this.resultsMode &&
					this.event.state === EventState.RESTRICTED &&
					!this.event.users_allowed_past_closure?.includes(
						this.mainStore.getEventParticipationById(params.data.id)?.user?.id ?? "",
					),
			};
		},
		onSelectionChanged() {
			// copy the id's of the selected participations
			this.selectedParticipations = this.gridApi
				?.getSelectedNodes()
				.map((n: any) => n.data.id);
		},
		deselectAllRows() {
			this.gridApi.deselectAll();
			this.selectedParticipations = [];
		},
		async onCellClicked(event: CellClickedEvent) {
			// TODO refactor to have separate methods
			if (event.colDef.field?.startsWith("slot") && this.resultsMode) {
				this.onOpenAssessmentEditorDialog(event.data.id, event.value);
			}
			// change turned in status
			else if (
				event.colDef.field === "state" &&
				!this.resultsMode &&
				event.data.state == EventParticipationState.TURNED_IN
			) {
				const participation = this.mainStore.getEventParticipationById(event.data.id);
				await this.onUndoParticipationTurnIn(participation as EventParticipation);
			}
		},
		async onOpenAssessmentEditorDialog(
			participationId: string,
			slot: EventParticipationSlot,
		) {
			this.editingSlot = slot;
			this.editingParticipationId = participationId;
			this.showAssessmentEditorDialog = true;

			// fetch slot that is being edited to have the full details
			await this.withLocalLoading(
				async () =>
					await this.mainStore.getEventParticipationSlot({
						courseId: this.courseId,
						slotId: (this.editingSlot as EventParticipationSlot).id,
						participationId,
						eventId: this.eventId,
					}),
			);
			// deep copy slot to prevent affecting the original one while editing
			this.editingSlotDirty = JSON.parse(JSON.stringify(this.editingSlot));
			this.editingFullName = (
				this.mainStore.getEventParticipationById(participationId) as EventParticipation
			).user.full_name;
		},
		async dispatchAssessmentUpdate() {
			this.dispatchingCall = true;
			try {
				await this.mainStore.partialUpdateEventParticipationSlot({
					courseId: this.courseId,
					eventId: this.eventId,
					participationId: this.editingParticipationId,
					slotId: (this.editingSlot as EventParticipationSlot).id,
					changes: {
						score: this.editingSlotDirty?.score,
						comment: this.editingSlotDirty?.comment,
					},
					mutate: true,
				});
				await this.mainStore.getEventParticipation({
					courseId: this.courseId,
					eventId: this.eventId,
					participationId: this.editingParticipationId,
				});
				this.hideDialog();
				this.metaStore.showSuccessFeedback();
				this.gridApi.refreshCells({ force: true });
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.dispatchingCall = false;
			}
		},
		async onCloseSelectedExams() {
			// closing exams only for a group of participant means putting all of the
			// participants except those ones inside the `users_allowed_past_closure`
			// list of the exam and setting the exam state to RESTRICTED

			const dialogData: DialogData = {
				title: _("event_monitor.close_for_selected"),
				yesText:
					_("misc.close") +
					" " +
					this.selectedCloseableParticipations.length +
					" " +
					(this.selectedCloseableParticipations.length === 1
						? _("misc.exam")
						: _("misc.exams")),
				noText: _("dialog.default_cancel_text"),
				text:
					_("event_monitor.close_for_selected_text_1") +
					" " +
					this.selectedCloseableParticipations.length +
					" " +
					(this.selectedCloseableParticipations.length === 1
						? _("misc.participant")
						: _("misc.participants")) +
					".",
				//onYes: this.closeExams,
			};

			this.blockingDialogData = dialogData;
			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			// these are the ones the exam will stay open for
			const unselectedParticipations = this.mainStore.eventParticipations.filter(
				p => !this.selectedParticipations.includes(p.id),
			);
			const unselectedUserIds = unselectedParticipations.map(p => p.user.id);

			this.dispatchingCall = true;
			try {
				await this.mainStore.partialUpdateEvent({
					courseId: this.courseId,
					eventId: this.eventId,
					mutate: true, // update local object too
					changes: {
						state: EventState.RESTRICTED,
						users_allowed_past_closure: [
							// users that were already allowed and haven't been selected now
							...(this.event.users_allowed_past_closure ?? []).filter(
								i =>
									!this.selectedCloseableParticipations.map(p => p.user.id).includes(i),
							),
							// unselected id's that were already allowed (unless it's the
							// first time the exam gets restricted)
							...unselectedUserIds.filter(
								i =>
									this.event.state !== EventState.RESTRICTED ||
									this.event.users_allowed_past_closure?.includes(i),
							),
						],
					},
				});
				this.showBlockingDialog = false;
				this.metaStore.showSuccessFeedback();
				this.deselectAllRows();
				this.gridApi.refreshCells({ force: true });
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.dispatchingCall = false;
			}

			// TODO extract
			if (this.resultsMode && !(VISITED_INSIGHTS_TOUR_KEY in localStorage)) {
				setTimeout(() => (this.$tours as any)["examInsightsTour"].start(), 1000);
				localStorage.setItem(VISITED_INSIGHTS_TOUR_KEY, "true");
			}
		},
		async onOpenSelectedExams() {
			// re-opening exam for a group of participants means adding those
			// participants to the `users_allowed_past_closure` list for the exam
			const dialogData: DialogData = {
				title: _("event_monitor.open_for_selected"),
				yesText:
					_("misc.reopen") +
					" " +
					this.selectedOpenableParticipations.length +
					" " +
					(this.selectedOpenableParticipations.length === 1
						? _("misc.exam")
						: _("misc.exams")),
				noText: _("dialog.default_cancel_text"),
				onYes: this.openExams,
				text:
					_("event_monitor.open_for_selected_text") +
					" " +
					this.selectedOpenableParticipations.length +
					" " +
					(this.selectedOpenableParticipations.length === 1
						? _("misc.participant")
						: _("misc.participants")) +
					".",
			};

			this.blockingDialogData = dialogData;
			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			// these are the ones the exam will stay open for
			const selectedParticipations = this.mainStore.eventParticipations.filter(p =>
				this.selectedParticipations.includes(p.id),
			);
			const selectedUserIds = selectedParticipations.map(p => p.user.id);

			this.dispatchingCall = true;

			try {
				await this.mainStore.partialUpdateEvent({
					courseId: this.courseId,
					eventId: this.eventId,
					mutate: true, // update local object
					changes: {
						users_allowed_past_closure: [
							// those that were already allowed
							...(this.event.users_allowed_past_closure ?? []),
							...selectedUserIds, // those added now
						],
					},
				});
				this.showBlockingDialog = false;

				this.metaStore.showSuccessFeedback();
				this.deselectAllRows();
				this.gridApi.refreshCells({ force: true });
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.dispatchingCall = false;
			}
		},
		async dispatchParticipationsUpdate(
			participationIds: string[],
			changes: Partial<EventParticipation>,
		) {
			// generic method to update multiple participations at once and show feedback/error
			this.dispatchingCall = true;

			try {
				await this.mainStore.bulkPartialUpdateEventParticipation({
					courseId: this.courseId,
					eventId: this.eventId,
					participationIds,
					changes,
				});
				this.showBlockingDialog = false;
				this.metaStore.showSuccessFeedback();
				this.deselectAllRows();
				this.gridApi.refreshCells({ force: true });
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.dispatchingCall = false;
			}
		},
		async onPublishResults() {
			const dialogData: DialogData = {
				//onYes: this.publishResults,
				yesText: _("event_results.publish"),
				noText: _("dialog.default_cancel_text"),
				text: _("event_results.publish_confirm_text"),
			};

			this.blockingDialogData = dialogData;
			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			// TODO handle blocking dialog
			await this.dispatchParticipationsUpdate(this.selectedParticipations, {
				visibility: AssessmentVisibility.PUBLISHED,
			});
		},
		async onUndoParticipationTurnIn(participation: EventParticipation) {
			const dialogData: DialogData = {
				title: "",
				text: _("event_monitor.un_turn_in_text") + participation.user?.full_name + "?",
				warning: false,
				noText: _("dialog.default_no_text"),
				yesText: _("dialog.default_yes_text"),
			};

			this.blockingDialogData = dialogData;
			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			await this.dispatchParticipationsUpdate([participation.id], {
				state: EventParticipationState.IN_PROGRESS,
			});
		},

		hideDialog() {
			this.editingSlot = null;
			this.editingSlotDirty = null;
			this.editingFullName = "";
			this.editingParticipationId = "";
			this.showDialog = false;
			this.selectedParticipations = [];
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		showDialog: {
			get() {
				return (
					this.showAssessmentEditorDialog ||
					this.publishingResultsMode ||
					this.closingExamsMode ||
					this.reOpeningTurnedInParticipationMode ||
					this.reOpeningClosedExamsMode
				);
			},
			set(val: boolean) {
				if (!val) {
					this.showAssessmentEditorDialog = false;
					this.publishingResultsMode = false;
					this.closingExamsMode = false;
					this.reOpeningTurnedInParticipationMode = false;
					this.reOpeningClosedExamsMode = false;
				}
			},
		},
		dialogData(): DialogData {
			let ret = {} as DialogData;
			const defaultData = {
				onNo: this.hideDialog,
				noText: _("dialog.default_cancel_text"),
			} as DialogData;

			// TODO handle this
			if (this.dispatchingCall) {
				ret.disableOk = true;
				ret.yesText = _("misc.wait");
			}

			return { ...defaultData, ...ret };
		},
		event() {
			return this.mainStore.getEventById(this.eventId);
		},
		resultsMode() {
			return this.event.state === EventState.CLOSED;
		},
		participantCount() {
			return this.mainStore.eventParticipations.length;
		},
		turnedInCount() {
			return this.mainStore.eventParticipations.filter(
				(p: EventParticipation) => p.state === EventParticipationState.TURNED_IN,
			).length;
		},
		averageProgress() {
			return getParticipationsAverageProgress(
				this.mainStore.eventParticipations,
				this.event,
			);
		},
		thereAreUnpublishedAssessments() {
			return this.mainStore.eventParticipations.some(
				p => p.visibility != AssessmentVisibility.PUBLISHED,
			);
		},
		participationPreviewColumns() {
			return getEventParticipationMonitorHeaders(
				this.resultsMode,
				this.mainStore.eventParticipations,
			);
		},
		participationsData() {
			return this.mainStore.eventParticipations.map((p: EventParticipation) => {
				const ret = {
					id: p.id,
					email: p.user?.email,
					mat: p.user?.mat,
					currentSlotCursor: p.current_slot_cursor,
					course: p.user?.course,
					fullName: p.user?.full_name,
					state: this.resultsMode ? p.assessment_progress : p.state,
					visibility: p.visibility,
					score: p.score ?? "",
				} as Record<string, unknown>;
				p.slots.forEach(
					s => (ret["slot-" + ((s.slot_number as number) + 1)] = s), //s.score ?? '-')
				);
				return ret;
			});
		},
		selectedCloseableParticipations(): EventParticipation[] {
			return this.mainStore.eventParticipations.filter(
				p =>
					this.selectedParticipations.includes(p.id) && // participation is selected
					(this.event.state === EventState.OPEN || // event is open for everyone or...
						//... event is still open for this participant
						this.event.users_allowed_past_closure?.includes(p.user.id)),
			);
		},
		selectedOpenableParticipations(): EventParticipation[] {
			return this.mainStore.eventParticipations.filter(
				p =>
					this.selectedParticipations.includes(p.id) && // participation is selected
					// event is restricted and participant isn't in the list of those still allowed
					this.event.state === EventState.RESTRICTED &&
					!this.event.users_allowed_past_closure?.includes(p.user.id),
			);
		},
	},
});
