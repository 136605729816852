import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "w-full whitespace-pre" }
const _hoisted_2 = { class: "ml-2 text-sm text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextEditor = _resolveComponent("TextEditor")!
  const _component_ProcessedTextFragment = _resolveComponent("ProcessedTextFragment")!
  const _component_AbstractExercise = _resolveComponent("AbstractExercise")!

  return (_openBlock(), _createBlock(_component_AbstractExercise, _normalizeProps(_guardReactiveProps(_ctx.$props)), {
    submissionControls: _withCtx(() => [
      _createVNode(_component_TextEditor, {
        disabled: _ctx.readOnly,
        modelValue: _ctx.answerTextProxy,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.answerTextProxy) = $event)),
        onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('blur')))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("event_participation_slot.text_answer_label")), 1)
        ]),
        _: 1
      }, 8, ["disabled", "modelValue"])
    ]),
    readOnlyAnswer: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.$t("event_assessment.text_answer_label")), 1),
        _createVNode(_component_ProcessedTextFragment, {
          style: {"white-space":"break-spaces"},
          class: "w-full px-4 py-2 rounded bg-gray-50",
          value: _ctx.answerTextProxy,
          defaultValue: _ctx.$t('misc.no_answer')
        }, null, 8, ["value", "defaultValue"])
      ])
    ]),
    _: 1
  }, 16))
}