
import { CodeExecutionResults, ExerciseTestCase as IExerciseTestCase } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import ExerciseTestCase from "./ExerciseTestCase.vue";
import CodeFragment from "../ui/CodeFragment.vue";
import Btn from "../ui/Btn.vue";
export default defineComponent({
	name: "CodeExecutionResults",
	props: {
		// slot: {
		//   type: Object as PropType<EventParticipationSlot>,
		//   required: true,
		// },
		executionResults: {
			type: Object as PropType<CodeExecutionResults>,
			required: true,
		},
		testCases: {
			type: Array as PropType<IExerciseTestCase[]>,
			required: true,
		},
		showTestIds: {
			type: Array as PropType<string[]>,
			default: () => [],
		},
		onlyErrors: {
			type: Boolean,
			default: false,
		},
		reduced: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			// test case id -> whether it's expanded
			testCaseDetailsVisibility: {} as Record<string, boolean>,
		};
	},
	methods: {},
	computed: {
		exerciseTestCase() {
			return (id: string) => this.testCases.find(t => String(t.id) === String(id));
		},
		filteredExecutionResultsTests() {
			return this.executionResults?.tests?.filter(
				t =>
					this.showTestIds.length === 0 ||
					this.showTestIds.map(i => String(i)).includes(String(t.id)),
			);
		},
	},
	components: { ExerciseTestCase, CodeFragment, Btn },
});
