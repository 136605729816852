
import { VueEternalLoading, LoadAction } from "@ts-pro/vue-eternal-loading";
import Spinner from "@/components/ui/Spinner.vue";

import { Exercise, ExerciseState, ExerciseType } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import MinimalExercisePreview from "@/components/teacher/ExerciseEditor/MinimalExercisePreview.vue";
import Btn from "@/components/ui/Btn.vue";
import ExerciseSearchFilters from "./ExerciseSearchFilters.vue";
import { ExerciseSearchFilter } from "@/api/interfaces";
import { getDebouncedForFilter } from "@/utils";
import { courseIdMixin } from "@/mixins";
import MinimalExercisePreviewSkeleton from "../ui/skeletons/MinimalExercisePreviewSkeleton.vue";
import { getBlankExerciseSearchFilters, isEmptyFilter } from "@/api/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
export default defineComponent({
	name: "ExercisePicker",
	async created() {
		this.onFilterChange = getDebouncedForFilter(this.onFilterChange);

		// this.firstLoading = true;
		// await this.getExercises({
		//   courseId: this.courseId,
		//   fromFirstPage: true,
		// });
		// this.firstLoading = false;
	},
	mixins: [courseIdMixin],
	watch: {
		searchFilter: {
			async handler(val: ExerciseSearchFilter) {
				this.isInitialInfiniteLoad = true;
				await this.onFilterChange();
			},
			deep: true,
		},
	},
	components: {
		MinimalExercisePreview,
		VueEternalLoading,
		Spinner,
		ExerciseSearchFilters,
		Btn,
		MinimalExercisePreviewSkeleton,
	},
	data() {
		return {
			firstLoading: false,
			isInitialInfiniteLoad: false,
			searchFilter: {
				label: "",
				text: "",
				tags: [] as string[],
				exercise_types: [] as ExerciseType[],
				states: [] as ExerciseState[],
			} as ExerciseSearchFilter,
		};
	},
	props: {
		modelValue: {
			type: Object as PropType<string[]>,
			required: true,
		},
		allowPickingDraft: {
			type: Boolean,
			default: false,
		},
		alreadySelected: {
			type: Object as PropType<string[]>,
			required: true,
		},
	},
	methods: {
		isExerciseDraft(exercise: Exercise): boolean {
			return !this.allowPickingDraft && exercise.state == ExerciseState.DRAFT;
		},
		isSelectedInAnotherRule(exercise: Exercise): boolean {
			return this.alreadySelected.includes(exercise.id) && !this.isSelected(exercise);
		},
		onSelection(exercise: Exercise) {
			const index = this.modelValue.findIndex(e => e == exercise.id);
			if (index === -1) {
				this.$emit("addExercise", exercise);
			} else {
				this.$emit("removeExercise", exercise);
			}
		},
		isSelected(exercise: Exercise): boolean {
			return this.modelValue.find(e => e == exercise.id) != null;
		},
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			try {
				const moreResults = await this.mainStore.getExercises({
					courseId: this.courseId,
					fromFirstPage: false,
					filters: this.searchFilter,
				});
				if (!moreResults) {
					noMore();
				} else {
					loaded();
				}
			} catch (e) {
				console.error("Error loading more exercises", e);
				error();
			}
		},
		async onFilterChange() {
			await this.mainStore.getExercises({
				courseId: this.courseId,
				fromFirstPage: true,
				filters: this.searchFilter,
			});
		},
		getBlankExerciseSearchFilters,
	},
	computed: {
		...mapStores(useMainStore),
		emptyFilter() {
			return isEmptyFilter(this.searchFilter);
		},
	},
});
