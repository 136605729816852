
import { defineComponent } from "@vue/runtime-core";
import Tooltip from "./Tooltip.vue";
import { v4 as uuid4 } from "uuid";
export default defineComponent({
	components: {
		Tooltip,
	},
	name: "CloudSaveStatus",
	props: {
		saving: {
			type: Boolean,
			required: true,
		},
		hadError: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		saving(newVal: boolean, oldVal: boolean) {
			if (!newVal && oldVal) {
				if (this.showSavedHandle !== null) {
					clearInterval(this.showSavedHandle);
				}
				if (this.showOutOfViewFeedbackHandle !== null) {
					clearInterval(this.showOutOfViewFeedbackHandle);
				}
				// done saving
				this.showSaved = true;
				if (!this.isElementInViewPort()) {
					this.showOutOfViewFeedback = true;
				}
				this.showSavedHandle = setTimeout(() => (this.showSaved = false), 5000);
				this.showOutOfViewFeedbackHandle = setTimeout(
					() => (this.showOutOfViewFeedback = false),
					1000,
				);
			}
			if (newVal && !this.isElementInViewPort()) {
				setTimeout(() => {
					if (this.saving) {
						this.showOutOfViewFeedback = true;
					}
				}, 1000);
			}
		},
	},
	data() {
		return {
			showSaved: false,
			showSavedHandle: null as null | number,
			showOutOfViewFeedback: false,
			showOutOfViewFeedbackHandle: null as null | number,
			elementId: uuid4(),
		};
	},
	methods: {
		isElementInViewPort() {
			const element = document.getElementById(this.elementId) as HTMLElement;
			const rect = element.getBoundingClientRect();

			return (
				rect.top >= 0 &&
				rect.left >= 0 &&
				rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
				rect.right <= (window.innerWidth || document.documentElement.clientWidth)
			);
		},
	},
});
