
import { courseIdMixin, loadingMixin } from "@/mixins";
import { Exercise, ExerciseSolution, ExerciseSolutionState } from "@/models";
import { defineComponent } from "@vue/runtime-core";
import ExerciseSolutionContainer from "@/components/shared/ExerciseSolution/ExerciseSolutionContainer.vue";
import { getTranslatedString as _ } from "@/i18n";

import { VueEternalLoading, LoadAction } from "@ts-pro/vue-eternal-loading";
import Spinner from "@/components/ui/Spinner.vue";
import ExerciseEditorWrapperSkeleton from "@/components/ui/skeletons/ExerciseEditorWrapperSkeleton.vue";
import { ExerciseSearchFilter, ExerciseSolutionSearchFilter } from "@/api/interfaces";
import MinimalExercisePreviewSkeleton from "@/components/ui/skeletons/MinimalExercisePreviewSkeleton.vue";
import SlotSkeleton from "@/components/ui/skeletons/SlotSkeleton.vue";
import Btn from "@/components/ui/Btn.vue";
import FullExercise from "@/components/shared/FullExercise.vue";
import { logAnalyticsEvent, setErrorNotification } from "@/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
export default defineComponent({
	name: "CourseExerciseSolutionThreads",
	mixins: [loadingMixin, courseIdMixin],
	props: {},
	async created() {
		this.withFirstLoading(async () => {
			await this.mainStore.getExercises({
				courseId: this.courseId,
				fromFirstPage: true,
				filters: { with_submitted_solutions: true } as ExerciseSearchFilter,
			});
			this.fetchSolutionsForNewExercises();
			logAnalyticsEvent("viewedCourseExerciseSolutions", { courseId: this.courseId });
		});
	},
	data() {
		return {
			isInitialInfiniteLoad: false,
			loadingSolutionsByExercise: {} as Record<string, boolean>,
			showSolutionsByExercise: {} as Record<string, boolean>,
		};
	},
	methods: {
		getSolutionsForExercise(exercise: Exercise): ExerciseSolution[] {
			return this.mainStore.getPaginatedSolutionsByExerciseId(exercise.id).data;
		},
		getSolutionCountForExercise(exercise: Exercise): number {
			return this.mainStore.paginatedSolutionsByExerciseId[exercise.id]?.count ?? 0;
		},
		async loadMore(exercise: Exercise) {
			await this.withLoading(
				async () =>
					await this.mainStore.getSolutionsByExercise({
						courseId: this.courseId,
						exerciseId: exercise.id,
						fromFirstPage: false,
						filter: {
							states: [ExerciseSolutionState.SUBMITTED],
						} as ExerciseSolutionSearchFilter,
					}),
			);
		},
		getExerciseTitle(exercise: Exercise): string {
			return (exercise?.label ?? "").trim().length > 0
				? (exercise.label as string)
				: _("exercise_preview.unnamed_exercise");
		},
		fetchSolutionsForNewExercises() {
			this.mainStore.exercises
				.filter(
					e => typeof this.mainStore.paginatedSolutionsByExerciseId[e.id] === "undefined",
				)
				.forEach(async e => {
					this.loadingSolutionsByExercise[e.id] = true;
					try {
						await this.mainStore.getSolutionsByExercise({
							courseId: this.courseId,
							exerciseId: e.id,
							fromFirstPage: true,
							filter: {
								states: [ExerciseSolutionState.SUBMITTED],
							} as ExerciseSolutionSearchFilter,
						});
					} catch (e) {
						setErrorNotification(e);
					} finally {
						this.loadingSolutionsByExercise[e.id] = false;
					}
				});
		},
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			try {
				const moreResults = await this.mainStore.getExercises({
					courseId: this.courseId,
					fromFirstPage: false,
					filters: { with_submitted_solutions: true } as ExerciseSearchFilter,
				});
				if (!moreResults) {
					noMore();
				} else {
					loaded();
				}
				this.fetchSolutionsForNewExercises();
			} catch {
				error();
			}
		},
	},
	computed: {
		...mapStores(useMainStore),
		processedExercises(): {
			exercise: Exercise;
			highlightedSolutionIds: string[];
		}[] {
			// TODO return exercises with the most important solutions shown
			return this.mainStore.exercises.map((exercise: Exercise) => ({
				exercise,
				highlightedSolutionIds: [],
			}));
		},
		remainingCount() {
			return this.mainStore.paginatedExercises.count;
		},
	},
	components: {
		ExerciseSolutionContainer,
		//AbstractEventParticipationSlot,
		VueEternalLoading,
		Spinner,
		ExerciseEditorWrapperSkeleton,
		MinimalExercisePreviewSkeleton,
		SlotSkeleton,
		Btn,
		FullExercise,
	},
});
