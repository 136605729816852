
// TODO test this component after migrating to pinia
import { getCourseLeaderboard, PaginatedData } from "@/api";
import { courseIdMixin, loadingMixin } from "@/mixins";
import { GamificationUser } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import SlotSkeleton from "@/components/ui/skeletons/SlotSkeleton.vue";
import Btn from "@/components/ui/Btn.vue";
import { logAnalyticsEvent } from "@/utils";
import { mapStores } from "pinia";
import { useMetaStore } from "@/stores/metaStore";
import { useMainStore } from "@/stores/mainStore";
import Avatar from "@/components/ui/Avatar.vue";

export default defineComponent({
	name: "GamificationLeaderboard",
	props: {},
	mixins: [loadingMixin, courseIdMixin],
	async created() {
		await this.withFirstLoading(async () => {
			await this.mainStore.getCourseGamificationContext({ courseId: this.courseId });
			if (this.mainStore.gamificationContext) {
				this.paginatedUsers = await getCourseLeaderboard(
					this.mainStore.gamificationContext.id,
					this.pageNumber,
				);
				this.pageSize = this.paginatedUsers.data.length;
			}
			logAnalyticsEvent("viewedLeaderboard", { courseId: this.courseId });
		});
	},
	watch: {
		async pageNumber() {
			await this.withLoading(async () => {
				await this.mainStore.getCourseGamificationContext({ courseId: this.courseId });
				this.paginatedUsers = await getCourseLeaderboard(
					this.mainStore.gamificationContext?.id ?? "",
					this.pageNumber,
				);
			});
		},
	},
	methods: {
		getUserPosition(userId: string) {
			return (
				this.pageSize * (this.pageNumber - 1) +
				(this.paginatedUsers?.data ?? []).findIndex(u => u.id == userId) +
				1
			);
		},
	},
	data() {
		return {
			paginatedUsers: null as PaginatedData<GamificationUser> | null,
			pageNumber: 1,
			pageSize: 1,
		};
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		lastPageNumber() {
			return Math.ceil((this.paginatedUsers?.count ?? 0) / this.pageSize);
		},
		pageNumbers() {
			return [...Array(this.lastPageNumber).keys()].map(p => p + 1);
		},
	},
	components: { SlotSkeleton, Btn, Avatar },
});
