
/* eslint-disable @typescript-eslint/no-extra-semi */

import CalendarInput from "@/components/ui/CalendarInput.vue";
import TextInput from "@/components/ui/TextInput.vue";
import TextEditor from "@/components/ui/TextEditor.vue";
import { defineComponent, inject } from "@vue/runtime-core";
import { Event, EventAccessRule, EventState, EventTimeLimitRule, Tag } from "@/models";
import Toggle from "@/components/ui/Toggle.vue";
//import NumberInput from '@/components/ui/NumberInput.vue'
import { PropType } from "vue";
//import Btn from '@/components/ui/Btn.vue'
import RadioGroup from "@/components/ui/RadioGroup.vue";
import { getTranslatedString as _ } from "@/i18n";
import Dialog from "@/components/ui/Dialog.vue";
import { SelectableOption } from "@/interfaces";
import Btn from "@/components/ui/Btn.vue";
import TagInput from "@/components/ui/TagInput.vue";
import { ChangeEvent } from "ag-grid-community/dist/lib/widgets/agCheckbox";
import { csvToArray, getFileContent, setErrorNotification } from "@/utils";
import NumberInput from "@/components/ui/NumberInput.vue";
import { mediaQueryMixin } from "@/mixins";
import { isDemoMode } from "@/utils";
import { mapStores } from "pinia";
import { useMetaStore } from "@/stores/metaStore";

export default defineComponent({
	name: "EventMetaEditor",
	components: {
		CalendarInput,
		TextInput,
		TextEditor,
		Toggle,
		RadioGroup,
		Dialog,
		Btn,
		TagInput,
		NumberInput,
	},
	props: {
		modelValue: {
			type: Object as PropType<Event>,
			required: true,
		},
	},
	mixins: [mediaQueryMixin],
	setup() {
		return {
			v$: inject("v$"),
		};
	},
	watch: {
		timeLimitExceptionsSerialized(_newVal) {
			this.emitUpdate("time_limit_exceptions", JSON.parse(_newVal));
		},
	},
	data() {
		return {
			event: {} as Event,
			saving: false,
			showDialog: false,
			showAccessRuleDialog: false,
			showTimeLimitDialog: false,
			EventAccessRule,
			EventTimeLimitRule,
		};
	},
	methods: {
		emitUpdate(key: keyof Event, value: unknown) {
			this.$emit("updateEvent", { field: key, value });
		},
		emitBlur() {
			this.$emit("blur");
		},
		onBeginTimestampOpen() {
			if (this.modelValue.state != EventState.DRAFT) {
				// eslint-disable-next-line @typescript-eslint/no-explicit-any
				(this.$refs.beginTimestampElement as any).close();
				this.showDialog = true;
			}
		},
		onAddTimeLimitException() {
			//this.modelValue.time_limit_exceptions?.push(["", 0]);
			this.emitUpdate("time_limit_exceptions", [
				...(this.modelValue.time_limit_exceptions ?? []),
				["", this.modelValue.time_limit_seconds ?? 0],
			]);
		},
		onRemoveTimeLimitException(index: number) {
			if (confirm(_("event_editor.remove_time_limit_exception_confirmation"))) {
				this.emitUpdate(
					"time_limit_exceptions",
					this.modelValue.time_limit_exceptions?.filter((_, i) => i !== index),
				);
			}
		},
		onAccessRuleChange(newVal: EventAccessRule) {
			this.emitUpdate("access_rule", newVal);
			if (newVal === EventAccessRule.ALLOW_ACCESS) {
				this.emitUpdate("access_rule_exceptions", []);
			}
		},
		onAddAllowedAccess(value: string) {
			// TODO investigate
			setTimeout(
				// have to delay in order to prevent issues with tag input component, which uses nextTick
				() =>
					this.emitUpdate("access_rule_exceptions", [
						...(this.modelValue.access_rule_exceptions ?? []),
						value,
					]),
				10,
			);
		},
		onRemoveAllowedAccess(value: string) {
			this.emitUpdate(
				"access_rule_exceptions",
				this.modelValue.access_rule_exceptions?.filter(
					e => e.toLowerCase() !== value.toLowerCase(),
				) ?? [],
			);
		},
		async onFileInputChange(event: { target: HTMLInputElement }) {
			if (event.target.files === null || event.target.files.length === 0) {
				return;
			}
			try {
				const file = event.target.files[0];

				const fileContents = await getFileContent(file);

				const FIRST_HEADER_NAME = _("reports.csv_headers.user.mat");
				const EMAIL_HEADER_NAME = _("reports.csv_headers.user.email");

				const csvElements = csvToArray(fileContents, FIRST_HEADER_NAME);

				const validCsvRecords = csvElements.filter(e => e[EMAIL_HEADER_NAME]);
				if (validCsvRecords.length === 0) {
					throw new Error();
				}
				this.emitUpdate(
					"access_rule_exceptions",
					validCsvRecords.map(
						e => e[EMAIL_HEADER_NAME]?.slice(1, -1) ?? "", // remove quotes
					),
				);
				this.metaStore.showSuccessFeedback();
			} catch (e) {
				setErrorNotification(_("misc.wrong_file_format"), true);
				throw e;
			} finally {
				event.target.value = "";
			}
		},
	},
	computed: {
		...mapStores(useMetaStore),
		isDemoMode() {
			return isDemoMode();
		},
		isDraft() {
			return this.modelValue.state == EventState.DRAFT;
		},
		timeLimitExceptionsSerialized(): string {
			return JSON.stringify(this.modelValue.time_limit_exceptions ?? "");
		},
		timeLimitProxy: {
			// handles conversion between seconds and minutes
			get() {
				return (this.modelValue.time_limit_seconds ?? 0) / 60;
			},
			set(val: any) {
				this.emitUpdate("time_limit_seconds", (val ?? 0) * 60);
			},
		},
		timeLimitRuleProxy: {
			get() {
				return this.modelValue.time_limit_rule === EventTimeLimitRule.TIME_LIMIT;
			},
			set(val: boolean) {
				this.emitUpdate(
					"time_limit_rule",
					val ? EventTimeLimitRule.TIME_LIMIT : EventTimeLimitRule.NO_TIME_LIMIT,
				);
			},
		},
		allowedAccessAsTags(): Tag[] {
			return (
				this.modelValue.access_rule_exceptions?.map(e => ({
					id: "",
					name: e,
				})) ?? []
			);
		},
		// TODO extract
		accessRulesOptions(): SelectableOption[] {
			return [
				{
					value: EventAccessRule.ALLOW_ACCESS,
					content: _("event_editor.allow_everyone_access_label"),
				},
				{
					value: EventAccessRule.DENY_ACCESS,
					content: _("event_editor.deny_access_by_default_label"),
				},
			];
		},
		exercisesShownAtOnceOptions(): SelectableOption[] {
			return [
				{
					value: null,
					content: _("event_editor.show_all_exercises_at_once"),
				},
				{
					value: 1,
					content: _("event_editor.show_one_exercise_at_once"),
				},
			];
		},
	},
});
