
import { defineComponent } from "@vue/runtime-core";
import { formatExerciseText, stripHtmlFromLaTexBlocks } from "@/utils";

export default defineComponent({
	name: "ProcessedTextFragment",
	props: {
		value: {
			type: String,
			required: true,
		},
		defaultValue: {
			type: String,
			default: "",
		},
	},
	methods: {},
	computed: {
		useDefault(): boolean {
			return this.value.trim().length === 0;
		},
		formattedValue(): string {
			if (this.useDefault) {
				return this.defaultValue;
			}
			return formatExerciseText(this.value);
		},
		sanitizedFormattedValue(): string {
			return this.$sanitize(stripHtmlFromLaTexBlocks(this.formattedValue));
		},
	},
});
