
import { getTranslatedString as _ } from "@/i18n";
import { v4 as uuid4 } from "uuid";
import Dropdown from "@/components/ui/Dropdown.vue";
import Dialog from "@/components/ui/Dialog.vue";
import draggable from "vuedraggable";

import {
	getBlankChoice,
	Exercise,
	ExerciseState,
	ExerciseChoice,
	ExerciseType,
	ExerciseTestCase,
	getBlankTestCase,
	getBlankExercise,
	EventParticipationSlot,
	getFakeEventParticipationSlot,
	ExerciseSolution,
	getBlankExerciseSolution,
	ExerciseSolutionState,
	programmingExerciseTypeToLanguageId,
	ProgrammingExerciseType,
	ExerciseTestCaseAttachment,
	programmingExerciseTypes,
} from "@/models";
import Card from "@/components/ui/Card.vue";
import { defineComponent, PropType } from "@vue/runtime-core";
import useVuelidate from "@vuelidate/core";

import TextEditor from "@/components/ui/TextEditor.vue";
import TextInput from "@/components/ui/TextInput.vue";
import Btn from "@/components/ui/Btn.vue";
import TagInput from "@/components/ui/TagInput.vue";

import ChoiceEditor from "@/components/teacher/ExerciseEditor/ChoiceEditor.vue";
import CloudSaveStatus from "@/components/ui/CloudSaveStatus.vue";
import { courseIdMixin, loadingMixin, savingMixin } from "@/mixins";
import { DialogData } from "@/interfaces";

import { AutoSaveManager } from "@/autoSave";
import {
	exerciseStateOptions,
	exerciseTypeOptions,
	EXERCISE_AUTO_SAVE_DEBOUNCED_FIELDS,
	EXERCISE_AUTO_SAVE_DEBOUNCE_TIME_MS,
	EXERCISE_CHOICE_AUTO_SAVE_DEBOUNCED_FIELDS,
	EXERCISE_CHOICE_AUTO_SAVE_DEBOUNCE_TIME_MS,
	TEST_CASE_AUTO_SAVE_DEBOUNCED_FIELDS,
	TEST_CASE_AUTO_SAVE_DEBOUNCE_TIME_MS,
	CLOZE_SEPARATOR,
	EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_FIELDS,
	EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_TIME_MS,
	CLOZE_PLACEHOLDER_REGEX,
} from "@/const";
import CodeEditor from "@/components/ui/CodeEditor.vue";
import TestCaseEditor from "./TestCaseEditor.vue";
import Tooltip from "@/components/ui/Tooltip.vue";
import Toggle from "@/components/ui/Toggle.vue";
import {
	downloadExerciseTestCaseAttachment,
	heartbeatExercise,
	testProgrammingExerciseSolution,
	unlockExercise,
} from "@/api/exercises";
import CodeExecutionResults from "@/components/shared/CodeExecutionResults.vue";
import NumberInput from "@/components/ui/NumberInput.vue";
import { exerciseValidation } from "@/validation/models";
import ArticleHandle from "@/components/shared/HelpCenter/ArticleHandle.vue";
import {
	getMaxScore,
	isMultipleChoiceExercise,
	isProgrammingExercise,
} from "@/components/shared/Exercise/utils";
import { ExerciseSolutionSearchFilter } from "@/api/interfaces";
import SlotSkeleton from "@/components/ui/skeletons/SlotSkeleton.vue";
import { forceFileDownload, getCurrentUserId, setErrorNotification } from "@/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import ClozeExercise from "@/components/shared/Exercise/ClozeExercise.vue";

export default defineComponent({
	name: "ExerciseEditor",
	components: {
		Card,
		TextEditor,
		TextInput,
		Dropdown,
		ChoiceEditor,
		Btn,
		TagInput,
		CloudSaveStatus,
		Dialog,
		draggable,
		CodeEditor,
		TestCaseEditor,
		Tooltip,
		Toggle,
		CodeExecutionResults,
		NumberInput,
		ArticleHandle,
		SlotSkeleton,
		ClozeExercise,
	},
	props: {
		modelValue: {
			type: Object as PropType<Exercise>,
			required: true,
		},
		subExercise: {
			// hides certain fields depending on whether the exercise
			// is a base- or sub-exercise
			type: Boolean,
			default: false,
		},
		cloze: {
			// hides certain fields depending on whether the exercise is a cloze
			type: Boolean,
			default: false,
		},
		invalidChildWeight: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [courseIdMixin, savingMixin, loadingMixin],
	validations() {
		return {
			modelValue: exerciseValidation,
		};
	},
	setup() {
		const v = useVuelidate();
		//provide("v$", v);
		return { v$: v };
	},
	beforeUnmount() {
		this.unlockEditingObject();
	},
	async created() {
		// fetch exercise to make sure to have the most up to date version
		await this.$nextTick(
			// nextTick required to prevent render issues with vue-draggable
			async () => {
				this.fetchingExercise = true;
				// TODO overwriting the whole exercise isn't necessary, what we really want is locked_by
				try {
					await this.mainStore.getExercise({
						courseId: this.courseId,
						exerciseId: this.modelValue.id,
					});
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.fetchingExercise = false;
				}
			},
		);

		await this.lockEditingObject();

		this.autoSaveManager = new AutoSaveManager<Exercise>(
			this.modelValue,
			async changes =>
				await this.mainStore.updateExercise({
					// TODO use partial update
					courseId: this.courseId,
					exercise: { ...this.modelValue, ...changes },
				}),
			changes => {
				this.saving = true;
				this.savingError = false;
				this.mainStore.setExercise({ ...this.modelValue, ...changes });
			},
			EXERCISE_AUTO_SAVE_DEBOUNCED_FIELDS,
			EXERCISE_AUTO_SAVE_DEBOUNCE_TIME_MS,
			undefined,
			() => (this.savingError = true),
			() => (this.saving = false),
		);

		// instantiate managers for children
		this.modelValue.choices?.forEach(c =>
			// TODO extract auto-save manager instantiation to module
			this.instantiateChoiceAutoSaveManager(c),
		);

		// for test cases, also fetch attachments
		this.modelValue.testcases?.forEach(t => {
			this.instantiateTestCaseAutoSaveManager(t);
			this.mainStore.getExerciseTestCaseAttachments({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				testcaseId: t.id,
			});
		});

		this.loadingSolutions = true;
		try {
			await this.mainStore.getSolutionsByExercise({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				fromFirstPage: true, // TODO handle solutions on multiple pages
				filter: {
					states: [ExerciseSolutionState.APPROVED],
				} as ExerciseSolutionSearchFilter,
			});
			this.solutions.forEach(s => this.instantiateSolutionAutoSaveManager(s));
		} catch (e) {
			setErrorNotification(e);
		} finally {
			this.loadingSolutions = false;
		}
	},
	mounted() {
		if (this.cloze) {
			(this.v$ as any).$touch();
		}
	},
	data() {
		return {
			loadingSolutions: false,
			autoSaveManager: null as AutoSaveManager<Exercise> | null,
			choiceAutoSaveManagers: {} as Record<string, AutoSaveManager<ExerciseChoice>>,
			testCaseAutoSaveManagers: {} as Record<string, AutoSaveManager<ExerciseTestCase>>,
			solutionAutoSaveManagers: {} as Record<string, AutoSaveManager<ExerciseSolution>>,
			elementId: uuid4(),
			showSaved: false,
			saving: false,
			savingError: false,
			showDialog: false,
			showValidationErrors: false,
			preventEdit: true,
			dialogData: {
				title: "",
				text: "",
				onNo: null as null | (() => void),
				onYes: null as null | (() => void),
				error: false,
				confirmOnly: false,
			} as DialogData,
			exerciseStateOptions,
			ExerciseState,
			ExerciseType,
			textEditorInstance: null as any,
			editingClozeSubExerciseId: null as string | null,
			editableClozeSubExerciseId: null as string | null,
			solutionTestSlots: {} as Record<string, EventParticipationSlot>,
			testingSolutions: {} as Record<string, boolean>,
			showStickyStateDropdown: false,
			lockPollingHandle: null as null | number,
			heartbeatHandle: null as null | number,
			fetchingExercise: false,
			addingCloze: false,
		};
	},
	methods: {
		async onChoiceDragEnd(event: { oldIndex: number; newIndex: number }) {
			const draggedChoice = (this.modelValue.choices as ExerciseChoice[])[event.oldIndex];

			if (event.oldIndex !== event.newIndex) {
				await this.onUpdateChoice(draggedChoice.id, "_ordering", event.newIndex);
			}
		},
		async onBlur() {
			await this.autoSaveManager?.flush();
		},
		async onBaseExerciseChange<K extends keyof Exercise>(key: K, value: Exercise[K]) {
			await this.autoSaveManager?.onChange({ [key]: value });
		},
		async lockEditingObject() {
			const LOCK_POLLING_INTERVAL = 5000;
			const LOCK_HEARTBEAT_INTERVAL = 10000;

			const setUpHeartbeatPollingFn = () =>
				(this.heartbeatHandle = setInterval(
					// TODO defensively handle failures, i.e. lock might've been passed onto someone else
					async () => await heartbeatExercise(this.courseId, this.modelValue.id),
					LOCK_HEARTBEAT_INTERVAL,
				));

			try {
				await this.mainStore.lockExercise({
					courseId: this.courseId,
					exerciseId: this.modelValue.id,
				});
				setUpHeartbeatPollingFn();
			} catch (e: any) {
				if (e.response?.status === 403) {
					// if lock can't be acquired at the moment, periodically
					// poll to see if the object is still locked, stopping
					// once the lock has been acquired by the requesting user
					this.lockPollingHandle = setInterval(async () => {
						await this.mainStore.getExercise({
							courseId: this.courseId,
							exerciseId: this.modelValue.id,
						});
						// user has finally acquired the lock; stop polling
						if (this.modelValue.locked_by?.id === getCurrentUserId()) {
							clearInterval(this.lockPollingHandle as number);
							setUpHeartbeatPollingFn();
							this.lockPollingHandle = null;
						}
					}, LOCK_POLLING_INTERVAL);
				} else {
					setErrorNotification(e);
				}
			}
		},
		async unlockEditingObject() {
			if (typeof this.heartbeatHandle === "number") {
				clearInterval(this.heartbeatHandle);
			}
			if (typeof this.lockPollingHandle === "number") {
				clearInterval(this.lockPollingHandle);
			}
			await unlockExercise(this.courseId, this.modelValue.id);
		},
		onFocusNonDraft() {
			this.showDialog = true;
			this.dialogData = {
				title: _("exercise_editor.edit_non_draft_title"),
				text: _("exercise_editor.edit_non_draft_body"),
				yesText: _("misc.edit"),
				noText: _("dialog.default_cancel_text"),
				onNo: () => (this.showDialog = false),
				onYes: () => {
					this.preventEdit = false;
					this.showDialog = false;
				},
			};
		},
		async onTestSolution(solutionId?: string) {
			try {
				if (solutionId) {
					this.testingSolutions[solutionId] = true;
					await this.solutionAutoSaveManagers[solutionId]?.flush();
				}

				const executionResults = await testProgrammingExerciseSolution(
					this.courseId,
					this.modelValue.id,
				);

				Object.keys(executionResults).forEach(sId => {
					if (!solutionId || sId == solutionId) {
						const fakeSlot = (this.solutionTestSlots[sId] ??=
							getFakeEventParticipationSlot(this.modelValue));
						fakeSlot.execution_results = executionResults[sId];
					}
				});
			} catch (e) {
				setErrorNotification(e);
			} finally {
				if (solutionId) {
					this.testingSolutions[solutionId] = false;
				}
			}
		},
		onTextSelectionChange(event: {
			fullText: string;
			text: string;
			range: { index: number; length: number };
		}) {
			/**
			 * Used to detect whether the text cursor is positioned within the limits
			 * of a cloze exercise placeholder (i.e. [[<exercise_id>]])
			 */
			if (this.modelValue.exercise_type !== ExerciseType.COMPLETION) {
				return;
			}
			const clozeSeparatorPositions: [number, string, string][] = [
				...event.fullText.matchAll(CLOZE_PLACEHOLDER_REGEX),
			].map(m => [
				m.index as number, // position of the match
				m[0], // full text match (e.g. `[[123]]`)
				m[1], // capture group match, in this case the id of the cloze sub exercise
			]);
			if (
				event.range.length === 0
				//&& event.text.includes(CLOZE_SEPARATOR)
			) {
				let i = 0;
				for (const [matchPosition, matchText, clozeId] of clozeSeparatorPositions) {
					if (
						event.range.index >= matchPosition &&
						event.range.index + event.range.length <= matchPosition + matchText.length
					) {
						this.editableClozeSubExerciseId = clozeId;
						return;
					}
					i++;
				}
			}
			this.editableClozeSubExerciseId = null;
		},
		onExerciseTypeChange(newVal: ExerciseType) {
			this.onBaseExerciseChange("exercise_type", newVal);
		},
		/* CRUD on related objects */
		async onAddSolution() {
			const newSolution: ExerciseSolution = await this.mainStore.createExerciseSolution({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				solution: getBlankExerciseSolution(ExerciseSolutionState.APPROVED),
			});
			this.instantiateSolutionAutoSaveManager(newSolution);
		},
		async onUpdateSolution<K extends keyof ExerciseSolution>(
			solutionId: string,
			key: K,
			value: ExerciseSolution[K],
		) {
			await this.solutionAutoSaveManagers[solutionId].onChange({
				[key]: value,
			});
		},
		async onBlurSolution(solutionId: string) {
			await this.solutionAutoSaveManagers[solutionId].flush();
		},
		async onAddChoice() {
			// when the exercise is set to be all_or_nothing, we need to make sure that
			// incorrect choices have a `correctness` value of -1.
			//.if we didn't do this, then by all_or_nothing logic the choice would
			// actually be considered true because selecting it doesn't
			// decrease the score obtained
			const score = this.modelValue.all_or_nothing ? -1 : 0;

			const newChoice: ExerciseChoice = await this.mainStore.createExerciseChild({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				childType: "choice",
				payload: getBlankChoice(score),
			});
			this.instantiateChoiceAutoSaveManager(newChoice);
		},
		async onUpdateChoice<K extends keyof ExerciseChoice>(
			choiceId: string,
			key: K,
			value: ExerciseChoice[K],
		) {
			await this.choiceAutoSaveManagers[choiceId].onChange({
				[key]: value,
			});
		},
		async onBlurChoice(choiceId: string) {
			await this.choiceAutoSaveManagers[choiceId].flush();
		},
		async onDeleteChoice(choiceId: string) {
			if (confirm(_("exercise_editor.confirm_delete_choice"))) {
				await this.withLoading(
					async () =>
						await this.mainStore.deleteExerciseChild({
							courseId: this.courseId,
							exerciseId: this.modelValue.id,
							childType: "choice",
							childId: choiceId,
						}),
					setErrorNotification,
				);
			}
		},
		async onDeleteSolution(solutionId: string) {
			if (confirm(_("exercise_editor.confirm_delete_solution"))) {
				await this.withLoading(
					async () =>
						await this.mainStore.deleteExerciseSolution({
							courseId: this.courseId,
							exerciseId: this.modelValue.id,
							solutionId,
						}),
					setErrorNotification,
				);
			}
		},
		async onAddSubExercise() {
			const newSubExercise: Exercise = await this.mainStore.createExerciseChild({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				childType: "sub_exercise",
				payload: getBlankExercise(),
			});
			return newSubExercise;
		},
		async onDeleteSubExercise(exerciseId: string) {
			if (confirm(_("exercise_editor.confirm_delete_sub_exercise"))) {
				await this.withLoading(
					async () =>
						await this.mainStore.deleteExerciseChild({
							courseId: this.courseId,
							exerciseId: this.modelValue.id,
							childType: "sub_exercise",
							childId: exerciseId,
						}),
					setErrorNotification,
				);
			}
		},
		async onAddTestCase() {
			const newTestcase: ExerciseTestCase = await this.mainStore.createExerciseChild({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				childType: "testcase",
				payload: getBlankTestCase(),
			});
			this.instantiateTestCaseAutoSaveManager(newTestcase);
		},
		async onUpdateTestCase<K extends keyof ExerciseTestCase>(
			testCaseId: string,
			key: K,
			value: ExerciseTestCase[K],
		) {
			await this.testCaseAutoSaveManagers[testCaseId].onChange({
				[key]: value,
			});
		},
		async onBlurTestCase(testcaseId: string) {
			await this.testCaseAutoSaveManagers[testcaseId].flush();
		},
		async onDeleteTestCase(testcaseId: string) {
			if (confirm(_("exercise_editor.confirm_delete_testcase"))) {
				await this.withLoading(
					async () =>
						await this.mainStore.deleteExerciseChild({
							courseId: this.courseId,
							exerciseId: this.modelValue.id,
							childType: "testcase",
							childId: testcaseId,
						}),
					setErrorNotification,
				);
			}
		},
		async onTestCaseCreateAttachment(testcaseId: string, attachment: Blob) {
			await this.mainStore.createExerciseTestCaseAttachment({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				testcaseId,
				attachment,
			});
		},
		async onTestCaseDeleteAttachment(
			testcaseId: string,
			attachment: ExerciseTestCaseAttachment,
		) {
			if (
				!confirm(
					_("exercise_editor.confirm_delete_testcase_attachment") +
						" " +
						(attachment.attachment as { name: string }).name +
						"?",
				)
			) {
				return;
			}
			await this.mainStore.deleteExerciseTestCaseAttachment({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				testcaseId,
				attachmentId: attachment.id,
			});
		},
		async onTestCaseDownloadAttachment(
			testcaseId: string,
			attachment: ExerciseTestCaseAttachment,
		) {
			forceFileDownload(
				{
					data: await downloadExerciseTestCaseAttachment(
						this.courseId,
						this.modelValue.id,
						testcaseId,
						attachment.id,
					),
				},
				(attachment.attachment as { name: string }).name,
			);
		},
		async onAddTag(tag: string, isPublic: boolean) {
			await this.mainStore.addExerciseTag({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				tag,
				isPublic,
			});
			await this.mainStore.getTags({
				courseId: this.courseId,
				includeExerciseCount: false,
			});
		},
		async onRemoveTag(tag: string, isPublic: boolean) {
			await this.mainStore.removeExerciseTag({
				courseId: this.courseId,
				exerciseId: this.modelValue.id,
				tag,
				isPublic,
			});
		},
		async onAddCloze() {
			const selection = this.textEditorInstance.getSelection();
			const insertionIndex = selection
				? selection.index + selection.length
				: this.textEditorInstance.getLength();

			try {
				this.addingCloze = true;
				const subExercise = await this.onAddSubExercise();

				// insert placeholder for new cloze sub-exercise
				this.textEditorInstance.insertText(
					insertionIndex,
					CLOZE_SEPARATOR(subExercise.id),
				);

				// focus on most recently added cloze
				this.editingClozeSubExerciseId = subExercise.id;
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.addingCloze = false;
			}
		},
		/* end CRUD on related objects */
		onExerciseStateChange(newState: ExerciseState) {
			this.v$.$touch();
			// TODO don't show errors in sub-exercises, or show them separately
			// TODO highlight in red the number-input of child weights and and correctness score (for "missing correct choice" error)
			// validation errors dialog
			if (newState != ExerciseState.DRAFT && this.v$.$errors.length > 0) {
				this.showDialog = true;
				this.showValidationErrors = true;
				this.dialogData = {
					title: _("exercise_editor.cannot_publish"),
					text: _("exercise_editor.cannot_publish_body"),
					onYes: () => {
						this.showDialog = false;
						this.showValidationErrors = false;
					},
					error: true,
					confirmOnly: true,
				};
				return;
			}

			// publish exercise confirmation
			if (newState === ExerciseState.PUBLIC) {
				this.showDialog = true;
				this.dialogData = {
					title: _("exercise_editor.make_public_confirmation_title"),
					text: _("exercise_editor.make_public_confirmation_body"),
					onYes: () => {
						this.onBaseExerciseChange("state", newState);
						this.showDialog = false;
					},
					onNo: () => (this.showDialog = false),
					error: false,
					confirmOnly: false,
				};
			} else {
				this.onBaseExerciseChange("state", newState);
			}
		},
		instantiateChoiceAutoSaveManager(choice: ExerciseChoice) {
			this.choiceAutoSaveManagers[choice.id] = new AutoSaveManager<ExerciseChoice>(
				choice,
				async changes => {
					// if choices are re-ordered, re-fetch them from server
					const reFetch = Object.keys(changes).includes("_ordering");
					await this.mainStore.updateExerciseChild({
						childType: "choice",
						courseId: this.courseId,
						exerciseId: this.modelValue.id,
						payload: { ...choice, ...changes },
						reFetch,
					});
				},
				changes => {
					this.saving = true;
					this.savingError = false;
					this.mainStore.setExerciseChild({
						childType: "choice",
						exerciseId: this.modelValue.id,
						payload: { ...choice, ...changes },
					});
				},
				EXERCISE_CHOICE_AUTO_SAVE_DEBOUNCED_FIELDS,
				EXERCISE_CHOICE_AUTO_SAVE_DEBOUNCE_TIME_MS,
				undefined,
				() => (this.savingError = true),
				() => (this.saving = false),
			);
		},
		instantiateSolutionAutoSaveManager(solution: ExerciseSolution) {
			this.solutionAutoSaveManagers[solution.id] = new AutoSaveManager<ExerciseSolution>(
				solution,
				async changes =>
					await this.mainStore.updateExerciseSolution({
						courseId: this.courseId,
						exerciseId: this.modelValue.id,
						solution: { ...solution, ...changes },
					}),
				changes => {
					this.saving = true;
					this.savingError = false;
					this.mainStore.setExerciseSolution({
						exerciseId: this.modelValue.id,
						payload: { ...solution, ...changes },
					});
				},
				EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_FIELDS,
				EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_TIME_MS,
				undefined,
				() => (this.savingError = true),
				() => (this.saving = false),
			);
		},
		instantiateTestCaseAutoSaveManager(testcase: ExerciseTestCase) {
			this.testCaseAutoSaveManagers[testcase.id] = new AutoSaveManager<ExerciseTestCase>(
				testcase,
				async changes => {
					// if choices are re-ordered, re-fetch them from server
					const reFetch = Object.keys(changes).includes("_ordering");
					await this.mainStore.updateExerciseChild({
						childType: "testcase",
						courseId: this.courseId,
						exerciseId: this.modelValue.id,
						payload: { ...testcase, ...changes },
						reFetch,
					});
					await this.onTestSolution();
				},
				changes => {
					this.saving = true;
					this.savingError = false;
					this.mainStore.setExerciseChild({
						childType: "testcase",
						exerciseId: this.modelValue.id,
						payload: { ...testcase, ...changes },
					});
				},
				TEST_CASE_AUTO_SAVE_DEBOUNCED_FIELDS,
				TEST_CASE_AUTO_SAVE_DEBOUNCE_TIME_MS,
				undefined,
				() => (this.savingError = true),
				() => (this.saving = false),
			);
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		solutions() {
			return this.mainStore.getPaginatedSolutionsByExerciseId(this.modelValue.id).data;
		},
		showNoChoicePenaltyWarning(): boolean {
			return (
				this.modelValue.exercise_type ===
					ExerciseType.MULTIPLE_CHOICE_MULTIPLE_POSSIBLE &&
				!this.modelValue.all_or_nothing &&
				!this.v$.$invalid &&
				!(this.modelValue.choices ?? []).some(c => (c.correctness ?? 0) < 0)
			);
		},
		isMultipleChoice(): boolean {
			return isMultipleChoiceExercise(this.modelValue);
		},
		isProgrammingExercise(): boolean {
			return isProgrammingExercise(this.modelValue);
		},
		maxScore(): number | null {
			return getMaxScore(this.modelValue);
		},
		exerciseTypeOptions() {
			return exerciseTypeOptions.filter(
				o => !this.subExercise || o.value !== ExerciseType.AGGREGATED,
			);
		},
		canBeAllOrNothing() {
			const allOrNothingTypes = [
				ExerciseType.MULTIPLE_CHOICE_MULTIPLE_POSSIBLE,
				...programmingExerciseTypes,
			];
			console.log(
				"All",
				allOrNothingTypes,
				this.modelValue.exercise_type,
				allOrNothingTypes.includes(this.modelValue.exercise_type as ExerciseType),
			);
			return allOrNothingTypes.includes(this.modelValue.exercise_type as ExerciseType);
		},
		exerciseLocked(): boolean {
			return (
				!!this.modelValue.locked_by &&
				this.modelValue.locked_by.id != this.metaStore.user.id
			);
		},
		editingCloze(): Exercise | null {
			if (this.editingClozeSubExerciseId === null) {
				return null;
			}
			return (this.modelValue.sub_exercises as Exercise[]).find(
				s => s.id == this.editingClozeSubExerciseId,
			) as Exercise;
		},
		childWeightError() {
			return (this.v$ as any).modelValue.$errors.find((e: any) =>
				["modelValue.sub_exercises-subExerciseWeightAddsUp"].includes(e.$uid),
			);
		},
		choicesCorrectnessError() {
			return (this.v$ as any).modelValue.$errors.find((e: any) =>
				["modelValue.choices-atLeastOneCorrectChoice"].includes(e.$uid),
			);
		},
		languageCode() {
			return programmingExerciseTypeToLanguageId[
				this.modelValue.exercise_type as ProgrammingExerciseType
			];
		},
		stateDropdownId() {
			return "exercise_state_" + this.elementId;
		},
		modelValueWrapperSlot() {
			return getFakeEventParticipationSlot(this.modelValue);
		},
	},
});
