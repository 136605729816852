
//import { useMetaStore } from "@/stores/metaStore";
import { isDemoMode, logAnalyticsEvent } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import Avatar from "./Avatar.vue";
//import { mapStores } from "pinia";
import Btn from "./Btn.vue";
import DropdownMenu from "./DropdownMenu.vue";
import LocaleSelector from "./LocaleSelector.vue";
// import Tooltip from "./Tooltip.vue";
export default defineComponent({
	name: "AppBar",
	props: {
		sticky: {
			type: Boolean,
			deafault: true,
		},
		moreHorizontalPadding: {
			type: Boolean,
			default: true,
		},
		sidebarCollapsed: {
			type: Boolean,
			required: true,
		},
		showCollapseSideBarButton: {
			type: Boolean,
			required: true,
		},
		showHelpCenterButton: {
			type: Boolean,
			required: true,
		},
		showLocaleSelector: {
			type: Boolean,
			default: true,
		},
		showMobileSidebarButton: {
			type: Boolean,
			required: true,
		},
	},
	setup() {
		// TODO temporary workaround for a werid issue: if use import like this:
		//import { useMainStore } from "./stores/mainStore";
		// the app won't render as useMainStore will be undefined in other components; investigate
		const useMetaStore = require("../../stores/metaStore").useMetaStore;
		const metaStore = useMetaStore();
		return {
			metaStore,
		};
	},
	data() {
		return {
			donateExpanded: false,
		};
	},
	methods: {
		onToggleDonate() {
			if (!this.donateExpanded) {
				logAnalyticsEvent("toggledDonate", {});
			}
			this.donateExpanded = !this.donateExpanded;
		},
	},
	computed: {
		//...mapStores(useMetaStore),
		isDemoMode() {
			return isDemoMode();
		},
		logoUrl() {
			return require("../../assets/logo.png");
		},
	},
	components: {
		Btn,
		DropdownMenu,
		LocaleSelector,
		Avatar,
		//	Tooltip
	},
});
