
import { getTranslatedString as _ } from "@/i18n";
import { ExerciseSearchFilter } from "@/api/interfaces";
import Btn from "@/components/ui/Btn.vue";
import Chipset from "@/components/ui/Chipset.vue";
import { ExerciseState, ExerciseType, Tag } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import { icons as exerciseTypesIcons } from "@/assets/exerciseTypesIcons";
import { icons as exerciseStatesIcons } from "@/assets/exerciseStatesIcons";
import TextInput from "../ui/TextInput.vue";
import { isEmptyFilter } from "@/api/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
export default defineComponent({
	name: "ExerciseSearchFilters",
	components: {
		Chipset,
		Btn,
		TextInput,
	},
	props: {
		modelValue: {
			type: Object as PropType<ExerciseSearchFilter>,
			required: true,
		},
		full: {
			type: Boolean,
			default: false,
		},
		allowFilterByState: {
			type: Boolean,
			default: true,
		},
		allowFilterByType: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			expanded: false,
		};
	},
	methods: {
		emitUpdate(key: keyof ExerciseSearchFilter, value: unknown) {
			this.$emit("update:modelValue", {
				...this.modelValue,
				[key]: value,
			});
		},
	},
	computed: {
		...mapStores(useMainStore),
		emptyFilters() {
			return isEmptyFilter(this.modelValue);
		},
		tagsOptions() {
			return this.mainStore.tags.map((t: Tag) => ({
				icons: this.modelValue.tags.includes(t.id as string) ? ["done-sm"] : null,
				value: t.id,
				content: t.name,
			}));
		},
		exerciseTypeOptions() {
			return (Object.keys(ExerciseType) as unknown[] as ExerciseType[])
				.filter((key: string | number) => parseInt(key as string) == key) //(ExerciseType[key] as unknown) == 'number')
				.map(key => ({
					icons: exerciseTypesIcons[key],
					value: key,
					content: _("exercise_types." + key),
				}));
		},
		exerciseStateOptions() {
			return (Object.keys(ExerciseState) as unknown[] as ExerciseState[])
				.filter((key: string | number) => parseInt(key as string) == key) //(ExerciseType[key] as unknown) == 'number')
				.map(key => ({
					icons: exerciseStatesIcons[key],
					value: key,
					content: _("exercise_states." + key),
					description: _("exercise_states_descriptions." + key),
				}));
		},
	},
});
