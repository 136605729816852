
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "../ui/Btn.vue";
import { fileViewerProps } from "./shared";
import { arraybufferToBase64, setErrorNotification } from "@/utils";
import { downloadFileNode } from "@/api";
import { fileViewerMixin } from "@/mixins";
import Spinner from "../ui/Spinner.vue";
export default defineComponent({
	name: "ImageViewer",
	props: {
		...fileViewerProps,
	},
	components: {
		Btn,
		Spinner,
	},
	mixins: [fileViewerMixin],
	async mounted() {
		this.isLoading = true;
		try {
			await this.downloadNodeFile();
			await this.setInitialWidth();
		} catch (e) {
			setErrorNotification(e);
		} finally {
			this.isLoading = false;
		}
		const bodyContainsOverflowHidden =
			document.body.classList.contains("overflow-y-hidden");
		if (!bodyContainsOverflowHidden) {
			document.body.classList.add("overflow-y-hidden");
		}
	},
	beforeUnmount() {
		const bodyContainsOverflowHidden =
			document.body.classList.contains("overflow-y-hidden");
		if (bodyContainsOverflowHidden) {
			document.body.classList.remove("overflow-y-hidden");
		}
	},
	data() {
		return {
			//downloading: true,
			isLoading: true,
			width: 100,
			initialWidth: 100,
			//source: "",
		};
	},
	methods: {
		// async downloadNodeFile() {
		// 	const fileBlob = await downloadFileNode(this.url);
		// 	console.log("BLOB", fileBlob);
		// 	this.source = arraybufferToBase64(fileBlob);
		// 	this.downloading = false;
		// },
		async setInitialWidth() {
			const MAX_INITIAL_WIDTH_RATIO = 0.7;
			const windowWidth = window.innerWidth;
			const imageWidth: number = await new Promise(resolve => {
				const image = new Image();
				image.onload = () => resolve(image.naturalWidth);
				image.src = this.base64Source;
			});
			if (imageWidth <= windowWidth * MAX_INITIAL_WIDTH_RATIO) {
				this.initialWidth = 100;
			} else {
				this.initialWidth = ((MAX_INITIAL_WIDTH_RATIO * windowWidth) / imageWidth) * 100;
			}
			this.width = this.initialWidth;
		},
		onZoom(multiplier: number) {
			const MIN_WIDTH = this.initialWidth / 3;
			const MAX_WIDTH = this.initialWidth * 3;
			const FACTOR = this.initialWidth / 5;
			if (multiplier > 0) {
				this.width = Math.min(MAX_WIDTH, this.width + FACTOR);
			} else {
				this.width = Math.max(MIN_WIDTH, this.width - FACTOR);
			}
		},
	},
	computed: {
		base64Source() {
			return `data:image;base64,${this.source}`;
		},
	},
});
