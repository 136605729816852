import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "linear-activity" }
const _hoisted_2 = {
  key: 0,
  class: "indeterminate"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.indeterminate)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (_openBlock(), _createElementBlock("div", {
          key: 1,
          class: "determinate",
          style: _normalizeStyle({ width: _ctx.progress + '%' })
        }, null, 4))
  ]))
}