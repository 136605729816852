
import { User } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Avatar from "../ui/Avatar.vue";
import Btn from "../ui/Btn.vue";
import Timestamp from "../ui/Timestamp.vue";
export default defineComponent({
	name: "Message",
	props: {
		message: {
			type: String,
			required: true,
		},
		user: {
			type: Object as PropType<User>,
			required: true,
		},
		created: {
			type: String,
			required: true,
		},
		canDelete: {
			type: Boolean,
			required: true,
		},
	},
	emits: {
		deleteMessage() {
			return true;
		},
	},
	methods: {},
	computed: {},
	components: { Avatar, Timestamp, Btn },
});
