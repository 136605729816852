import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MinimalExercisePreviewSkeleton = _resolveComponent("MinimalExercisePreviewSkeleton")!
  const _component_FullExercise = _resolveComponent("FullExercise")!
  const _component_ExerciseSolutionContainer = _resolveComponent("ExerciseSolutionContainer")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.firstLoading)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_MinimalExercisePreviewSkeleton)
        ]))
      : (_ctx.exercise)
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createVNode(_component_FullExercise, { exercise: _ctx.exercise }, null, 8, ["exercise"]),
            _createVNode(_component_ExerciseSolutionContainer, {
              exercise: _ctx.exercise,
              solutions: _ctx.exerciseSolutions,
              showFirst: _ctx.solutionId ? [_ctx.solutionId] : [],
              forceShowAll: true,
              showTeacherControls: true,
              allowAddSolution: false,
              showTitle: false,
              canLoadMore: !_ctx.exercisePaginatedSolutions.isLastPage,
              onLoadMore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.loadMore()))
            }, null, 8, ["exercise", "solutions", "showFirst", "canLoadMore"])
          ]))
        : _createCommentVNode("", true)
  ]))
}