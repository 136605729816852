
import { defineComponent, PropType } from "@vue/runtime-core";
import LinearProgress from "./LinearProgress.vue";

export default defineComponent({
	name: "Card",
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String as PropType<"sm" | "default">,
			default: "default",
		},
		expandSideOnSmall: {
			type: Boolean,
			default: false,
		},
		highlighted: {
			type: Boolean,
			default: false,
		},
		marginLess: {
			type: Boolean,
			default: false,
		},
		borderLess: {
			type: Boolean,
			default: false,
		},
		focusable: {
			type: Boolean,
			default: false,
		},
		filled: {
			type: Boolean,
			default: false,
		},
		hoverable: {
			type: Boolean,
			default: true,
		},
	},
	components: { LinearProgress },
});
