import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, vModelCheckbox as _vModelCheckbox, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["for"]
const _hoisted_2 = { class: "flex flex-col w-full" }
const _hoisted_3 = { class: "flex items-start w-full" }
const _hoisted_4 = {
  key: 0,
  class: "absolute w-12 h-12 transition-all duration-100 ease-in-out rounded-full bg-opacity-10 bg-primary checkbox-shadow"
}
const _hoisted_5 = ["disabled", "id", "value"]
const _hoisted_6 = {
  key: 0,
  class: "flex items-center"
}
const _hoisted_7 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toggle = _resolveComponent("Toggle")!
  const _directive_wave_trigger = _resolveDirective("wave-trigger")!
  const _directive_wave = _resolveDirective("wave")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
      return (_openBlock(), _createElementBlock("label", {
        class: _normalizeClass([[
				_ctx.disabled ? '' : 'checkbox-container',
				'relative  flex space-x-1.5 items-start ',
				_ctx.optionClass,
				_ctx.itemClass,
			], "checkbox-item"]),
        key: _ctx.id + '-option-' + index,
        for: _ctx.id + '-option-' + index
      }, [
        _createElementVNode("div", _hoisted_2, [
          _createElementVNode("div", _hoisted_3, [
            (!_ctx.useToggles)
              ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, null, 512)), [
                  [_directive_wave_trigger, void 0, "checkbox"],
                  [_directive_wave, _ctx.disabled ? false : { trigger: 'checkbox', color: '#666ad1' }]
                ])
              : _createCommentVNode("", true),
            (!_ctx.useToggles)
              ? _withDirectives((_openBlock(), _createElementBlock("input", {
                  key: 1,
                  disabled: _ctx.disabled,
                  id: _ctx.id + '-option-' + index,
                  type: "checkbox",
                  "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.proxyModelValue) = $event)),
                  style: {"min-width":"15px","min-height":"15px"},
                  value: option.value,
                  class: "mr-2 mt-5px"
                }, null, 8, _hoisted_5)), [
                  [_vModelCheckbox, _ctx.proxyModelValue]
                ])
              : (_openBlock(), _createBlock(_component_Toggle, {
                  key: 2,
                  class: "order-1 ml-4 mt-5px",
                  overrideId: _ctx.id + '-option-' + index,
                  modelValue: _ctx.proxyModelValue.includes(option.value),
                  "onUpdate:modelValue": ($event: any) => (_ctx.onToggleUpdate($event, option))
                }, null, 8, ["overrideId", "modelValue", "onUpdate:modelValue"])),
            _createElementVNode("div", {
              class: _normalizeClass([_ctx.labelClass, "flex flex-col"])
            }, [
              (!_ctx.usesCustomSlot)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                    _renderSlot(_ctx.$slots, "default", {
                      icons: option.icons
                    }),
                    _createElementVNode("p", {
                      innerHTML: option.content
                    }, null, 8, _hoisted_7)
                  ]))
                : _renderSlot(_ctx.$slots, "custom", {
                    key: 1,
                    item: {
								content: option.content,
								icons: option.icons,
								value: option.value,
							}
                  }),
              _renderSlot(_ctx.$slots, "item", {
                description: option.description
              })
            ], 2)
          ])
        ])
      ], 10, _hoisted_1))
    }), 128))
  ]))
}