import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "w-full flex flex-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_view = _resolveComponent("router-view")!
  const _component_NumberInput = _resolveComponent("NumberInput")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_view, { class: "w-full" }),
    _createVNode(_component_Dialog, {
      "show-dialog": _ctx.editingMat,
      onNo: _cache[1] || (_cache[1] = ($event: any) => (_ctx.editingMat = false)),
      onYes: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSaveMat())),
      "yes-text": _ctx.$t('misc.save'),
      "no-text": _ctx.$t('dialog.default_cancel_text')
    }, {
      title: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("student_data.edit_mat_title")), 1)
      ]),
      body: _withCtx(() => [
        _createVNode(_component_NumberInput, {
          modelValue: _ctx.dirtyMat,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dirtyMat) = $event)),
          class: "mt-8"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("student_data.your_mat")), 1)
          ]),
          _: 1
        }, 8, ["modelValue"])
      ]),
      _: 1
    }, 8, ["show-dialog", "yes-text", "no-text"])
  ]))
}