import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.editorComponentName), _mergeProps(_ctx.$props, {
    onCloseEditor: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeEditor'))),
    onPatchNode: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('patchNode', $event))),
    onBlur: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('blur'))),
    onSave: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('save'))),
    onDeleteNode: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$emit('deleteNode', $event)))
  }), null, 16))
}