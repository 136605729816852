
import { ExerciseChoice } from "@/models";
import { defineComponent, PropType, inject } from "@vue/runtime-core";
import TextEditor from "@/components/ui/TextEditor.vue";
import NumberInput from "@/components/ui/NumberInput.vue";
import Tooltip from "@/components/ui/Tooltip.vue";
import TextInput from "@/components/ui/TextInput.vue";
import Btn from "@/components/ui/Btn.vue";
import useVuelidate from "@vuelidate/core";
import { exerciseChoiceValidation } from "@/validation/models";
import Toggle from "@/components/ui/Toggle.vue";

export default defineComponent({
	name: "ChoiceEditor",
	props: {
		modelValue: {
			type: Object as PropType<ExerciseChoice>,
			required: true,
		},
		iconType: {
			type: String as PropType<"checkbox" | "radio" | "dropdown">,
			required: true,
		},
		singleLine: {
			type: Boolean,
			default: false,
		},
		invalidCorrectness: {
			type: Boolean,
			default: false,
		},
		correctnessIsBoolean: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		correctnessIsBoolean(newVal) {
			// when the exercise is set to be all_or_nothing and this prop becomes true,
			// we need to make sure that incorrect choices have a `correctness` value
			// of -1. to ensure this, we instantly emit an update if the choice
			// `correctness` is 0. if we didn't do this, then by all_or_nothing logic the
			// choice would actually be considered true because selecting it doesn't
			// decrease the score obtained
			if (newVal && this.modelValue.correctness === 0) {
				this.onUpdate("correctness", -1);
			}
		},
	},
	validations() {
		return {
			choice: exerciseChoiceValidation,
		};
	},
	setup() {
		const v = useVuelidate();
		// provide("v$", v);
		return { v$: v };
	},
	components: {
		TextEditor,
		NumberInput,
		//Tooltip,
		TextInput,
		Btn,
		Toggle,
	},
	methods: {
		onUpdate<K extends keyof ExerciseChoice>(field: K, value: ExerciseChoice[K]) {
			this.$emit("choiceUpdate", { field, value });
		},
	},
	computed: {
		choice() {
			return this.modelValue;
		},
		booleanCorrectnessProxy: {
			get() {
				return (this.modelValue.correctness ?? 0) > 0;
			},
			set(value: boolean) {
				this.onUpdate("correctness", value ? 1 : -1);
			},
		},
	},
});
