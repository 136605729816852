
import { defineComponent } from "@vue/runtime-core";
import MultiIcon from "@/components/ui/MultiIcon.vue";

import { v4 as uuid4 } from "uuid";
import Tooltip from "./Tooltip.vue";
import Btn from "./Btn.vue";
export default defineComponent({
	name: "Chipset",
	props: {
		modelValue: Array,
		value: {
			default: null,
		},
		options: Array,
		allowMultiple: {
			type: Boolean,
			default: true,
		},
		filled: {
			type: Boolean,
			default: false,
		},
		compact: {
			type: Boolean,
			default: false,
		},
		wrap: {
			type: Boolean,
			default: true,
		},
		deletable: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		showAddChip: {
			type: Boolean,
			default: false,
		},
		addChipTooltip: {
			type: String,
			default: "",
		},
	},
	components: {
		MultiIcon,
		Tooltip,
		Btn,
	},
	created() {
		this.id = uuid4();
	},
	data() {
		return {
			id: "",
		};
	},
	methods: {
		onRemove(val) {
			// console.log(
			// 	"BEFORE",
			// 	this.proxyModelValue,
			// 	"VAL",
			// 	val,
			// 	"AFTER",
			// 	this.proxyModelValue?.filter(o => o.value != val),
			// );
			//this.proxyModelValue = this.proxyModelValue?.filter(o => o.value != val);
			this.$emit("deleteChip", val);
		},
	},
	computed: {
		proxyModelValue: {
			get() {
				return this.modelValue;
			},
			set(val: unknown) {
				this.$emit(
					"update:modelValue",
					this.allowMultiple ? val : val === this.modelValue?.[0] ? [] : [val],
				);
			},
		},
	},
});
