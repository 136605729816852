import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createElementBlock as _createElementBlock, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "flex w-full items-center mb-12" }
const _hoisted_3 = { class: "mb-0 ml-2 mr-auto" }
const _hoisted_4 = {
  key: 1,
  class: "flex space-x-3 items-center"
}
const _hoisted_5 = { class: "text-muted" }
const _hoisted_6 = {
  key: 2,
  class: "ml-2"
}
const _hoisted_7 = { class: "mb-8 flex items-center space-x-8" }
const _hoisted_8 = { class: "w-1/3" }
const _hoisted_9 = { class: "mb-8" }
const _hoisted_10 = { key: 1 }
const _hoisted_11 = { class: "mb-4" }
const _hoisted_12 = { class: "grid lg:grid-cols-2 lg:gap-6 gap-4 grid-cols-1" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinearProgress = _resolveComponent("LinearProgress")!
  const _component_Btn = _resolveComponent("Btn")!
  const _component_CloudSaveStatus = _resolveComponent("CloudSaveStatus")!
  const _component_Dropdown = _resolveComponent("Dropdown")!
  const _component_TextEditor = _resolveComponent("TextEditor")!
  const _component_FileNode = _resolveComponent("FileNode")!
  const _component_FileUpload = _resolveComponent("FileUpload")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.blockingSaving)
      ? (_openBlock(), _createBlock(_component_LinearProgress, {
          key: 0,
          class: "absolute top-0"
        }))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_Btn, {
        variant: 'icon',
        outline: true,
        class: "-ml-2"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", {
            class: "material-icons-outlined",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('closeEditor')))
          }, " close")
        ]),
        _: 1
      }),
      _createElementVNode("h1", _hoisted_3, _toDisplayString(_ctx.$t("course_tree.announcement_editor_title")), 1),
      (_ctx.showAutoSaveIndicator)
        ? (_openBlock(), _createBlock(_component_CloudSaveStatus, {
            key: 0,
            saving: _ctx.saving,
            hadError: _ctx.savingError,
            class: "mt-1 mr-6"
          }, null, 8, ["saving", "hadError"]))
        : _createCommentVNode("", true),
      (_ctx.modelValue.state === _ctx.AnnouncementNodeState.DRAFT)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.$t("course_tree.draft")), 1),
            _createVNode(_component_Btn, {
              onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onPublish()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t("course_tree.publish_announcement")), 1)
              ]),
              _: 1
            })
          ]))
        : _createCommentVNode("", true),
      (!_ctx.publishOnly)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_Btn, {
              outline: _ctx.modelValue.state === _ctx.AnnouncementNodeState.DRAFT,
              disabled: _ctx.blockingSaving,
              onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onSave()))
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.modelValue.state === _ctx.AnnouncementNodeState.DRAFT
							? _ctx.$t("course_tree.save_draft")
							: _ctx.$t("course_tree.save")), 1)
              ]),
              _: 1
            }, 8, ["outline", "disabled"])
          ]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      _createElementVNode("div", _hoisted_8, [
        _createVNode(_component_Dropdown, {
          loading: _ctx.loadingTopics,
          options: _ctx.topicsAsOptions,
          modelValue: _ctx.modelValue.parent_id,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => (_ctx.onParentChange($event)))
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.$t("course_tree.topic_label")), 1)
          ]),
          _: 1
        }, 8, ["loading", "options", "modelValue"])
      ])
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_TextEditor, {
        modelValue: _ctx.modelValue.body,
        fixedLabel: true,
        onBlur: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onBlur())),
        "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => (_ctx.onNodeChange('body', $event)))
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t("course_tree.announcement_body")), 1)
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    (_ctx.allowChildren)
      ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
          _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.$t("course_tree.announcement_attachments")), 1),
          _createElementVNode("div", _hoisted_12, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.fileChildren, (fileNode) => {
              return (_openBlock(), _createElementBlock("div", {
                key: fileNode.id
              }, [
                _createVNode(_component_FileNode, {
                  onDeleteNode: _cache[6] || (_cache[6] = ($event: any) => (_ctx.$emit('deleteNode', $event))),
                  isDraggable: false,
                  canEdit: true,
                  node: fileNode
                }, null, 8, ["node"])
              ]))
            }), 128)),
            _createVNode(_component_FileUpload, {
              uploadProgress: _ctx.attachmentUploadProgress,
              uploading: _ctx.creatingAttachment,
              modelValue: _ctx.attachmentProxy,
              "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.attachmentProxy) = $event)),
              autoUpload: true,
              clearImmediately: true
            }, null, 8, ["uploadProgress", "uploading", "modelValue"])
          ])
        ]))
      : _createCommentVNode("", true)
  ]))
}