
import { defineComponent, PropType } from "@vue/runtime-core";
import Dialog from "@/components/ui/Dialog.vue";
import {
	EventParticipationSlot,
	Exercise,
	getFakeEventParticipationSlot,
} from "@/models";
import { SelectableOption } from "@/interfaces";
import SegmentedControls from "@/components/ui/SegmentedControls.vue";
import AbstractEventParticipationSlot from "@/components/shared/AbstractEventParticipationSlot.vue";
import { getTranslatedString as _ } from "@/i18n";
import html2pdf from "html2pdf.js";
import Btn from "@/components/ui/Btn.vue";
import { setErrorNotification } from "@/utils";
import { eventIdMixin } from "@/mixins";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";

export default defineComponent({
	name: "EventInstancesPreview",
	mixins: [eventIdMixin],
	components: {
		Dialog,
		SegmentedControls,
		AbstractEventParticipationSlot,
		Btn,
	},
	props: {
		show: {
			type: Boolean,
			required: true,
		},
		instances: {
			type: Array as PropType<Exercise[][]>,
			required: true,
		},
	},
	data() {
		return {
			currentInstance: 0 as number,
			saving: false,
		};
	},
	methods: {
		async onPrint() {
			const element = document.getElementById(
				"printable-preview-" + this.currentInstance,
			);
			const worker = html2pdf();
			this.saving = true;
			setTimeout(async () => {
				try {
					await worker
						.set({
							filename: this.eventName + "_" + this.currentInstance + ".pdf",
							html2canvas: {},
							jsPDF: {
								orientation: "p",
								unit: "mm",
								format: "a3",
								putOnlyUsedFonts: true,
								floatPrecision: 16, // or "smart", default is 16
							},
						})
						.from(element)
						.save();
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.saving = false;
				}
			}, 10);
		},
	},
	computed: {
		...mapStores(useMainStore),
		eventName(): string {
			return this.mainStore.getEventById(this.eventId).name;
		},
		instancesAsSlotArrays(): EventParticipationSlot[][] {
			return this.instances.map(i => i.map(e => getFakeEventParticipationSlot(e)));
		},
		instancesAsSelectableOptions(): SelectableOption[] {
			return this.instances.map((i, j) => ({
				value: j,
				content: _("misc.example") + " " + (j + 1),
			}));
		},
	},
});
