
import { courseIdMixin, loadingMixin } from "@/mixins";
import { defineComponent } from "@vue/runtime-core";

import EventParticipationPreview from "@/components/student/EventParticipationPreview.vue";
import SkeletonCard from "@/components/ui/SkeletonCard.vue";
import { EventType } from "@/models";
import { getTranslatedString as _ } from "@/i18n";
import { MAX_PRACTICE_EXERCISE_COUNT } from "@/const";
import { LoadAction } from "@ts-pro/vue-eternal-loading";
import VueEternalLoading from "@ts-pro/vue-eternal-loading/src/components/VueEternalLoading/VueEternalLoading.vue";
import { EventParticipationSearchFilter } from "@/api";
import Spinner from "@/components/ui/Spinner.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";

export default defineComponent({
	components: {
		EventParticipationPreview,
		SkeletonCard,
		VueEternalLoading,
		Spinner,
	},
	name: "ExamList",
	mixins: [courseIdMixin, loadingMixin],
	async created() {
		await this.withFirstLoading(async () => {
			await this.mainStore.getTags({
				// TODO is this necessary?
				courseId: this.courseId,
				includeExerciseCount: true,
			});
			//await this.mainStore.getCourse({ courseId: this.courseId });
			await this.mainStore.getCourseEventParticipations({
				courseId: this.courseId,
				fromFirstPage: true,
				filter: { event_type: EventType.EXAM } as EventParticipationSearchFilter,
			});
		});
	},
	data() {
		return {
			isEditingRule: false,
			MAX_PRACTICE_EXERCISE_COUNT,
			showNotRecent: false,
			showBookmarkedOnly: false,
			isInitialInfiniteLoad: false,
		};
	},
	methods: {
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			try {
				const moreResults = await this.mainStore.getCourseEventParticipations({
					courseId: this.courseId,
					fromFirstPage: false,
					filter: { event_type: EventType.EXAM } as EventParticipationSearchFilter,
				});
				if (!moreResults) {
					noMore();
				} else {
					loaded();
				}
			} catch {
				error();
			}
		},
	},
	computed: {
		...mapStores(useMainStore),
	},
});
