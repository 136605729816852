
import { defineComponent } from "vue";
import Card from "../Card.vue";

export default defineComponent({
	name: "MinimalExercisePreviewSkeleton",
	components: {
		Card,
	},
});
