import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveComponent as _resolveComponent, createBlock as _createBlock, renderSlot as _renderSlot, createVNode as _createVNode } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = {
  key: 1,
  class: "flex flex-wrap items-center mt-1 mb-2.5"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Tag = _resolveComponent("Tag")!
  const _component_ProcessedTextFragment = _resolveComponent("ProcessedTextFragment")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.showLabel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("h3", null, _toDisplayString(_ctx.exerciseTitle), 1)
        ]))
      : _createCommentVNode("", true),
    (_ctx.showPrivateTags || _ctx.showPublicTags)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.tags, (tag) => {
            return (_openBlock(), _createBlock(_component_Tag, {
              key: 'tag-' + tag.id + '-slot-' + _ctx.exercise.id,
              tag: tag,
              class: "mb-0.5 mr-2"
            }, null, 8, ["tag"]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "exerciseText", {}, () => [
      _createVNode(_component_ProcessedTextFragment, {
        class: "mb-4 user-content",
        value: _ctx.exercise.text
      }, null, 8, ["value"])
    ]),
    (!_ctx.readOnly || !_ctx.$slots.readOnlyAnswer?.())
      ? _renderSlot(_ctx.$slots, "submissionControls", { key: 2 })
      : (_ctx.showReadOnlyAnswer)
        ? _renderSlot(_ctx.$slots, "readOnlyAnswer", { key: 3 })
        : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "extras")
  ]))
}