import { renderSlot as _renderSlot, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = {
  key: 0,
  class: "material-icons-outlined"
}
const _hoisted_3 = {
  key: 1,
  class: "ml-1"
}
const _hoisted_4 = {
  key: 2,
  class: "h-full w-0 opacity-30",
  style: {"border-width":"0.5px","margin-left":"10px","margin-right":"5px"}
}
const _hoisted_5 = {
  key: 3,
  class: "material-icons-outlined -mr-4"
}
const _hoisted_6 = {
  key: 0,
  class: "relative",
  style: {"width":"200%"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_LinearProgress = _resolveComponent("LinearProgress")!
  const _directive_click_outside = _resolveDirective("click-outside")!

  return _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.$slots.activator?.())
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('toggleExpanded')))
        }, [
          _renderSlot(_ctx.$slots, "activator")
        ]))
      : (_openBlock(), _createBlock(_component_Btn, {
          key: 1,
          variant: _ctx.label ? 'primary' : 'icon',
          outline: !_ctx.label,
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('toggleExpanded')))
        }, {
          default: _withCtx(() => [
            _renderSlot(_ctx.$slots, "icon"),
            (!_ctx.$slots.icon?.())
              ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.icon), 1))
              : _createCommentVNode("", true),
            (_ctx.label)
              ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.label), 1))
              : _createCommentVNode("", true),
            (_ctx.trailingIcon)
              ? (_openBlock(), _createElementBlock("div", _hoisted_4))
              : _createCommentVNode("", true),
            (_ctx.trailingIcon)
              ? (_openBlock(), _createElementBlock("span", _hoisted_5, _toDisplayString(_ctx.trailingIcon), 1))
              : _createCommentVNode("", true)
          ]),
          _: 3
        }, 8, ["variant", "outline"])),
    _createElementVNode("div", {
      class: _normalizeClass(["absolute z-50 overflow-y-auto transition-all duration-100 ease-in transform bg-white mt-1.5 card shadow-popup", {
				'opacity-0 scale-95 invisible max-h-97': !_ctx.expanded,
				'max-h-97 opacity-100 scale-100': _ctx.expanded,
				'origin-top-left left-0': _ctx.placement === 'left',
				'origin-top-right right-0': _ctx.placement === 'right',
			}])
    }, [
      (_ctx.loading)
        ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
            _createVNode(_component_LinearProgress, { class: "absolute px-4 -mx-8 -top-4 w-full" })
          ]))
        : _createCommentVNode("", true),
      _renderSlot(_ctx.$slots, "default")
    ], 2)
  ])), [
    [_directive_click_outside, () => (_ctx.expanded ? _ctx.$emit('toggleExpanded') : undefined)]
  ])
}