import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "relative flex flex-col w-full" }
const _hoisted_2 = {
  key: 1,
  class: "",
  style: {"line-height":"1.2"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HelpCenter = _resolveComponent("HelpCenter")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.metaStore.helpCenterOpen)
      ? (_openBlock(), _createBlock(_component_HelpCenter, {
          key: 0,
          onStartTour: _cache[0] || (_cache[0] = ($event: any) => (_ctx.startTour())),
          onClose: _cache[1] || (_cache[1] = ($event: any) => (_ctx.metaStore.setHelpCenterVisibility(false)))
        }))
      : _createCommentVNode("", true),
    (_ctx.routeTitle?.length > 0)
      ? (_openBlock(), _createElementBlock("h1", _hoisted_2, _toDisplayString(_ctx.routeTitle), 1))
      : _createCommentVNode("", true),
    _createVNode(_component_router_view, { class: "" })
  ]))
}