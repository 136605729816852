
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";

export default defineComponent({
	name: "Spinner",
	props: {
		size: {
			type: String as PropType<"sm" | "md" | "lg" | "xl">,
			default: "md",
		},
		variant: {
			type: String as PropType<"primary" | "gray" | "light" | "dark">,
			default: "gray",
		},
		fast: {
			type: Boolean,
			default: false,
		},
	},
	computed: {
		spinnerSize() {
			switch (this.size) {
				case "sm":
					return "12px";
				case "md":
					return "20px";
				case "lg":
					return "24px";
				case "xl":
					return "48px";
				default:
					return "12px";
			}
		},
	},
});
