
import { courseIdMixin, eventIdMixin, loadingMixin, mediaQueryMixin } from "@/mixins";
import {
	Event,
	EventParticipation,
	EventParticipationState,
	EventTemplateRule,
	Exercise,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import { icons as participationStateIcons } from "@/assets/participationStateIcons";

import { Bar } from "vue-chartjs";
import { TChartData } from "vue-chartjs/dist/types";
import {
	Chart as ChartJS,
	Title,
	Tooltip,
	Legend,
	BarElement,
	CategoryScale,
	LinearScale,
} from "chart.js";
import { _DeepPartialObject } from "chart.js/types/utils";
import { getTranslatedString as _ } from "@/i18n";
import Btn from "@/components/ui/Btn.vue";
import Tabs from "@/components/ui/Tabs.vue";
import { SelectableOption } from "@/interfaces";
import ExerciseWithStats from "@/components/teacher/ExerciseWithStats.vue";
import MinimalExercisePreviewSkeleton from "@/components/ui/skeletons/MinimalExercisePreviewSkeleton.vue";

import { DataFrequency } from "@/reports/misc";
import {
	getScoreFrequencyFromParticipations,
	scoreChartOptions,
	scoreChartDatasetSettings,
	areAllParticipationsFullyAssessed,
	getExerciseListFromParticipations,
} from "@/reports/examParticipations";
import { roundToTwoDecimals } from "@/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
ChartJS.register(Title, Tooltip, Legend, BarElement, CategoryScale, LinearScale);

enum ExamStatsTabs {
	OVERALL,
	EXERCISES,
}

export default defineComponent({
	name: "EventStats",
	props: {},
	components: {
		Bar,
		Btn,
		Tabs,
		ExerciseWithStats,
		MinimalExercisePreviewSkeleton,
	},
	mixins: [courseIdMixin, eventIdMixin, loadingMixin, mediaQueryMixin],
	async created() {
		await this.withLoading(async () => {
			// make a first request without the heavy fields in order
			// to quickly show the first chart...
			await this.mainStore.getEventParticipations({
				courseId: this.courseId,
				eventId: this.eventId,
				mutate: true,
				includeDetails: false,
			});
			await this.mainStore.getEvent({
				courseId: this.courseId,
				eventId: this.eventId,
				includeDetails: false, // event template isn't needed
			});
		});
		// ... then make the heavy request, whose data is to be shown
		// in a tab that's not yet visible to the user
		await this.withLocalLoading(
			async () =>
				await this.mainStore.getEventParticipations({
					courseId: this.courseId,
					eventId: this.eventId,
					mutate: true,
					includeDetails: true, // needed to get slots' exercises and answer texts
				}),
		);
	},
	data() {
		return {
			currentTab: ExamStatsTabs.OVERALL,
			ExamStatsTabs,
			scoreChartOptions,
			participationStateIcons,
			EventParticipationState,
		};
	},
	methods: {
		getSlotsContaining(exercise: Exercise) {
			if (exercise) {
				return this.mainStore.eventParticipations
					.flatMap(p => p.slots)
					.filter(s => s.exercise?.id == exercise.id);
			}
			return [];
		},
		areAllParticipationsFullyAssessed,
	},
	computed: {
		...mapStores(useMainStore),
		tabsAsSelectableOptions(): SelectableOption[] {
			return [
				{
					value: ExamStatsTabs.OVERALL,
					content: _("event_stats.overall"),
				},
				{
					value: ExamStatsTabs.EXERCISES,
					content: _("event_stats.exercises"),
				},
			];
		},
		exercises(): Exercise[] {
			return getExerciseListFromParticipations(this.mainStore.eventParticipations);
		},
		scoreFrequency(): DataFrequency<string>[] {
			return getScoreFrequencyFromParticipations(this.mainStore.eventParticipations);
		},
		scoreFrequencyChartData(): TChartData<"bar", number[], unknown> {
			return {
				labels: this.scoreFrequency.map(r => r.datum),
				datasets: [
					{
						data: this.scoreFrequency.map(r => r.frequency),
						...scoreChartDatasetSettings,
					},
				],
			};
		},
		// overall stats
		participantCount() {
			return this.mainStore.eventParticipations.length;
		},
		turnedInCount() {
			return this.mainStore.eventParticipations.filter(
				p => p.state === EventParticipationState.TURNED_IN,
			).length;
		},
		averageProgress() {
			// TODO use method in utils (like in monitor view)
			const participations = this.mainStore.eventParticipations;
			if (!participations.length) {
				return 0;
			}
			const divisor =
				(this.mainStore
					.getEventById(this.eventId)
					.template?.rules?.map(r => r.amount)
					?.reduce((a, b) => a + b, 0) ?? 0) * participations.length;

			const perc =
				(100 *
					participations
						.map(p =>
							p.state === EventParticipationState.TURNED_IN
								? p.slots.length
								: p.current_slot_cursor ?? 0,
						)
						.reduce((a, b) => a + b)) /
				divisor;
			return roundToTwoDecimals(perc);
		},
	},
});
