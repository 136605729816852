
/* eslint-disable */
import Btn from "@/components/ui/Btn.vue";
import { inject, toRefs } from "vue";
import { defineComponent } from "@vue/runtime-core";
import { loadingMixin } from "@/mixins";
import {
	isDemoMode,
	logAnalyticsEvent,
	redirectToMainView,
	setErrorNotification,
} from "@/utils";
import { getTranslatedString as _ } from "@/i18n";
import { demoLoginTourSteps, tourOptions } from "@/const";
import { mapStores } from "pinia";
import { useMetaStore } from "@/stores/metaStore";

const DEMO_TOUR_KEY = "demo_tour_taken";

export default defineComponent({
	name: "Login",
	components: {
		Btn,
	},
	watch: {
		googleOauthReady(newVal) {
			// trigger demo tour
			if (newVal && isDemoMode() && !(DEMO_TOUR_KEY in localStorage)) {
				setTimeout(() => ((this as any).$tours["demoTour"] as any).start(), 50);
				this.disableLogin = true;
			}
		},
	},
	data() {
		return {
			user: "",
			loadingLogin: true,
			disableLogin: false,
			demoLoginTourSteps,
			tourOptions,
			showIcons: false,
			loginAttemptWithUnauthorizedEmailAddress: false,
		};
	},
	mixins: [loadingMixin],
	methods: {
		onTourFinish() {
			this.disableLogin = false;
			localStorage.setItem(DEMO_TOUR_KEY, "true");
		},
		async handleClickSignIn() {
			try {
				this.loginAttemptWithUnauthorizedEmailAddress = false;
				await this.withLocalLoading(
					async () => {
						const googleUser = await this.$gAuth.signIn();
						if (!googleUser) {
							return null;
						}
						this.user = googleUser.getBasicProfile().getEmail();
						const token = googleUser.getAuthResponse().access_token;

						await this.metaStore.convertToken(token);
						await this.metaStore.getUserData();

						redirectToMainView();

						// TODO if you use this.setErroNotification, this is undefined - investigate (hint: it has to do something with the mixin and possibily the store)
						setErrorNotification(_("misc.logged_in"), true);
					},
					// different error handling depending on whether in demo mode
					isDemoMode()
						? this.redirectToDemoPage
						: e => {
								// rethrow error to custom handler
								throw e;
						  },
				);
			} catch (error: any) {
				console.error("sign in error", error);

				const UNAUTHORIZED_EMAIL_DOMAIN_MSG = "Your credentials aren't allowed";
				if (error.response?.data?.error_description === UNAUTHORIZED_EMAIL_DOMAIN_MSG) {
					this.onLoginAttemptWithUnauthorizedEmailAddress();
				} else if (error.error === "popup_closed_by_user") {
					logAnalyticsEvent("popup_closed_by_user", {});
				} else {
					setErrorNotification(error);
				}
				throw error;
			}
		},
		onLoginAttemptWithUnauthorizedEmailAddress() {
			logAnalyticsEvent("unauth_login_attempt", {});
			this.loginAttemptWithUnauthorizedEmailAddress = true;
		},
		redirectToDemoPage() {
			logAnalyticsEvent("loginFailedRedirectToDemo", {});
			const redirectUrl =
				process.env.VUE_APP_DEMO_REDIRECT_URL ?? "http://localhost:8081";
			window.location.href = redirectUrl;
		},
	},
	setup() {
		const Vue3GoogleOauth = inject("Vue3GoogleOauth");
		return {
			Vue3GoogleOauth,
		};
	},
	created() {
		if (this.metaStore.isAuthenticated) {
			redirectToMainView();
		}
	},
	mounted() {
		setTimeout(() => (this.showIcons = true), 10);
	},
	computed: {
		...mapStores(useMetaStore),
		googleOauthReady() {
			return (this as any).Vue3GoogleOauth.isInit;
		},
		googleOauthHadError() {
			return (this as any).Vue3GoogleOauth.hadError;
		},
	},
});
