import { resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SlotSkeleton = _resolveComponent("SlotSkeleton")!
  const _component_CourseTreeNodeEditor = _resolveComponent("CourseTreeNodeEditor")!
  const _component_Dialog = _resolveComponent("Dialog")!

  return (_openBlock(), _createElementBlock("div", null, [
    (!_ctx.firstLoading)
      ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.componentName), _mergeProps({
          key: 0,
          onLoadChildren: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onLoadChildren($event.node, $event.fromFirstPage))),
          onLoadComments: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onLoadComments($event))),
          onEditNode: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onEditNode())),
          onDeleteNode: _cache[3] || (_cache[3] = ($event: any) => (_ctx.onDeleteNode($event))),
          onViewerClose: _cache[4] || (_cache[4] = ($event: any) => (_ctx.onViewerClose())),
          children: _ctx.nodeChildren
        }, _ctx.passDownProps, {
          loadingComments: _ctx.loadingComments,
          loadingChildren: _ctx.loadingChildren,
          node: _ctx.node
        }), null, 16, ["children", "loadingComments", "loadingChildren", "node"]))
      : (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createVNode(_component_SlotSkeleton),
          _createVNode(_component_SlotSkeleton),
          _createVNode(_component_SlotSkeleton)
        ])),
    _createVNode(_component_Dialog, {
      fullHeight: _ctx.fullScreenDialog,
      large: !_ctx.fullScreenDialog,
      fullWidth: _ctx.fullScreenDialog,
      onNo: _cache[9] || (_cache[9] = ($event: any) => (_ctx.onDismissEditor())),
      showDialog: _ctx.editorOpen,
      showActions: false,
      dismissible: false
    }, {
      body: _withCtx(() => [
        _createVNode(_component_CourseTreeNodeEditor, {
          class: "text-darkText",
          saving: _ctx.saving,
          savingError: _ctx.savingError,
          blockingSaving: _ctx.blockingSaving,
          modelValue: _ctx.proxyEditingNode,
          showAutoSaveIndicator: false,
          onPatchNode: _cache[5] || (_cache[5] = ($event: any) => (_ctx.onEditingNodeChange($event.key, $event.value, !!$event.save))),
          onSave: _cache[6] || (_cache[6] = ($event: any) => (_ctx.onSave())),
          onCloseEditor: _cache[7] || (_cache[7] = ($event: any) => (_ctx.onDismissEditor())),
          onDeleteNode: _cache[8] || (_cache[8] = ($event: any) => (_ctx.onDeleteNode($event)))
        }, null, 8, ["saving", "savingError", "blockingSaving", "modelValue"])
      ]),
      _: 1
    }, 8, ["fullHeight", "large", "fullWidth", "showDialog"])
  ]))
}