import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "flex items-center max-w-xs overflow-x-auto text-xs md:max-w-max text-muted" }
const _hoisted_2 = {
  key: 0,
  class: "font-semibold opacity-80 breadcrumb-item"
}
const _hoisted_3 = { class: "" }
const _hoisted_4 = {
  key: 2,
  class: "mx-2 opacity-60"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.breadcrumbs, (breadcrumb, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: 'breadcrumb-' + index,
        class: "flex items-center bg-black bg-opacity-0"
      }, [
        (breadcrumb.routeName === _ctx.$route.name)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(breadcrumb.title), 1))
          : (_openBlock(), _createBlock(_component_router_link, {
              key: 1,
              to: {
					name: breadcrumb.routeName,
					params: breadcrumb.routeParams ?? {},
				},
              class: "link breadcrumb-item"
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_3, _toDisplayString(breadcrumb.title), 1)
              ]),
              _: 2
            }, 1032, ["to"])),
        (index !== _ctx.breadcrumbs.length - 1)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, "/"))
          : _createCommentVNode("", true)
      ]))
    }), 128))
  ]))
}