
import { fileViewerProps } from "./shared";

import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "../ui/Btn.vue";
import { fileViewerMixin } from "@/mixins";
import LinearProgress from "../ui/LinearProgress.vue";
export default defineComponent({
	name: "VideoViewer",
	props: {
		...fileViewerProps,
	},
	mixins: [fileViewerMixin],
	async created() {
		await this.downloadNodeFile();
		this.videoSrc = (window.URL || window.webkitURL).createObjectURL(
			new Blob([this.arrayBufferSource as ArrayBuffer]),
		);
	},
	mounted() {
		const bodyContainsOverflowHidden =
			document.body.classList.contains("overflow-y-hidden");
		if (!bodyContainsOverflowHidden) {
			document.body.classList.add("overflow-y-hidden");
		}
	},
	beforeUnmount() {
		const bodyContainsOverflowHidden =
			document.body.classList.contains("overflow-y-hidden");
		if (bodyContainsOverflowHidden) {
			document.body.classList.remove("overflow-y-hidden");
		}
	},
	data() {
		return {
			videoSrc: null as string | null,
			downloadProgress: undefined as undefined | number,
		};
	},
	methods: {
		onDownloadProgress(e: { loaded: number }) {
			const downloaded = e.loaded;
			this.downloadProgress = (downloaded / this.size) * 100;
			console.log({ e, p: this.downloadProgress });
		},
	},
	components: {
		Btn,
		LinearProgress,
	},
});
