import { vModelCheckbox as _vModelCheckbox, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, renderSlot as _renderSlot, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = ["value", "id"]
const _hoisted_2 = { class: "absolute w-12 h-12 transition-all duration-100 ease-in-out rounded-full bg-opacity-10 bg-primary toggle-shadow -top-4" }
const _hoisted_3 = ["for"]
const _hoisted_4 = ["for"]
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_wave = _resolveDirective("wave")!
  const _directive_wave_trigger = _resolveDirective("wave-trigger")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["flex items-center", {
			'opacity-40 pointer-events-none': _ctx.disabled,
		}])
  }, [
    _withDirectives((_openBlock(), _createElementBlock("div", {
      class: _normalizeClass([{ 'order-12 ml-4': _ctx.labelOnLeft }, "relative inline-block w-10 h-4 mt-1 mr-2 align-middle transition duration-200 ease-in bg-transparent rounded-full select-none toggle-container"])
    }, [
      _withDirectives(_createElementVNode("input", {
        style: {"z-index":"3"},
        type: "checkbox",
        name: "toggle",
        value: _ctx.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.proxyModelValue) = $event)),
        id: _ctx.overrideId || _ctx.id,
        class: _normalizeClass([{
					'bg-white': !_ctx.modelValue,
					'bg-primary': _ctx.modelValue,
				}, "absolute -left-0.5 block transition-all duration-100 rounded-full toggle-handle appearance-none cursor-pointer hover:ring-0 ring-secondary active:ring-opacity-40 ring-opacity-20 toggle-checkbox"])
      }, null, 10, _hoisted_1), [
        [_vModelCheckbox, _ctx.proxyModelValue]
      ]),
      _withDirectives(_createElementVNode("div", _hoisted_2, null, 512), [
        [_directive_wave, { trigger: 'toggleCheckbox', color: '#666ad1' }]
      ]),
      _createElementVNode("label", {
        style: {"z-index":"4"},
        for: _ctx.overrideId || _ctx.id,
        class: _normalizeClass([[_ctx.proxyModelValue ? 'text-light' : 'text-gray-600'], "absolute text-lg font-semibold transform translate-y-1/2 cursor-pointer toggle-mark opacity-70 material-icons-outlined bottom-1/2"])
      }, " ", 10, _hoisted_3),
      _createElementVNode("label", {
        style: {"z-index":"2"},
        for: _ctx.overrideId || _ctx.id,
        class: "relative block overflow-hidden bg-gray-400 rounded-full cursor-pointer toggle-rail toggle-label"
      }, null, 8, _hoisted_4)
    ], 2)), [
      [_directive_wave_trigger, void 0, "toggleCheckbox"]
    ]),
    (_ctx.$slots.default)
      ? (_openBlock(), _createElementBlock("label", {
          key: 0,
          for: _ctx.id
        }, [
          _renderSlot(_ctx.$slots, "default")
        ], 8, _hoisted_5))
      : _createCommentVNode("", true)
  ], 2))
}