
import { courseIdMixin, loadingMixin } from "@/mixins";
import { Exercise as IExercise } from "@/models";
import { defineComponent } from "@vue/runtime-core";
import ExerciseSolutionContainer from "@/components/shared/ExerciseSolution/ExerciseSolutionContainer.vue";
import SlotSkeleton from "@/components/ui/skeletons/SlotSkeleton.vue";
import Spinner from "@/components/ui/Spinner.vue";
import VueEternalLoading from "@ts-pro/vue-eternal-loading/src/components/VueEternalLoading/VueEternalLoading.vue";
import { LoadAction } from "@ts-pro/vue-eternal-loading";
import { getBlankExerciseSearchFilters } from "@/api/utils";
import ExerciseSearchFilters from "@/components/teacher/ExerciseSearchFilters.vue";
import Btn from "@/components/ui/Btn.vue";
import { getDebouncedForFilter, setErrorNotification } from "@/utils";
import FullExercise from "@/components/shared/FullExercise.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
export default defineComponent({
	name: "ExerciseThreadList",
	mixins: [courseIdMixin, loadingMixin],
	props: {},
	watch: {
		searchFilter: {
			async handler() {
				await this.onFilterChange();
			},
			deep: true,
		},
	},
	async created() {
		this.onFilterChange = getDebouncedForFilter(this.onFilterChange);
		this.withFirstLoading(async () => {
			await this.mainStore.getTags({
				courseId: this.courseId,
				includeExerciseCount: false,
			});
			await this.mainStore.getExercises({
				courseId: this.courseId,
				fromFirstPage: true,
				filters: null, //{ by_popularity: true },
			});
			this.fetchSolutionsForNewExercises(true);
		});
	},
	data() {
		return {
			isInitialInfiniteLoad: false,
			loadingSolutionsByExercise: {} as Record<string, boolean>,
			showSolutionsByExercise: {} as Record<string, boolean>,
			searchFilter: getBlankExerciseSearchFilters(),
		};
	},
	methods: {
		getBlankExerciseSearchFilters,
		async onFilterChange() {
			this.isInitialInfiniteLoad = true;
			await this.withLoading(
				async () =>
					await this.mainStore.getExercises({
						courseId: this.courseId,
						fromFirstPage: true,
						filters: {
							by_popularity: true,
							...this.searchFilter,
						},
					}),
			);
			this.fetchSolutionsForNewExercises();
		},
		getSolutionCountForExercise(exercise: IExercise): number {
			return this.mainStore.getPaginatedSolutionsByExerciseId(exercise.id).count;
		},
		async loadMore(exercise: IExercise) {
			await this.withLoading(
				async () =>
					await this.mainStore.getSolutionsByExercise({
						courseId: this.courseId,
						exerciseId: exercise.id,
						fromFirstPage: false,
						filter: null,
					}),
			);
		},
		fetchSolutionsForNewExercises(force = false) {
			this.mainStore.exercises
				.filter(
					e =>
						force ||
						typeof this.mainStore.paginatedSolutionsByExerciseId[e.id] === "undefined",
				)
				.forEach(async e => {
					this.loadingSolutionsByExercise[e.id] = true;
					try {
						await this.mainStore.getSolutionsByExercise({
							courseId: this.courseId,
							exerciseId: e.id,
							fromFirstPage: true,
							filter: null,
						});
					} catch (e) {
						setErrorNotification(e);
					} finally {
						this.loadingSolutionsByExercise[e.id] = false;
					}
				});
		},
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			try {
				const moreResults = await this.mainStore.getExercises({
					courseId: this.courseId,
					fromFirstPage: false,
					filters: { by_popularity: true, ...this.searchFilter },
				});
				if (!moreResults) {
					noMore();
				} else {
					loaded();
				}
				this.fetchSolutionsForNewExercises();
			} catch {
				error();
			}
		},
	},
	computed: {
		...mapStores(useMainStore),
	},
	components: {
		SlotSkeleton,
		//Exercise,
		ExerciseSolutionContainer,
		Spinner,
		VueEternalLoading,
		ExerciseSearchFilters,
		Btn,
		FullExercise,
	},
});
