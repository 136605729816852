
import {
	EventParticipationSlotSubmission,
	Exercise,
	ExerciseChoice,
	ExerciseType,
	getEmptySubmission,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import AbstractExercise from "./AbstractExercise.vue";
import CheckboxGroup from "@/components/ui/CheckboxGroup.vue";
import RadioGroup from "@/components/ui/RadioGroup.vue";
import { SelectableOption } from "@/interfaces";
import { formatExerciseText, stripHtmlFromLaTexBlocks } from "@/utils";
import { exerciseProps } from "./shared";
import { getCorrectChoices } from "./utils";
export default defineComponent({
	name: "MultipleChoiceExercise",
	props: {
		...exerciseProps,
	},
	data() {
		return {
			ExerciseType,
		};
	},
	methods: {},
	computed: {
		selectedChoicesProxy: {
			get() {
				return this.submission.selected_choices;
			},
			set(val: string | string[]) {
				this.$emit("updateSubmission", [
					"selected_choices",
					typeof val === "object" ? val : [val],
				]);
			},
		},
		correctChoices(): string[] {
			return getCorrectChoices(this.exercise);
		},
		exerciseChoicesAsOptions(): SelectableOption[] {
			if (
				this.exercise.exercise_type !== ExerciseType.MULTIPLE_CHOICE_SINGLE_POSSIBLE &&
				this.exercise.exercise_type !== ExerciseType.MULTIPLE_CHOICE_MULTIPLE_POSSIBLE
			) {
				return [];
			}

			return (this.exercise.choices as ExerciseChoice[]).map(c => ({
				value: c.id,
				// TODO extract this sanitizing (it's the same as in ProcessedTextFragment.vue)
				content: this.$sanitize(stripHtmlFromLaTexBlocks(formatExerciseText(c.text))),
				description: this.showSolution
					? this.correctChoices.includes(c.id)
						? "done"
						: "close"
					: "", // TODO instead of putting it in the description, there should be an `extras` parameter
			}));
		},
	},
	components: { AbstractExercise, CheckboxGroup, RadioGroup },
});
