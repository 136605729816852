
import VuePdfEmbed from "vue-pdf-embed";

import { defineComponent } from "@vue/runtime-core";
import Btn from "../ui/Btn.vue";
import LinearProgress from "../ui/LinearProgress.vue";
import { fileViewerProps } from "./shared";
import { fileViewerMixin, mediaQueryMixin } from "@/mixins";
export default defineComponent({
	name: "PdfViewer",
	props: {
		...fileViewerProps,
	},
	components: {
		VuePdfEmbed,
		Btn,
		LinearProgress,
	},
	mixins: [fileViewerMixin, mediaQueryMixin],
	mounted() {
		this.downloadNodeFile();
		const bodyContainsOverflowHidden =
			document.body.classList.contains("overflow-y-hidden");
		if (!bodyContainsOverflowHidden) {
			document.body.classList.add("overflow-y-hidden");
		}
		if (!this.mediaQueryMdMatches) {
			this.width = window.screen.availWidth - 10;
		}
	},
	beforeUnmount() {
		const bodyContainsOverflowHidden =
			document.body.classList.contains("overflow-y-hidden");
		if (bodyContainsOverflowHidden) {
			document.body.classList.remove("overflow-y-hidden");
		}
	},
	data() {
		return {
			//downloading: true,
			isLoading: true,
			reRendering: false,
			page: null,
			pageCount: null as null | number,
			showAllPages: true,
			width: 800,
			//source: "",
		};
	},
	methods: {
		// async downloadNodeFile() {
		// 	const fileBlob = await downloadFileNode(this.url);
		// 	console.log("BLOB", fileBlob);
		// 	this.source = arraybufferToBase64(fileBlob);
		// 	this.downloading = false;
		// },
		onZoom(amount: number) {
			if (this.reRendering) {
				return;
			}
			this.reRendering = true;
			const lastWidth = this.width;
			const MIN_WIDTH = this.mediaQueryMdMatches ? 400 : window.screen.availWidth / 2;
			const MAX_WIDTH = this.mediaQueryMdMatches ? 1800 : window.screen.availWidth * 2;
			if (amount > 0) {
				this.width = Math.min(MAX_WIDTH, this.width + amount);
			} else {
				this.width = Math.max(MIN_WIDTH, this.width + amount);
			}
			if (lastWidth === this.width) {
				// width didn't change - call re-rendering off
				this.reRendering = false;
			}
		},
		onProgress(evt: any) {
			console.log("prog", evt);
		},
		handleDocumentRender() {
			this.isLoading = false;
			this.reRendering = false;
			this.pageCount = (this.$refs as any).pdfRef.pageCount;
		},
	},
	computed: {
		base64Source() {
			return `data:application/pdf;base64,${this.source}`;
		},
	},
});
