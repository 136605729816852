import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["aria-valuenow"]
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      role: "progressbar",
      "aria-valuenow": _ctx.percentage,
      "aria-valuemin": "0",
      "aria-valuemax": "100",
      style: _normalizeStyle(_ctx.style)
    }, [
      (_ctx.displayType === 'fraction')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.value) + " / " + _toDisplayString(_ctx.total), 1))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.percentage), 1))
    ], 12, _hoisted_1)
  ]))
}