import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "w-11/12 h-4 bg-gray-200 rounded"
}
const _hoisted_2 = {
  key: 0,
  class: "w-11/12 h-4 bg-gray-200 rounded"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Card = _resolveComponent("Card")!

  return (_openBlock(), _createBlock(_component_Card, {
    hoverable: false,
    borderLess: _ctx.borderLess,
    class: _normalizeClass([_ctx.full ? 'h-96' : ''])
  }, {
    header: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass([[_ctx.short ? 'h-3' : 'h-4'], "bg-gray-200 rounded animate-pulse"])
      }, null, 2)
    ]),
    body: _withCtx(() => [
      _createElementVNode("div", {
        class: _normalizeClass({ 'pb-8': !_ctx.short })
      }, [
        _createElementVNode("div", {
          class: _normalizeClass([[_ctx.short ? 'h-3' : 'h-4'], "w-11/12 mb-2 bg-gray-200 rounded animate-pulse"])
        }, null, 2),
        _createElementVNode("div", {
          class: _normalizeClass([[_ctx.short ? 'h-3 space-y-2' : 'h-4 space-y-3'], "animate-pulse"])
        }, [
          _createElementVNode("div", {
            class: _normalizeClass(["grid grid-cols-3", [_ctx.short ? 'gap-2' : 'gap-4']])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([[_ctx.short ? 'h-3' : 'h-4'], "col-span-2 bg-gray-200 rounded"])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass([[_ctx.short ? 'h-3' : 'h-4'], "col-span-1 bg-gray-200 rounded"])
            }, null, 2)
          ], 2),
          (!_ctx.short)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true)
        ], 2)
      ], 2),
      (_ctx.full)
        ? (_openBlock(), _createElementBlock("div", {
            key: 0,
            class: _normalizeClass(["mt-12", { 'pb-8': !_ctx.short }])
          }, [
            _createElementVNode("div", {
              class: _normalizeClass([[_ctx.short ? 'h-3' : 'h-4'], "w-11/12 mb-2 bg-gray-200 rounded animate-pulse"])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass([[_ctx.short ? 'h-3 space-y-2' : 'h-4 space-y-3'], "animate-pulse"])
            }, [
              _createElementVNode("div", {
                class: _normalizeClass(["grid grid-cols-3", [_ctx.short ? 'gap-2' : 'gap-4']])
              }, [
                _createElementVNode("div", {
                  class: _normalizeClass([[_ctx.short ? 'h-3' : 'h-4'], "col-span-2 bg-gray-200 rounded"])
                }, null, 2),
                _createElementVNode("div", {
                  class: _normalizeClass([[_ctx.short ? 'h-3' : 'h-4'], "col-span-1 bg-gray-200 rounded"])
                }, null, 2)
              ], 2),
              (!_ctx.short)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2))
                : _createCommentVNode("", true)
            ], 2)
          ], 2))
        : _createCommentVNode("", true),
      (_ctx.full)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass([[_ctx.short ? 'h-3' : 'h-4'], "col-span-2 mt-12 bg-gray-200 rounded"])
          }, null, 2))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }, 8, ["borderLess", "class"]))
}