import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "relative segmented-controls" }
const _hoisted_2 = { class: "absolute top-2 left-3 origin-0 fixed-label" }
const _hoisted_3 = { class: "flex w-full" }
const _hoisted_4 = ["for"]
const _hoisted_5 = ["onInput", "id", "value", "checked", "disabled"]
const _hoisted_6 = { class: "flex mx-auto space-x-2 items-top" }
const _hoisted_7 = {
  key: 1,
  class: "flex flex-col"
}
const _hoisted_8 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_multi_icon = _resolveComponent("multi-icon")!
  const _directive_wave = _resolveDirective("wave")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return _withDirectives((_openBlock(), _createElementBlock("label", {
          onMousedown: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onMouseDown && _ctx.onMouseDown(...args))),
          for: _ctx.id + '-input-' + index,
          class: _normalizeClass(["relative flex flex-grow max-h-screen overflow-hidden overflow-x-hidden overflow-y-hidden font-semibold text-center text-gray-600 rounded-md cursor-pointer min-w-fit overflow-ellipsis whitespace-nowrap items-top", {
					'py-1.5 px-2': !_ctx.small,
					'py-0.5 px-1': _ctx.small,
					'rounded-r-none': index < _ctx.options.length - 1,
					'rounded-l-none': index !== 0,
					'border border-primary bg-primary bg-opacity-20 text-primary ':
						option.value == _ctx.modelValue,
					'border hover:text-gray-800 hover:bg-light': option.value != _ctx.modelValue,
				}]),
          key: _ctx.id + '-option-' + index
        }, [
          _createElementVNode("input", {
            onInput: ($event: any) => (_ctx.onInput(option.value, $event)),
            class: "invisible w-0 h-0",
            type: "radio",
            id: _ctx.id + '-input-' + index,
            value: option.value,
            checked: option.value == _ctx.modelValue,
            disabled: _ctx.disabled
          }, null, 40, _hoisted_5),
          _createElementVNode("div", _hoisted_6, [
            (option.icons)
              ? (_openBlock(), _createBlock(_component_multi_icon, {
                  key: 0,
                  class: "w-6",
                  icons: option.icons
                }, null, 8, ["icons"]))
              : _createCommentVNode("", true),
            (option.content.length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
                  _createElementVNode("p", {
                    class: "",
                    innerHTML: option.content
                  }, null, 8, _hoisted_8)
                ]))
              : _createCommentVNode("", true)
          ])
        ], 42, _hoisted_4)), [
          [_directive_wave]
        ])
      }), 128))
    ])
  ]))
}