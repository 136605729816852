import { normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    innerHTML: _ctx.sanitizedFormattedValue,
    class: _normalizeClass({
			'text-muted font-light': _ctx.useDefault && _ctx.defaultValue.length > 0,
		})
  }, null, 10, _hoisted_1))
}