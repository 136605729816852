
import { CourseSearchFilter } from "@/api/interfaces";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import TextInput from "../ui/TextInput.vue";
import Toggle from "../ui/Toggle.vue";
export default defineComponent({
	name: "CourseSearchFilters",
	props: {
		modelValue: {
			type: Object as PropType<CourseSearchFilter>,
			required: true,
		},
	},
	methods: {
		emitUpdate(key: keyof CourseSearchFilter, value: unknown) {
			this.$emit("update:modelValue", {
				...this.modelValue,
				[key]: value,
			});
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		anyCourseHasPrivileges(): boolean {
			return (
				this.metaStore.user.is_teacher ||
				this.mainStore.courses.some(c => (c.privileges?.length ?? 0) > 0)
			);
		},
	},
	components: { TextInput, Toggle },
});
