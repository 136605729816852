
import { defineComponent, PropType } from "@vue/runtime-core";
import moment from "moment";

export default defineComponent({
	name: "Countdown",
	props: {
		isInitialized: {
			type: Boolean,
			required: true,
		},
		endTimestamp: {
			type: Number,
			required: true,
		},
	},
	watch: {
		isInitialized(newVal) {
			if (newVal) {
				this.startTimer();
			} else {
				this.stopTimer();
			}
		},
	},
	data() {
		return {
			seconds: 0,
			shake: false,
			handle: null as number | null,
			hasShakenHalfTime: false,
			hasShakenLittleTime: false,
			initialSeconds: null as number | null,
		};
	},
	methods: {
		startTimer() {
			this.timerCallback();
			this.initialSeconds = this.seconds;

			this.handle = setInterval(this.timerCallback, 1000);
		},
		timerCallback() {
			const now = moment();
			this.seconds = this.endMoment.diff(now, "seconds");

			if (this.seconds <= 0) {
				this.stopTimer();
				this.$emit("timeUp");
			}
			if (this.littleTimeRemaining) {
				this.shake = true;
			}
		},
		stopTimer() {
			clearInterval(this.handle as number);
			this.handle = null;
			this.initialSeconds = null;
		},
		onShakeEnd() {
			if (!this.hasShakenHalfTime) {
				this.hasShakenHalfTime = true;
			} else if (!this.hasShakenLittleTime) {
				this.hasShakenLittleTime = true;
			}
		},
	},
	computed: {
		formattedTime(): {
			hours: string;
			minutes: string;
			seconds: string;
		} {
			const hours = Math.floor(this.seconds / 3600);
			const minutes = Math.floor((this.seconds - hours * 3600) / 60);
			const seconds = this.seconds - minutes * 60 - hours * 3600;

			return {
				hours: String(hours).padStart(2, "0"),
				minutes: String(minutes).padStart(2, "0"),
				seconds: String(seconds).padStart(2, "0"),
			};
		},
		littleTimeRemaining(): boolean {
			return this.isInitialized && this.seconds < 5 * 60;
		},
		lessThanHalfTimeRemaining(): boolean {
			if (this.initialSeconds === null) {
				return false;
			}
			return this.isInitialized && this.seconds < Math.floor(this.initialSeconds / 2);
		},
		isInitialTimeValid(): boolean {
			return !isNaN(this.initialSeconds ?? 0);
		},
		endMoment() {
			return moment(new Date(this.endTimestamp));
		},
	},
});
