import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileViewer = _resolveComponent("FileViewer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_FileViewer, {
      onViewerClose: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('viewerClose'))),
      filename: _ctx.node.file.name,
      onFileDownload: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDownload())),
      url: _ctx.fileUrl,
      mimeType: _ctx.node.mime_type,
      id: _ctx.node.id + '-viewer',
      downloading: _ctx.downloading,
      size: _ctx.node.file.size
    }, null, 8, ["filename", "url", "mimeType", "id", "downloading", "size"])
  ]))
}