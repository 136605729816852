
import {
	ActionCount,
	GamificationAction,
	Goal,
	GoalLevel,
	GoalProgress,
} from "@/gamification";
import { defineComponent } from "@vue/runtime-core";
import CircularProgress from "../ui/CircularProgress.vue";
import Spinner from "../ui/Spinner.vue";
import GamificationBadge from "../ui/GamificationBadge.vue";
import Tooltip from "../ui/Tooltip.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
export default defineComponent({
	name: "GamificationContextPanel",
	props: {},
	async created() {
		this.mainStore.gamificationContextGoals.forEach(async (g: Goal) => {
			this.loadingProgressByGoalId[g.id] = true;
			await this.mainStore.getGamificationGoalProgress({
				contextId: this.mainStore.gamificationContext?.id ?? "",
				goalId: g.id,
			});
			this.loadingProgressByGoalId[g.id] = false;
		});
	},
	data() {
		return {
			loadingProgressByGoalId: {} as Record<string, boolean>,
		};
	},
	methods: {
		getNextLevel(goal: Goal): GoalLevel | undefined {
			return goal.levels.find(
				l =>
					l.level_value ===
					(this.mainStore.progressByGoalId[goal.id]?.current_level ?? 0) + 1,
			);
		},
		getActionCount(goal: Goal, action: GamificationAction) {
			const progress = this.mainStore.progressByGoalId[goal.id] as
				| GoalProgress
				| undefined;
			if (!progress) {
				return 0;
			}
			return progress.action_counts.find(c => c.action === action)?.amount ?? 0;
		},
		getPercentageRequirementProgress(goal: Goal, requirement: ActionCount): number {
			const count = this.getActionCount(goal, requirement.action);
			return (count / requirement.amount) * 100;
		},
	},
	computed: {
		...mapStores(useMainStore),
		sortedGoals(): Goal[] {
			return this.mainStore.gamificationContextGoals;
		},
	},
	components: { CircularProgress, Spinner, GamificationBadge, Tooltip },
});
