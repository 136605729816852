import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "absolute top-2 left-1.5 origin-0 fixed-label w-full" }
const _hoisted_2 = { class: "flex md:flex-row space-x-1 flex-col md:space-y-0 space-y-2 w-full" }
const _hoisted_3 = {
  style: {"height":"inherit"},
  class: "absolute flex w-full"
}
const _hoisted_4 = {
  key: 0,
  class: "hide-in-thumbnail top-0 ml-auto h-full z-40 right-0 mt-0.5 mr-4"
}
const _hoisted_5 = {
  key: 0,
  class: "code-editor-side-pane flex flex-col w-full md:w-5/12 overflow-hidden"
}
const _hoisted_6 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!
  const _component_MonacoEditor = _resolveComponent("MonacoEditor")!

  return (_openBlock(), _createElementBlock("div", {
    class: "relative floating-label",
    style: _normalizeStyle('height: ' + _ctx.containerHeight + ';')
  }, [
    _createElementVNode("label", _hoisted_1, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass(["relative rounded-sm hide-in-thumbnail", _ctx.$slots.sidePane ? 'md:w-7/12 w-full' : 'w-full'])
      }, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.showRunButton)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                _createVNode(_component_Btn, {
                  disabled: _ctx.running || _ctx.runCoolDown > 0,
                  variant: 'success',
                  onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('run')))
                }, {
                  default: _withCtx(() => [
                    _renderSlot(_ctx.$slots, "runButton")
                  ]),
                  _: 3
                }, 8, ["disabled"])
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isRendered)
          ? (_openBlock(), _createBlock(_component_MonacoEditor, {
              key: 0,
              onEditorDidMount: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onDidMount($event))),
              onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onChange($event))),
              onBlur: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('blur'))),
              value: _ctx.modelValue,
              theme: "vs-dark",
              class: "rounded-md",
              options: _ctx.monacoOptions,
              height: _ctx.baseHeight,
              language: _ctx.language
            }, null, 8, ["value", "options", "height", "language"]))
          : _createCommentVNode("", true),
        _renderSlot(_ctx.$slots, "bottom")
      ], 2),
      (_ctx.$slots.sidePane)
        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
            _createElementVNode("div", {
              id: _ctx.elementId + 'sidePaneTitle'
            }, [
              _renderSlot(_ctx.$slots, "sidePaneTitle")
            ], 8, _hoisted_6),
            _createElementVNode("div", {
              class: "overflow-x-hidden overflow-y-auto rounded-sm rounded-t-none rounded-l-none",
              style: _normalizeStyle('height:' + _ctx.sidePaneContentHeight + 'px')
            }, [
              _renderSlot(_ctx.$slots, "sidePane")
            ], 4)
          ]))
        : _createCommentVNode("", true)
    ])
  ], 4))
}