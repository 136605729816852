
//import Card from "@/components/ui/Card.vue";
import Btn from "@/components/ui/Btn.vue";
import { Course } from "@/models";
import { useMetaStore } from "@/stores/metaStore";
import { getColorFromString } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import CopyToClipboard from "../ui/CopyToClipboard.vue";
import Tooltip from "../ui/Tooltip.vue";
export default defineComponent({
	name: "CourseListItem",
	props: {
		course: {
			type: Object as PropType<Course>,
			required: true,
		},
	},
	data() {
		return {};
	},
	components: {
		//Card,
		Btn,
		Tooltip,
		CopyToClipboard,
	},
	computed: {
		...mapStores(useMetaStore),
		permalink() {
			return (
				window.location.origin +
				this.$router.resolve({
					name: "CourseDispatcher",
					params: { courseId: this.course.id },
				}).fullPath
			);
		},
		canAccessCoursePanel(): boolean {
			return (
				this.course.creator?.id === this.metaStore.user.id ||
				(this.course.privileges?.length ?? 0) > 0
			);
		},
		formattedDescription() {
			const MAX_DESC_LENGTH = 150;
			const description = this.course.description ?? "";
			return (
				description.slice(0, MAX_DESC_LENGTH) +
				(description.length > MAX_DESC_LENGTH ? "..." : "")
			);
		},
		bookmarkColor() {
			return getColorFromString(this.course.name);
			// return [
			// 	"#000066",
			// 	"#310987",
			// 	"#4f24a1",
			// 	"#6b3cbc",
			// 	"#8654d7",
			// 	"#a26df3",
			// 	"#c189fe",
			// 	"#e1a7ff",
			// 	"#ffc5ff",
			// ][this.course.name.length % 9];
		},
	},
	methods: {
		getColorFromString,
	},
});
