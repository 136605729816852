
import { getTranslatedString as _ } from "@/i18n";
import { v4 as uuid4 } from "uuid";

import { Exercise, ExerciseState, ExerciseType } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Tag from "@/components/ui/Tag.vue";
import MultiIcon from "@/components/ui/MultiIcon.vue";
import { icons as exerciseStatesIcons } from "@/assets/exerciseStatesIcons";
import { icons as exerciseTypesIcons } from "@/assets/exerciseTypesIcons";
import Btn from "@/components/ui/Btn.vue";
import Dialog from "@/components/ui/Dialog.vue";
import FullExercise from "@/components/shared/FullExercise.vue";
import { texMixin } from "@/mixins";
import Tooltip from "@/components/ui/Tooltip.vue";
import FadedEdgesScrollableFragment from "@/components/ui/FadedEdgesScrollableFragment.vue";
import { v4 as uuidv4 } from "uuid";
import { logAnalyticsEvent } from "@/utils";
import ProcessedTextFragment from "@/components/ui/ProcessedTextFragment.vue";
export default defineComponent({
	name: "MinimalExercisePreview",
	props: {
		exercise: {
			type: Object as PropType<Exercise>,
			required: true,
		},
		highlighted: {
			type: Boolean,
			default: false,
		},
		selectable: {
			type: Boolean,
			default: true,
		},
		previewable: {
			type: Boolean,
			default: true,
		},
		selectionDisabled: {
			type: Boolean,
			default: false,
		},
		selectButtonTitle: {
			type: String,
			default: "",
		},
		expandable: {
			type: Boolean,
			default: false,
		},
		showTags: {
			type: Boolean,
			default: false,
		},
		hoverable: {
			type: Boolean,
			default: false,
		},
		showEdit: {
			type: Boolean,
			default: false,
		},
		reduced: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [texMixin],
	components: {
		Tag,
		MultiIcon,
		Btn,
		Dialog,
		FullExercise,
		Tooltip,
		FadedEdgesScrollableFragment,
		ProcessedTextFragment,
	},
	created() {
		this.elementId = uuid4();
		this.tagsDivId = uuid4();
		this.triggerTexRender();
	},
	mounted() {
		const setWidthFn = () => {
			this.tagsDivWidth =
				document.getElementById(this.tagsDivId)?.parentElement?.clientWidth ?? 0;
		};
		window.addEventListener("resize", setWidthFn);
		setTimeout(setWidthFn, 10);
	},
	watch: {
		showPreview(newVal) {
			if (newVal) {
				this.triggerTexRender();
			}
		},
	},
	data() {
		return {
			elementId: "",
			showPreview: false,
			ExerciseType,
			tagsDivId: "",
			tagsDivWidth: 0,
		};
	},
	methods: {
		emitEdit() {
			this.$emit("edit");
			logAnalyticsEvent("editExerciseFromPreview", {});
		},
		onSelection() {
			this.$emit("selection");
		},
	},
	computed: {
		tagsDivStyle() {
			return `width: ${this.tagsDivWidth ?? 508}px`;
		},
		previewTitle(): string {
			return (this.exercise?.label ?? "").trim().length > 0
				? (this.exercise.label as string)
				: _("exercise_preview.unnamed_exercise");
		},
		previewText(): string {
			return this.exercise.text;
		},
		exerciseStateIcons() {
			return exerciseStatesIcons[this.exercise.state as ExerciseState];
		},
		exerciseTypeIcons() {
			return exerciseTypesIcons[this.exercise.exercise_type as ExerciseType];
		},
		tags() {
			return [
				...(this.exercise.public_tags ?? []),
				...(this.exercise.private_tags ?? []),
			];
		},
	},
});
