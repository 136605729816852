
import { defineComponent, PropType } from "@vue/runtime-core";
import Spinner from "./Spinner.vue";
import Tooltip from "./Tooltip.vue";

export default defineComponent({
	components: {
		Spinner,
		Tooltip,
	},
	name: "Btn",
	props: {
		outline: {
			type: Boolean,
			default: false,
		},
		variant: {
			type: String as PropType<
				| "primary"
				| "secondary"
				| "danger"
				| "success"
				| "dark"
				| "light"
				| "primary-borderless"
				| "secondary-borderless"
				| "success-borderless"
				| "transparent"
				| "icon"
			>,
			default: "primary",
		},
		size: {
			type: String as PropType<"xs" | "sm" | "base" | "lg">,
			default: "base",
		},
		forceActive: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		tooltip: {
			type: String,
			default: "",
		},
	},
	data() {
		return {};
	},
	computed: {
		rippleClass() {
			switch (this.variant) {
				case "primary-borderless":
					return "ripple-primary";
				case "success-borderless":
					return "ripple-success";
				case "transparent":
					return "ripple-primary";
				case "primary":
					if (this.outline) {
						return "ripple-primary";
					}
					return "ripple-white";
				case "icon":
					return "ripple-icon";
				default:
					return "ripple-white";
			}
		},
	},
});
