
import axios, { AxiosRequestConfig } from "axios";

import { defineComponent, PropType } from "@vue/runtime-core";
import { Course } from "@/models";
import Btn from "@/components/ui/Btn.vue";
import { blockingDialogMixin } from "../../mixins";
import Dialog from "../ui/Dialog.vue";
import NumberInput from "../ui/NumberInput.vue";
import TextEditor from "../ui/TextEditor.vue";
import Chipset from "../ui/Chipset.vue";
import { SelectableOption } from "../../interfaces";
import { mapStores } from "pinia";
import { useMainStore } from "../../stores/mainStore";
export default defineComponent({
	name: "CourseTutoringBanner",
	props: {
		course: {
			type: Object as PropType<Course>,
			required: true,
		},
	},
	mixins: [blockingDialogMixin],
	// show banner after a short delay
	async created() {
		this.show = await this.shouldDisplayBanner();
		this.selectedCourses = [String(this.course.id)];
	},
	watch: {
		confirmed(newVal) {
			if (newVal) {
				setTimeout(() => (this.show = false), 3000);
			}
		},
	},
	data() {
		return {
			show: false,
			requesting: false,
			offering: false,
			confirmed: false,
			selectedCourses: [] as string[],
			hourlyRate: null,
			sessionsPerWeek: null,
			comment: "",
		};
	},
	methods: {
		async onOffer() {
			try {
				this.offering = true;

				const confirmed = await this.getBlockingBinaryDialogChoice();

				const type = confirmed ? "offered_tutor" : "offered_tutor_canceled";

				await this.makeRequest({
					type,
					course: this.course.id,
					houry_rate: this.hourlyRate,
					sessions_per_week: this.sessionsPerWeek,
					selected_courses: this.selectedCourses,
					comment: this.comment,
				});

				this.showBlockingDialog = false;
				if (!confirmed) {
					return;
				}

				this.confirmed = true;

				const confirmedCourses = [
					...this.getConfirmedCourses(),
					String(this.course.id),
					...this.selectedCourses,
				];

				localStorage.setItem(
					"tutoringConfirmedCourses",
					JSON.stringify(confirmedCourses),
				);
			} finally {
				this.offering = false;
			}
		},
		async onConfirm() {
			try {
				this.requesting = true;

				const confirmed = await this.getBlockingBinaryDialogChoice();

				const type = confirmed ? "requested_tutor" : "requested_tutor_canceled";

				await this.makeRequest({
					type,
					course: this.course.id,
					houry_rate: this.hourlyRate,
					sessions_per_week: this.sessionsPerWeek,
					selected_courses: this.selectedCourses,
					comment: this.comment,
				});

				this.showBlockingDialog = false;
				if (!confirmed) {
					return;
				}

				this.confirmed = true;

				const confirmedCourses = [
					...this.getConfirmedCourses(),
					String(this.course.id),
					...this.selectedCourses,
				];

				localStorage.setItem(
					"tutoringConfirmedCourses",
					JSON.stringify(confirmedCourses),
				);
			} finally {
				this.requesting = false;
			}
		},
		async onClose() {
			this.show = false;
			const dismissedCourses = this.getDismissedCourses();
			dismissedCourses.push(String(this.course.id));
			localStorage.setItem("tutoringDismissedCourses", JSON.stringify(dismissedCourses));
		},
		makeRequest(payload) {
			return axios.post("/features/", { feature_id: "tutoring", data: payload });
		},
		getDismissedCourses() {
			try {
				return JSON.parse(localStorage.getItem("tutoringDismissedCourses") || "[]").map(
					i => String(i),
				);
			} catch {
				return [];
			}
		},
		getConfirmedCourses() {
			try {
				return JSON.parse(localStorage.getItem("tutoringConfirmedCourses") || "[]").map(
					i => String(i),
				);
			} catch {
				return [];
			}
		},
		async shouldDisplayBanner() {
			const dismissedCourses = this.getDismissedCourses();
			const confirmedCourses = this.getConfirmedCourses();
			const enabledCourses = await this.getEnabledCourses();
			return (
				!dismissedCourses.includes(String(this.course.id)) &&
				!confirmedCourses.includes(String(this.course.id)) &&
				enabledCourses.includes(String(this.course.id))
			);
		},
		getEnabledCourses() {
			return ["7", "2", "8", "15", "30", "27", "18", "25"];
		},
	},
	computed: {
		...mapStores(useMainStore),
		coursesAsOptions(): SelectableOption[] {
			return [
				...this.mainStore.courses
					.filter(c => this.getEnabledCourses().includes(String(c.id)))
					.map(c => ({
						label: c.name,
						content: c.name,
						value: String(c.id),
					})),
				{
					label: "Altri",
					content: "Altri (specifica nei commenti)",
					value: "other",
				},
			];
		},
	},
	components: { Btn, Dialog, NumberInput, TextEditor, Chipset },
});
