
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "@/components/ui/Btn.vue";
export default defineComponent({
	name: "PageNotFound",
	props: {},
	methods: {},
	computed: {},
	components: { Btn },
});
