
import { ParticipationAssessmentProgress } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import { icons as assessmentStateIcons } from "@/assets/assessmentStateIcons";

export default defineComponent({
	name: "EventParticipationAssessmentStateRenderer",
	props: {
		params: {
			type: Object as PropType<{ value: ParticipationAssessmentProgress }>,
			required: true,
		},
	},
	data() {
		return {
			ParticipationAssessmentProgress,
			assessmentStateIcons,
		};
	},
	methods: {},
	computed: {},
});
