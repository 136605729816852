
import { defineComponent } from "@vue/runtime-core";
import AnimatedIcon from "./AnimatedIcon.vue";

export default defineComponent({
	components: { AnimatedIcon },
	name: "Notification",
	props: {
		icon: {
			type: String,
			default: "",
		},
		text: {
			type: String,
			default: "",
		},
	},
});
