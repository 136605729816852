
import { EventParticipation, EventType, User } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Card from "../ui/Card.vue";
import Btn from "../ui/Btn.vue";
import Timeline from "../ui/Timeline.vue";
import Timestamp from "../ui/Timestamp.vue";
import { getUserCourseParticipations } from "@/api/courses";
import { courseIdMixin, loadingMixin } from "@/mixins";
import SkeletonCard from "../ui/SkeletonCard.vue";
import { setErrorNotification } from "@/utils";
import Avatar from "../ui/Avatar.vue";
export default defineComponent({
	name: "StudentCard",
	mixins: [courseIdMixin, loadingMixin],
	props: {
		user: {
			type: Object as PropType<User>,
			required: true,
		},
	},
	watch: {
		async expanded(newVal) {
			if (newVal && this.participationHistory.length === 0) {
				this.fetching = true;
				try {
					this.participationHistory = await getUserCourseParticipations(
						this.courseId,
						this.user.id,
					);
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.fetching = false;
				}
			}
		},
	},
	data() {
		return {
			fetching: false,
			expanded: false,
			participationHistory: [] as EventParticipation[],
			EventType,
		};
	},
	methods: {},
	computed: {
		processedHistory() {
			return this.participationHistory
				.map(p => ({
					eventType: p.event.event_type,
					timestamp: p.begin_timestamp,
					data: {
						id: p.id,
						name: p.event.name,
						examId: p.event.id,
						class:
							p.event.event_type === EventType.SELF_SERVICE_PRACTICE ? "opacity-50" : "",
					},
				}))
				.sort((a, b) => (new Date(a.timestamp) < new Date(b.timestamp) ? -1 : 1));
		},
	},
	components: { Card, Btn, Timeline, Timestamp, SkeletonCard, Avatar },
});
