
const DESKTOP_SIDEBAR_WIDTH_EXPANDED = "270px";

import { SidebarOption } from "@/navigation/sidebar";
import { defineComponent, PropType } from "@vue/runtime-core";
import Avatar from "./Avatar.vue";
import Btn from "./Btn.vue";

export default defineComponent({
	name: "SideBar",
	props: {
		header: {
			type: String,
			default: "",
		},
		sidebarOptions: {
			type: Array as PropType<SidebarOption[]>,
			required: true,
		},
		showMobileSidebar: {
			type: Boolean,
			default: true,
		},
		collapsed: {
			type: Boolean,
			required: true,
		},
		initializing: {
			type: Boolean,
			required: false,
		},
	},
	setup() {
		// TODO temporary workaround for a werid issue: if use import like this:
		//import { useMainStore } from "./stores/mainStore";
		// the app won't render as useMainStore will be undefined in other components; investigate
		const useMetaStore = require("../../stores/metaStore").useMetaStore;
		const metaStore = useMetaStore();
		return {
			metaStore,
		};
	},
	data() {
		return {
			desktopSidebarWidth: DESKTOP_SIDEBAR_WIDTH_EXPANDED,
			hovering: false,
			hoverHandle: null as null | number,
		};
	},
	methods: {
		onSideBarHover(event: MouseEvent) {
			if (
				this.hoverHandle == null &&
				!(event.target as any).className.split(" ").includes("no-hover-sidebar")
			) {
				this.hoverHandle = setTimeout(() => (this.hovering = true), 300);
			}
		},
		onSideBarLeave() {
			if (this.hoverHandle != null) {
				clearTimeout(this.hoverHandle);
				this.hoverHandle = null;
			}
			this.hovering = false;
		},
		isRouteActive(option: SidebarOption) {
			return (
				option.routeName === this.$route.name ||
				option.children?.includes(this.$route.name as string)
			);
		},
	},

	components: {
		Btn,
		Avatar,
	},
});
