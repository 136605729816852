
import { defineComponent } from "@vue/runtime-core";
import Card from "./Card.vue";
export default defineComponent({
	name: "SkeletonCard",
	components: {
		Card,
	},
	props: {
		short: {
			type: Boolean,
			default: false,
		},
		borderLess: {
			type: Boolean,
			default: false,
		},
		full: {
			type: Boolean,
			default: false,
		},
	},
});
