import { GamificationAction } from "@/gamification";
import {
	ExerciseState,
	ExerciseType,
	EventState,
	ExerciseTestCaseType,
	CourseTreeNodeType,
} from "@/models";
import {
	ROUTE_TITLE_COURSE_NAME_TOKEN,
	ROUTE_TITLE_EVENT_NAME_TOKEN,
} from "@/navigation/const";

export const en = {
	dialog: {
		default_ok_text: "OK",
		default_cancel_text: "Cancel",
	},
	practice_template_editor: {
		begin_practice: "Start practice",
		add_rule: "Aggiungi esercizi",
		choose_exercises_text:
			"Select the tags you're interested in in order to create your practice session.",
		rule_amount_1: "How many exercises with tag",
		rule_amount_2: "do you want to see?",
	},
	headings: {
		course_tree: "Material " + ROUTE_TITLE_COURSE_NAME_TOKEN,
		exercise_solution_threads: "Soluzioni esercizi " + ROUTE_TITLE_COURSE_NAME_TOKEN,
		login: "Login",
		course_title: ROUTE_TITLE_COURSE_NAME_TOKEN,
		new_course: "Nuovo corso",
		exercise_solution_thread_detail: "Discussione esercizio",
		course_dashboard: "Dashboard " + ROUTE_TITLE_COURSE_NAME_TOKEN,
		course_exercises: "Esercizi " + ROUTE_TITLE_COURSE_NAME_TOKEN,
		course_exams: "Esami " + ROUTE_TITLE_COURSE_NAME_TOKEN,
		course_permissions: "Permessi " + ROUTE_TITLE_COURSE_NAME_TOKEN,
		course_insights: "Statistiche " + ROUTE_TITLE_COURSE_NAME_TOKEN,
		course_list: "Courses",
		exam_progress: "Progresso " + ROUTE_TITLE_EVENT_NAME_TOKEN,
		exam_results: "Risultati " + ROUTE_TITLE_EVENT_NAME_TOKEN,
		ongoing_practice: "Practice",
		ongoing_exam: "Esame " + ROUTE_TITLE_EVENT_NAME_TOKEN + " in corso",
		exam_preview: "Anteprima esame",
		review_submission: "Revisione risposte",
		review_assessment: "Correzione risposte",
		practice_summary: "Riepilogo esercitazione",
		student_exercise_solution_thread: "Discussione esercizio",
		student_exercise_solution_threads: "Esercizi popolari",
		student_favorites: "Contenuti salvati",
		student_course_leaderboard: "Leaderboard",
	},
	course_tree: {
		poll_results: "Risultati",
		save_poll_choice: "Conferma",
		poll_choice_not_saved_yet: "Selezione non ancora salvata",
		confirm_delete_poll_choice: "Vuoi eliminare questa scelta?",
		new_poll_choice: "Nuova",
		poll_choice_text: "Testo opzione",
		poll_closed: "Questo sondaggio è chiuso",
		poll_title: "Poll",
		publish_poll: "Pubblica",
		poll_text: "Testo del sondaggio",
		poll_choices: "Opzioni",
		poll_editor_title: "Sondaggio",
		announcement_title: "Annuncio",
		publish_announcement: "Pubblica",
		announcement_body: "Annuncio",
		announcement_attachments: "Allegati",
		announcement_editor_title: "Annuncio",
		node_comments_title: "commenti",
		node_comment_title: "commento",
		cannot_delete_nonempty_topic: "Sezione non vuota",
		delete_node_confirm:
			"Sei sicuro di voler eliminare questo elemento? Quest'azione è irreversibile.",
		topic_label: "Section",
		no_topic: "No section",
		create: "Crea",
		topic_node_name: "Nome sezione",
		new_topic_title: "Nuova sezione",
		save: "Save",
		save_draft: "Save draft",
		create_file_node_title: "New file",
		no_nodes: "Non c'è ancora materiale",
		unnamed_lesson: "Lezione senza titolo",
		back_to_tree: "Torna al materiale",
		new: "New",
		lesson_title: "Title",
		lesson_body: "Contents",
		lesson_editor_title: "Lesson editor",
		draft: "Draft",
		publish_lesson: "Publish",
		lesson_creation_date: "Creata il",
		lesson_attachments: "Attachments",
		add_attachment: "Add attachment",
	},
	course_tree_node_types: {
		[CourseTreeNodeType.FileNode]: "File",
		[CourseTreeNodeType.TopicNode]: "Section",
		[CourseTreeNodeType.LessonNode]: "Lesson",
		[CourseTreeNodeType.PollNode]: "Poll",
		[CourseTreeNodeType.AnnouncementNode]: "Announcement",
	},
	courses: {
		access_as_student: "Access as student",
		course_panel: "Teacher panel",
	},
	course_list: {
		filter_courses: "Search courses",
		courses_i_teach: "Courses I teach",
		hidden_courses: "Courses hidden to students",
	},
	sidebar_labels: {
		course_dashboard_dashboard: "Dashboard",
		course_permissions: "Permissions",
		course_dashboard_material: "Material",
		course_dashboard_exams: "Exams",
		course_dashboard_practices: "Practice",
		course_dashboard_exercises: "Exercises",
		exercise_solution_threads: "Solutions",
		course_insights: "Statistics",
		course_dashboard_back_to_courses: "Back to courses",
		course_list_courses: "Courses",
		course_list_new_course: "New course",
	},
	breadcrumbs: {
		course_list: "Courses",
		course_dashboard: ROUTE_TITLE_COURSE_NAME_TOKEN,
		exam_participation_page: ROUTE_TITLE_EVENT_NAME_TOKEN,
		practice_participation_page: "Practice",
		submission_review: "Review " + ROUTE_TITLE_EVENT_NAME_TOKEN,
		practice_review: "Review practice",
		exam_assessment: "Assessment " + ROUTE_TITLE_EVENT_NAME_TOKEN,
		exercise_solution_thread: "Exercise discussion",
		exercise_solution_threads: "Popular exercises",
		student_favorites: "Saved content",
		student_course_leaderboard: "Leaderboard",
	},
	programming_exercise: {
		open_text_popup: "Open as popup",
		tab_text: "Text",
		tab_testcases: "Test cases",
		tab_editor: "Editor",
		tab_execution_results: "Results",
		run_code: "Run",
		running_code: "Running your code...",
		execution_results: "Execution results",
		testcase: "Test case",
		testcase_stdin: "Standard input",
		testcase_expected_stdout: "Expected output",
		passed: "Passed",
		not_passed: "Failed",
		test_failed_with_error: "Execution aborted with the following error",
		test_failed_stdout: "Your program produced the following output",
		code_errored: "Execution aborted with the following error",
		compilation_errored: "Compilation failed producing the following error",
		no_testcases:
			"This exercise doesn't have any test cases. Try asking your teacher to add some.",
		results_ok_but_no_testcases:
			"Your code ran without producing errors. Unfortunately, this exercise doesn't have any test cases, so that's all we know.",
		internal_error:
			"An internal error occurred while attempting to run your code. Please, report this error to the teachers.",
		code_execution_will_appear_here:
			"The execution results for your code will appear here.",
	},
	event_states: {
		[EventState.DRAFT]: "Draft",
		[EventState.PLANNED]: "Planned",
		[EventState.OPEN]: "In progress",
		[EventState.CLOSED]: "Closed",
		[EventState.RESTRICTED]: "Restricted access",
	},
	event_states_descriptions: {
		[EventState.PLANNED]:
			"It's all set! The exam preview is visible to students, but they won't be able to access it until its begin timestamp.",
		[EventState.DRAFT]: "Exam won't be visible to students until you publish it.",
		[EventState.OPEN]: "",
		[EventState.CLOSED]: "",
	},
	event_editor: {
		name: "Nome",
		state_editor_title: "Publish",
		state: "Stato esame",
		current_state_is: "Exam is currently in state: ",
		state_is: "L'esame è",
		begin_timestamp: "Beings at",
		end_timestamp: "Ends at",
		instructions: "Istruzioni (opzionale)",
		editor_title: "Exam editor",
		flow_rules: "Regole di svolgimento",
		exercises_shown_at_a_time_label: "Numero di esercizi da mostrare per pagina",
		allow_going_back_label:
			"Permetti agli studenti di tornare indietro a un esercizio già visto",
		show_all_exercises_at_once: "Mostra tutti gli esercizi insieme",
		show_one_exercise_at_once: "Mostra solo un esercizio alla volta",
		publish: "Publish",
		revert_to_draft: "Torna a stato di bozza",
		event_planned_help_text: "L'esame verrà aperto agli studenti automaticamente in data",
		editing_open_event_title: "Modifica di un esame in corso",
		editing_open_event_body:
			"Stai modificando un esame già iniziato. Sei sicuro di volerlo modificare?",
		correct_errors_to_publish:
			"Prima di poter pubblicare l'esame, correggi i seguenti errori:",
		cannot_change_timestamp:
			"Non puoi modificare la data e ora di inizio dell'esame una volta pianificato. Per modificare questo campo, metti l'esame in stato di bozza.",
		this_is_the_link_to_the_event:
			"Ecco il link per accedere all'esame. Comunicalo agli studenti che parteciperanno.",
		edit_template_in_progress_warning:
			"Modificare il modello dell'esame quando questo è già iniziato può avere conseguenze imprevedibili. Gli studenti che hanno già iniziato l'esame non saranno interessati dalle modifiche. Procedi solo se sai cosa stai facendo.",
		currently_locked_by: "È in corso una modifica a questo esame da parte di",
		lock_stand_by: "L'editor verrà sbloccato non appena la modifica sarà conclusa.",
		tip_you_used_randomization:
			"Hai utilizzato alcune delle funzionalità di randomizzazione. Verifica che gli esami generati siano corretti.",
		generate_examples: "Genera esempi",
		generating_examples: "Generazione esempi...",
	},
	student_course_dashboard: {
		your_practice_events: "Le tue esercitazioni",
		new_practice_event: "Nuova esercitazione",
		exams_you_participated_in: "Esami a cui hai partecipato",
		review_submission: "Rivedi risposte",
		view_assessment: "Valutazione",
		resume: "Riprendi",
		assessment_available: "Sono stati pubblicati i voti relativi a questo esame",
		new_practice: "New practice session",
		resume_practice: "Resume practice",
		draft_practice: "Bozza esercitazione",
		pending: "In corso",
		practice_summary: "Riepilogo",
		no_public_exercises:
			"Non puoi iniziare un'esercitazione perché i docenti del corso non hanno ancora aggiunto esercizi",
	},
	exercise_preview: {
		unnamed_exercise: "Unnamed exercise",
	},
	exercise_editor: {
		testcases_title: "Test cases",
		testcase_type: "Visibility",
		testcase_text: "Description",
		testcase_stdin: "Standard Input",
		testcase_expected_stdout: "Expected Standard Output",
		max_score: "Max score",
		solutions_title: "Solutions",
		new_solution: "New solution",
		exercise_tags: "Tags",
		exercise_editor_title: "Exercise editor",
		draft_notice: "Draft",
		choices_title: "Choices",
		exercise_label: "Exercise label",
		exercise_text: "Text",
		exercise_solution: "Solution (optional)",
		select_exercise_type: "Select type",
		exercise_type: "Type",
		exercise_state: "Visibility",
		exercise_public_tags: "Public tags",
		exercise_private_tags: "Private tags",
		choice_text: "Text",
		choice_score: "Score",
		choice_correctness: "Score",
		edit_non_draft_title: "Modifica di un esercizio non bozza",
		edit_non_draft_body:
			"Stai per modificare un esercizio non in stato di bozza. Potrebbe essere già presente in un esame o essere già stato visto dagli studenti. Sei sicuro di volerlo modificare?",
		new_choice: "New choice",
		new_testcase: "New test case",
		cannot_publish: "Non puoi ancora pubblicare questo esercizio",
		cannot_publish_body:
			"Per poter rimuovere lo stato di bozza, correggi i seguenti errori:",
		make_public_confirmation_title:
			"Sei sicuro di voler rendere questo esercizio pubblico?",
		make_public_confirmation_body:
			"Se rendi questo esercizio pubblico, tutti gli studenti potranno visualizzarlo in qualsiasi momento. Se vuoi utilizzare questo esercizio in un esame, rendilo privato.",
	},
	filter_results: {
		title: "Search exercises",
		more_filters: "More filters",
	},
	exercise_types: {
		[ExerciseType.MULTIPLE_CHOICE_SINGLE_POSSIBLE]: "Multiple choices, only one correct",
		[ExerciseType.MULTIPLE_CHOICE_MULTIPLE_POSSIBLE]:
			"Multiple choices, more than one correct",
		[ExerciseType.OPEN_ANSWER]: "Open answer",
		[ExerciseType.JS]: "JavaScript Exercise",
		[ExerciseType.COMPLETION]: "Cloze exercise",
		[ExerciseType.ATTACHMENT]: "Attachment answer",
		[ExerciseType.AGGREGATED]: "Composite exercise",
		[ExerciseType.C]: "C Exercise",
	},
	exercise_states: {
		[ExerciseState.PUBLIC]: "Public",
		[ExerciseState.PRIVATE]: "Exams only",
		[ExerciseState.DRAFT]: "Draft",
	},
	misc: {
		updated_on: "Updated on",
		wait: "Wait...",
		file_upload: "Upload file",
		show_preview: "Show preview",
		hide_preview: "Hide preview",
		having_troubles_with_editor: "Having problems with the editor?",
		tags: "Tags",
		example: "Example",
		score: "Score",
		out_of: "out of",
		at: "at",
		edit: "Edit",
		select: "Select",
		preview: "Preview",
	},
	gamification: {
		to_reach_next_level: "To reach next level",
		your_reputation: "Your reputation",
		your_leaderboard_position: "Your leaderboard position",
		your_badges: "Your badges",
		show_panel: "Show details",
		goals: "Goals",
		no_leaderboard: "The leaderboard is empty... for now",
		no_goals: "There are no study goals",
		current_level: "Current level",
		actions: {
			[GamificationAction.SUBMIT_EXERCISE_SOLUTION]: "Propose a solution to an exercise",
			[GamificationAction.EXERCISE_SOLUTION_APPROVED]:
				"Get teacher's approval to a solution you proposed",
			[GamificationAction.TURN_IN_PRACTICE_PARTICIPATION]: "Complete a practice session",
			[GamificationAction.CORRECTLY_ANSWERED_EXERCISE]: "Answer correctly to an exercise",
			[GamificationAction.EXERCISE_SOLUTION_UPVOTED]:
				"Get a thumbs up to a solution you proposed",
			[GamificationAction.SUBMIT_FIRST_EXERCISE_SOLUTION]:
				"Be the first to propose a solution to an exercise",
		},
	},
	help_texts: {
		student_practice_rule_amount: "Quanti esercizi con questo tag vuoi vedere?",
		exercise_editor: {
			score_if_checked:
				"100% means correct answer. You can use negative values to apply a penalty",
			score_if_unchecked: "Punteggio se la risposta non viene selezionata",
			solution:
				"Se pubblichi questo esercizio, quando viene incluso in un'esercitazione creata da uno studente, la soluzione verrà mostrata al termine della stessa.",
			public_tags:
				"Se pubblichi questo esercizio, i tag pubblici potranno essere utilizzati dagli studenti per cercarlo quando compongono un'esercitazione",
			private_tags:
				"Questi tag non verranno mai mostrati agli studenti e possono essere utilizzati per organizzare gli esercizi e aggiungerli agli esami",
		},
		copy_exam_link: "Copy link",
		stats: "Statistiche esame",
		edit_score: "Modifica punteggio",
		edit_overall_grade: "Modifica voto",
		hidden_course: "Corso nascosto agli studenti",
	},
	event_preview: {
		unnamed_event: "Unnamed exam",
		monitor: "Monitora",
		results: "Risultati",
		editor: "Editor",
		close: "Close",
		still_open_for_some: "L'esame è ancora aperto per alcuni studenti.",
		copy_link: "Copia link per gli studenti",
		copied_link: "Copiato link per gli studenti",
	},
	cloud: {
		changes_saved_to_server: "All changes saved to server",
		saving: "Saving...",
		saved: "All changed saved!",
		error: "An error occurred while saving your changes",
	},
	event_template_editor: {
		add_more_rules: "Aggiungi più slot",
		editor_title: "Exam template",
		introduction_text:
			"Create the template for this exam. For each slot, you can decide whether to show the same exercise to all students or to use randomization criteria.",
		add_rule: "Add slot",
		confirm_delete_rule: "Sei sicuro di voler eliminare questo slot?",
		randomize_rule_order: "Randomize order of slots",
		rule_order_randomization_off:
			"Gli slot verranno assegnati agli studenti nell'ordine in cui sono disposti qui",
		rule_order_randomization_on:
			"Gli slot verranno assegnati agli studenti in ordine casuale",
	},
	event_template_rule_editor: {
		reset_slot_settings: "Reset slot",
		exercise_number: "Slot",
		choose_exercise: "Choose exercise",
		populate_slot_plural_title: "Select exercise(s) for slots",
		populate_slot_singular_title: "Select exercise(s) for slot",
		mode_selection_text: "Come vuoi scegliere l'esercizio per questo slot?",
		pick_single_exercise: "Seleziona un esercizio",
		pick_exercise_from_pool: "Pick exercise from a pool",
		pick_exercise_tag_based: "Seleziona esercizio in base ai tag",
		pick_single_exercise_help_text: "Tutti gli studenti vedranno lo stesso esercizio",
		pick_exercise_from_pool_help_text:
			"Each student will be shown an exercise randomly picked from the pool you define",
		pick_exercise_tag_based_help_text:
			"Ogni studente vedrà un esercizio scelto a caso con i tag selezionati",
		one_exercise_from_set_description:
			"Each student will be shown a randomly picked exercise among these:",
		tag_based_description: "Ogni studente vedrà un esercizio a caso che tra i tag ha:",
		same_exercise_for_everyone_description:
			"Tutti gli studenti vedranno questo esercizio:",
		tag_based_introduction:
			"Each student will see one exercise chosen randomly among those that meet the conditions defined here. An exercise is eligible if, for each group of tags defined, it contains at least one tag from that group.",
		tag_based_select_exercises: "Exercises will be eligible only if they have",
		tag_based_at_least_one: "at least one",
		tag_based_among: "among the following tags:",
		tag_based_and: "... and",
		tag_based_add_condition: "Aggiungi condizione",
		eligible_exercises: "Eligible exercises:",
	},
	exercise_states_descriptions: {
		[ExerciseState.PUBLIC]:
			"Will be visible to students and able to be used both in exams and in student practice sessions.",
		[ExerciseState.PRIVATE]:
			"Won't be visible by students, but teachers will be able to use it in exams.",
		[ExerciseState.DRAFT]:
			"Won't be visible to students and teachers won't be able to use it in exams.",
	},
	event_participation_page: {
		exercise: "Exercise",
		of: "of",
		next_exercise: "Avanti",
		previous_exercise: "Indietro",
		skip_exercise: "Salta esercizio",
		turn_in: "Turn in",
		turn_in_dialog_title: "Sei sicuro di voler consegnare?",
		turn_in_dialog_text:
			"Una volta consegnato, non potrai più modificare le tue risposte.",
		turn_in_confirm_button: "Consegna",
		turned_in_text:
			"Fatto! Hai consegnato con successo. Qui sotto puoi rivedere le tue risposte. Quando vuoi, puoi chiudere questa pagina.",
		review_answers: "Revisione risposte",
		next_dialog_title: "Sei sicuro di voler passare al prossimo esercizio?",
		next_dialog_text:
			"Se vai avanti, non potrai più tornare indietro a questo esercizio.",
		next_dialog_confirm_button: "Vai avanti",
		participate: "Partecipa",
		exam_not_yet_begun: "L'esame non è ancora iniziato.",
		exam_is_over: "L'esame è terminato.",
		begin_timestamp: "Began on",
		turn_in_timestamp: "Turned in on",
	},
	testcase_types: {
		[ExerciseTestCaseType.SHOW_CODE_SHOW_TEXT]: "Public",
		[ExerciseTestCaseType.SHOW_TEXT_ONLY]: "Only descript.",
		[ExerciseTestCaseType.HIDDEN]: "Hidden",
	},
	event_assessment: {
		overall_score: "Grade",
		you_overrode_score:
			"You assigned a grade manually." +
			" If you edit the score of an exercise now, the overall grade won't be updated.",
		// " Se vuoi che il voto si aggiorni automaticamente in base ai punteggi degli esercizi, ripristina il voto di default.",
		all_participations_assesses:
			"Tutti gli esami sono stati corretti. Puoi pubblicare i risultati.",
		some_exams_require_manual_assessment:
			"Alcuni esercizi richiedono una correzione manuale. Prima di poter pubblicare i risultati, devi assegnare un punteggio agli esercizi che ancora non lo hanno.",
		exams_awaiting_assessment_are_marked:
			"Per assegnare un punteggio alle risposte non ancora valutate, clicca sull'icona",
		ready_to_publish_1:
			"I risultati sono pronti per essere pubblicati. Seleziona le righe che vuoi pubblicare e clicca su",
		ready_to_publish_2:
			"Le righe evidenziate in verde corrispondono a risultati già pubblicati.",
		all_published:
			"Tutti i risultati sono stati pubblicati e sono ora visibili agli studenti.",
		publish_results: "Pubblica i risultati",
		this_comment_is_private:
			"Questo commento verrà visualizzato dagli insegnanti ma non dallo studente",
		comment_for_student: "Feedback",
		student_will_see_this_comment:
			"Questo commento sarà visibile allo studente quando pubblicherai i risultati",
		this_exercise_requires_manual_assessment: "You have to assign a score manually.",
		some_sub_slots_pending:
			"Il punteggio mostrato è incompleto perché alcuni sotto-esercizi non hanno ancora ricevuto una valutazione.",
		some_slots_pending:
			"The grade shown is incomplete because you haven't yet assigned a score to some exercises.",
		default_score:
			"The grade shown is computed as the sum of the scores of each individual exercise." +
			" If you edit the score for an exercise, the overall grade will be updated automatically." +
			" You can override this grade by setting a value manually.",
		assessment_state: "Stato della correzione",
		confirm_assessment: "Save assessment",
		exercise: "esercizio",
		assess: "Valuta",
		assigned_score: "Score",
		your_assessment: "Assessment",
		text_answer_label: "Risposta dello studente",
		exercise_seen_at: "Visto:",
		exercise_answered_at: "Answered at:",
		viewing_participation_of: "You are viewing the participation to the exam of",
		viewing_practice_of: "Stai visualizzando un'esercitazione di",
		sub_slot_assessment_unavailable_open_full_1: "Apri la",
		sub_slot_assessment_unavailable_open_full_2: "partecipazione completa",
		sub_slot_assessment_unavailable_open_full_3:
			"all'esame per assegnare un punteggio a questo sotto-esercizio.",
		undo_overall_score_edit: "Vuoi ripristinare il voto originale?",
		undo_score_edit: "Vuoi ripristinare il punteggio originale per questo esercizio?",
		overall_score_instructions:
			"You can assign a grade manually to this student. The grade can be" +
			" a number or a string.",
	},
};
