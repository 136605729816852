
import { getTranslatedString as _ } from "@/i18n";
import { defineComponent, PropType } from "@vue/runtime-core";
export default defineComponent({
	name: "Tooltip",
	props: {
		textCode: {
			type: String,
			required: false,
		},
		textValue: {
			type: String,
			default: "",
			required: false,
		},
		placement: {
			type: String as PropType<"right" | "left" | "top" | "bottom">,
			default: "right",
		},
		noArrow: {
			type: Boolean,
			default: false,
		},
		allowHoverOnText: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			show: false,
			showHandle: null as null | number,
		};
	},
	methods: {
		setShow(val: boolean) {
			this.show = val;
		},
		showText() {
			if (!this.showHandle) {
				this.showHandle = setTimeout(() => this.setShow(true), this.noArrow ? 200 : 0);
			}
		},
		hideText() {
			if (this.showHandle) {
				clearTimeout(this.showHandle);
				this.showHandle = null;
			}
			this.setShow(false);
		},
	},
	computed: {
		helpText(): string {
			return this.textValue || _("help_texts." + this.textCode);
		},
	},
});
