
import { courseIdMixin, eventIdMixin } from "@/mixins";
import { EventParticipation } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "../ui/Btn.vue";
export default defineComponent({
	name: "EventParticipationEmailRenderer",
	props: {
		params: {
			type: Object as PropType<{
				data: EventParticipation;
				value: string;
			}>,
			required: true,
		},
	},
	mixins: [eventIdMixin, courseIdMixin],
	methods: {},
	computed: {},
	components: { Btn },
});
