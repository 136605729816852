
import { SelectableOption } from "@/interfaces";
import { defineComponent, PropType } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
import Toggle from "./Toggle.vue";

export default defineComponent({
	components: { Toggle },
	name: "CheckboxGroup",
	//props: ['options', 'modelValue', 'disabled'],
	props: {
		options: {
			type: Object as PropType<SelectableOption[]>,
			required: true,
		},
		modelValue: {
			type: Array as PropType<unknown[]>,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		labelClass: {
			type: String,
			default: "",
		},
		optionClass: {
			type: String,
			default: "",
		},
		useToggles: {
			type: Boolean,
			default: false,
		},
		usesCustomSlot: {
			type: Boolean,
			default: false,
		},
		itemClass: {
			type: String,
			default: "",
		},
	},
	created() {
		this.id = uuid4();
	},
	data() {
		return {
			id: "",
		};
	},
	methods: {
		onToggleUpdate(event: unknown, option: SelectableOption) {
			if (event) {
				// new selection
				this.proxyModelValue = [...this.proxyModelValue, option.value];
			} else {
				// deselection
				this.proxyModelValue = this.proxyModelValue.filter(v => v !== option.value);
			}
		},
	},
	computed: {
		proxyModelValue: {
			get(): unknown[] {
				return this.modelValue;
			},
			set(val: SelectableOption[]) {
				this.$emit("update:modelValue", val);
			},
		},
	},
});
