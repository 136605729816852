
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";

import VueUploadComponent from "vue-upload-component";
import Btn from "./Btn.vue";
import AnimatedIcon from "./AnimatedIcon.vue";
import { loadingMixin } from "@/mixins";
import { v4 as uuid4 } from "uuid";
import { mapStores } from "pinia";
import { useMetaStore } from "@/stores/metaStore";
import LinearProgress from "./LinearProgress.vue";
import {
	getDefaultThumbnail,
	getHumanFileSize,
	getMaxUploadFileSizeBytes,
} from "@/utils";

export default defineComponent({
	name: "FileUpload",
	mixins: [loadingMixin],
	components: {
		VueUploadComponent,
		Btn,
		AnimatedIcon,
		LinearProgress,
	},
	props: {
		small: {
			type: Boolean,
			default: false,
		},
		maxFilesNum: {
			type: Number,
			default: 1,
		},
		modelValue: {
			type: Array as PropType<any[]>,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		autoUpload: {
			type: Boolean,
			default: false,
		},
		uploading: {
			type: Boolean,
			default: false,
		},
		uploadProgress: {
			type: Number,
			required: false,
		},
		clearImmediately: {
			type: Boolean,
			default: false,
		},
		showMaxSize: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		uploading(newVal) {
			if (!newVal && this.clearImmediately) {
				this.files = [];
			}
		},
		// TODO probably useless
		refUploading(newVal) {
			if (!newVal) {
				this.showUpload = false;
			}
		},
		fileTooBig(newVal) {
			this.fileTooBigTextShake = newVal;
		},
	},
	data() {
		return {
			files: [] as any,
			minSize: 0,
			maxSize: 1000000000,
			showUpload: false,
			elementId: uuid4(),
			fileTooBig: false,
			fileTooBigTextShake: false,
		};
	},
	created() {
		this.files = this.modelValue;
	},
	methods: {
		download() {
			this.$emit("download");
		},
		async emitUpload(
			file: { blob: Blob }, //VueUploadItem,
			component: any,
		) {
			const fileBlob = file.blob;
			this.$emit("update:modelValue", fileBlob);

			return component;
		},
		inputFilter(
			newFile: {
				name: string;
				file: Blob;
				error: string;
				type: string;
				size: number;
				blob: Blob;
				thumb: string;
			},
			oldFile: { file: any; blob: any },
			prevent: () => any,
		) {
			this.fileTooBig = false;

			const mimeType = newFile.type;
			const isImage = mimeType.substring(0, 6) === "image/";

			if (newFile.file.size > getMaxUploadFileSizeBytes()) {
				this.$emit("fileTooBig");
				// without this, the watcher for fileTooBig won't detect both changes
				// (the one above to false and this one) and the shake animation on
				// the warning text won't be re-triggered
				this.$nextTick(() => (this.fileTooBig = true));
				return prevent();
			}
			if (
				newFile &&
				newFile.error === "" &&
				newFile.file &&
				(!oldFile || newFile.file !== oldFile.file)
			) {
				newFile.blob = newFile.file;
				newFile.thumb = "";
				if (newFile.blob && isImage) {
					newFile.thumb = URL.createObjectURL(newFile.file);
				} else {
					newFile.thumb = getDefaultThumbnail(mimeType);
				}
			}
			// image size
			if (
				newFile &&
				newFile.error === "" &&
				isImage &&
				newFile.blob &&
				(!oldFile || newFile.blob !== oldFile.blob)
			) {
				newFile.error = "image parsing";
				let img = new Image();
				img.onload = () => {
					(this.$refs as any).upload.update(newFile, {
						error: "",
						height: img.height,
						width: img.width,
					});
				};
				img.onerror = (e: any) => {
					(this.$refs as any).upload.update(newFile, {
						error: "parsing image size",
					});
				};
				img.src = URL.createObjectURL(newFile.file); // newFile.blob;
			}
		},
		// add, update, remove File Event
		inputFile(
			newFile: {
				active: any;
				size: number;
				progress: any;
				error: any;
				success: any;
			},
			oldFile: {
				active: any;
				progress: any;
				error: any;
				success: any;
				response: { id: any };
			},
		) {
			if (newFile && oldFile) {
				this.showUpload = true;
				// update
				if (newFile.active && !oldFile.active) {
					// beforeSend
					// min size
					if (newFile.size >= 0 && this.minSize > 0 && newFile.size < this.minSize) {
						(this.$refs as any).upload.update(newFile, { error: "size" });
					}
				}
				if (newFile.progress !== oldFile.progress) {
					// progress
				}
				if (newFile.error && !oldFile.error) {
					console.log("error uploading file");
					(this.$refs as any).upload.active = false;
				}
				if (newFile.success && !oldFile.success) {
					this.showUpload = false;
				}
			}
			if (!newFile && oldFile) {
				// remove
				if (oldFile.success && oldFile.response?.id) {
					// delete
				}
			}
			// Automatically activate upload
			if (Boolean(newFile) !== Boolean(oldFile) || oldFile.error !== newFile.error) {
				if (!this.autoUpload) {
					this.showUpload = true;
				}

				if (this.autoUpload && !(this.$refs as any).upload.active) {
					(this.$refs as any).upload.active = true;
				}
			}
		},
	},
	computed: {
		...mapStores(useMetaStore),
		refUploading(): boolean {
			return !!(this.$refs as any).upload?.active;
		},
		humanReadableMaxUploadSize() {
			return getHumanFileSize(getMaxUploadFileSizeBytes());
		},
	},
});
