import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createSlots as _createSlots, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "user-content" }
const _hoisted_2 = { class: "mt-4" }
const _hoisted_3 = { class: "ml-2 text-sm text-muted" }
const _hoisted_4 = { class: "w-full px-4 py-2 rounded bg-gray-50" }
const _hoisted_5 = ["innerHTML"]
const _hoisted_6 = { key: 0 }
const _hoisted_7 = { class: "w-full mt-4 whitespace-pre" }
const _hoisted_8 = { class: "ml-2 text-sm text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_v_runtime_template = _resolveComponent("v-runtime-template")!
  const _component_ProcessedTextFragment = _resolveComponent("ProcessedTextFragment")!
  const _component_AbstractExercise = _resolveComponent("AbstractExercise")!

  return (_openBlock(), _createBlock(_component_AbstractExercise, {
    exercise: _ctx.slot.exercise,
    showSolution: _ctx.showScores
  }, _createSlots({
    exerciseText: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_v_runtime_template, {
          onSelectionUpdate: _cache[0] || (_cache[0] = ($event: any) => (_ctx.onSelectionUpdate($event))),
          template: _ctx.textTemplate
        }, null, 8, ["template"])
      ])
    ]),
    _: 2
  }, [
    (_ctx.showScores)
      ? {
          name: "extras",
          fn: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createElementVNode("p", _hoisted_3, _toDisplayString(_ctx.$t("misc.correct_answers")), 1),
              _createElementVNode("div", _hoisted_4, [
                _createElementVNode("ul", null, [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.correctChoices, (choiceId) => {
                    return (_openBlock(), _createElementBlock("li", {
                      key: 'corr-' + choiceId,
                      innerHTML: _ctx.getChoice(choiceId).text
                    }, null, 8, _hoisted_5))
                  }), 128))
                ])
              ])
            ]),
            ((_ctx.exercise.solution ?? '').trim().length > 0)
              ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
                  _createElementVNode("div", _hoisted_7, [
                    _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t("misc.solution")), 1),
                    _createVNode(_component_ProcessedTextFragment, {
                      style: {"white-space":"break-spaces"},
                      class: "w-full px-4 py-2 rounded bg-gray-50",
                      value: _ctx.exercise.solution,
                      defaultValue: _ctx.$t('misc.no_answer')
                    }, null, 8, ["value", "defaultValue"])
                  ])
                ]))
              : _createCommentVNode("", true)
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["exercise", "showSolution"]))
}