
import {
	CodeExecutionResults as ICodeExecutionResults,
	EventParticipationSlot,
	ExerciseTestCase,
	ExerciseTestCaseAttachment,
	ExerciseTestCaseType,
	ExerciseType,
	TestCaseExecutionResults,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import TextEditor from "@/components/ui/TextEditor.vue";
import CodeEditor from "@/components/ui/CodeEditor.vue";
import SegmentedControls from "@/components/ui/SegmentedControls.vue";
import { testcaseTypeOptions } from "@/const";
import Btn from "@/components/ui/Btn.vue";
import Tooltip from "@/components/ui/Tooltip.vue";
import Spinner from "@/components/ui/Spinner.vue";
import CodeExecutionResults from "@/components/shared/CodeExecutionResults.vue";
import FileUpload from "@/components/ui/FileUpload.vue";

export default defineComponent({
	name: "TestCaseEditor",
	props: {
		modelValue: {
			type: Object as PropType<ExerciseTestCase>,
			required: true,
		},
		testCaseType: {
			type: Number as PropType<ExerciseType.JS | ExerciseType.C | ExerciseType.PYTHON>,
			required: true,
		},
		executionResultsSlots: {
			type: Array as PropType<EventParticipationSlot[]>,
		},
		executingSolution: {
			type: Boolean,
			default: false,
		},
		attachments: {
			type: Array as PropType<ExerciseTestCaseAttachment[]>,
			default: () => [],
		},
		// TODO add language prop
	},
	watch: {
		// TODO review, it might be shared among components
		attachments: {
			deep: true,
			handler(newVal) {
				if (newVal.length > 0) {
					this.showAttachmentArea = true;
				}
			},
		},
	},
	components: {
		TextEditor,
		CodeEditor,
		SegmentedControls,
		Btn,
		Tooltip,
		Spinner,
		CodeExecutionResults,
		FileUpload,
	},
	data() {
		return {
			testcaseTypeOptions,
			ExerciseTestCaseType,
			ExerciseType,
			showAttachmentArea: false,
		};
	},
	methods: {
		onUpdate(field: keyof ExerciseTestCase, value: unknown) {
			this.$emit("testCaseUpdate", { field, value });
		},
	},
	computed: {
		attachmentProxy: {
			get() {
				return [];
			},
			set(val: any) {
				this.$emit("createAttachment", val);
			},
		},
		executionResults(): ICodeExecutionResults[] {
			return (this.executionResultsSlots ?? []).map(
				s => s.execution_results ?? { state: "completed", tests: [] },
			);
		},
		nonPassingExecutionResults(): ICodeExecutionResults[] {
			return this.executionResults.filter(e => {
				const testResults = (e.tests ?? []).find(t => t.id == this.modelValue.id);
				return typeof testResults === "undefined" || !testResults.passed;
			});
		},
		testCaseExecutionResults(): (TestCaseExecutionResults | undefined)[] {
			return this.executionResults.map(r =>
				r.tests?.find(t => t.id == this.modelValue.id),
			);
		},
		allTestsPass(): boolean {
			return this.testCaseExecutionResults.every(
				r => typeof r !== "undefined" && r.passed,
			);
		},
	},
});
