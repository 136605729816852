
import { defineComponent } from "@vue/runtime-core";
import { PropType } from "vue";
import { Event, EventState, CoursePrivilege } from "@/models";
import Card from "@/components/ui/Card.vue";
import Timestamp from "@/components/ui/Timestamp.vue";
import { getTranslatedString as _ } from "@/i18n";
import { icons as eventStatesIcons } from "@/assets/eventStateIcons";
import MultiIcon from "@/components/ui/MultiIcon.vue";
import Btn from "@/components/ui/Btn.vue";
import { coursePrivilegeMixin } from "@/mixins";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";

export default defineComponent({
	components: {
		Card,
		Timestamp,
		MultiIcon,
		Btn,
		CopyToClipboard,
	},
	name: "EventEditorPreview",
	mixins: [coursePrivilegeMixin],
	props: {
		event: {
			type: Object as PropType<Event>,
			required: true,
		},
		buttonIconsOnly: {
			type: Boolean,
			default: true,
		},
		allowClose: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			CoursePrivilege,
			EventState,
		};
	},
	computed: {
		previewTitle(): string {
			return (this.event?.name ?? "").trim().length > 0
				? (this.event.name as string)
				: _("event_preview.unnamed_event");
		},
		isDraft(): boolean {
			return this.event.state === EventState.DRAFT;
		},
		eventStateIcons() {
			return eventStatesIcons[this.event.state as EventState];
		},
		hasBegun() {
			return (
				this.event.state === EventState.OPEN || this.event.state === EventState.RESTRICTED
			);
		},
		canReopen() {
			return (
				this.hasEnded &&
				// TODO use absolute time to avoid tz issues
				Math.abs(
					new Date().getTime() - new Date(this.event.begin_timestamp ?? "").getTime(),
				) /
					3600000 <
					24
			);
		},
		hasEnded() {
			return this.event.state === EventState.CLOSED;
		},
		halfClosed() {
			return this.event.state === EventState.RESTRICTED;
		},
		permalink(): string {
			return (
				window.location.origin +
				this.$router.resolve({
					name: "ExamParticipationPreview",
					params: { examId: this.event.id },
				}).fullPath
			);
		},
	},
});
