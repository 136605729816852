
import { defineComponent } from "@vue/runtime-core";
import Btn from "@/components/ui/Btn.vue";
import LinearProgress from "./LinearProgress.vue";
export default defineComponent({
	name: "Dialog",
	props: {
		title: String,
		fullHeight: {
			type: Boolean,
			default: false,
		},
		fullWidth: {
			type: Boolean,
			default: false,
		},
		large: {
			type: Boolean,
			default: false,
		},
		stickyHeader: {
			type: Boolean,
			default: false,
		},
		showDialog: {
			type: Boolean,
			required: true,
		},
		dismissible: {
			type: Boolean,
			default: true,
		},
		severity: {
			type: Number,
			default: 1,
		},
		yesText: {
			type: String,
			default: "",
		},
		noText: {
			type: String,
			default: "",
		},
		error: {
			type: Boolean,
			default: false,
		},
		warning: {
			type: Boolean,
			default: false,
		},
		success: {
			type: Boolean,
			default: false,
		},
		headerBorder: {
			type: Boolean,
			default: false,
		},
		footerBorder: {
			type: Boolean,
			default: false,
		},
		noPadding: {
			type: Boolean,
			default: false,
		},
		showActions: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		confirmOnly: Boolean,
		disableOk: Boolean,
		dialogBoxClasses: {
			type: String,
			default: "",
		},
	},
	components: {
		Btn,
		LinearProgress,
	},
	beforeUnmount() {
		const bodyContainsOverflowHidden =
			document.body.classList.contains("overflow-y-hidden");
		if (bodyContainsOverflowHidden) {
			document.body.classList.remove("overflow-y-hidden");
		}
	},
	watch: {
		showDialog(newVal) {
			// prevent scrolling of the underlying page when dialog is open
			const bodyContainsOverflowHidden =
				document.body.classList.contains("overflow-y-hidden");

			if (newVal && !bodyContainsOverflowHidden) {
				document.body.classList.add("overflow-y-hidden");
			} else if (!newVal && bodyContainsOverflowHidden) {
				document.body.classList.remove("overflow-y-hidden");
			}
		},
	},
	created() {
		setTimeout(() => (this.showContent = true), 0);
	},
	data() {
		return {
			choice: "",
			showContent: false,
		};
	},
	methods: {
		emitChoice(choice: string) {
			this.$emit(choice);
		},
		dismiss() {
			if (!this.dismissible) {
				return;
			}
			this.emitChoice(this.confirmOnly ? "yes" : "no");
		},
	},
});
