import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "flex items-start space-x-2 mb-3" }
const _hoisted_2 = { class: "flex flex-col space-y-3 items-center" }
const _hoisted_3 = { class: "mb-4" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.steps, (step, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: _ctx.id + '-step-' + index
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("div", {
              class: _normalizeClass(["w-4 h-4 rounded-full bg-primary", step.data?.class ?? ''])
            }, null, 2),
            _createElementVNode("div", {
              class: _normalizeClass([
							index < _ctx.steps.length - 1 ? 'h-10' : '',
							'border-l-1.5 border-gray-200',
						])
            }, null, 2)
          ]),
          _createElementVNode("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "default", {
              timestamp: step.timestamp,
              eventType: step.eventType,
              data: step.data
            })
          ])
        ])
      ]))
    }), 128))
  ]))
}