
import { v4 as uuid4 } from "uuid";
import Btn from "@/components/ui/Btn.vue";
import { defineComponent, inject, PropType } from "@vue/runtime-core";
import EventTemplateRuleEditor from "./EventTemplateRuleEditor.vue";
import {
	EventTemplate,
	EventTemplateRule,
	EventTemplateRuleClause,
	EventTemplateRuleType,
	getBlankEventTemplateRule,
	getBlankTagBasedEventTemplateRuleClause,
} from "@/models";
import { courseIdMixin, loadingMixin, mediaQueryMixin } from "@/mixins";

import draggable from "vuedraggable";

import { AutoSaveManager } from "@/autoSave";

import { getTranslatedString as _ } from "@/i18n";
import DropdownMenu from "@/components/ui/DropdownMenu.vue";
import NumberInput from "@/components/ui/NumberInput.vue";
import ArticleHandle from "@/components/shared/HelpCenter/ArticleHandle.vue";
import SegmentedControls from "@/components/ui/SegmentedControls.vue";
import { SelectableOption } from "@/interfaces";
import { logAnalyticsEvent, setErrorNotification } from "@/utils";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
export default defineComponent({
	setup() {
		return {
			v$: inject("v$"),
			//  v$: useVuelidate()
		};
	},
	// validations() {
	//   return {
	//     modelValue: eventTemplateValidation,
	//   };
	// },
	components: {
		Btn,
		EventTemplateRuleEditor,
		draggable,
		DropdownMenu,
		NumberInput,
		ArticleHandle,
		SegmentedControls,
	},
	mixins: [courseIdMixin, loadingMixin, mediaQueryMixin],
	name: "EventTemplateEditor",
	props: {
		modelValue: {
			type: Object as PropType<EventTemplate>,
			required: true,
		},
		showEditWarning: {
			type: Boolean,
			default: false,
		},
		randomRuleOrder: {
			type: Boolean,
			required: true,
		},
	},
	created() {
		this.modelValue.rules.forEach(r => {
			this.instantiateRuleAutoSaveManager(r);
			r.clauses?.forEach(c => this.instantiateRuleClauseAutoSaveManager(r.id, c));
		});
	},
	watch: {
		viewMode(newVal) {
			logAnalyticsEvent("changeEventEditorViewMode", {
				courseId: this.courseId,
				to: newVal,
				rules: (this.modelValue.rules ?? []).length,
			});
		},
	},
	data() {
		return {
			elementId: uuid4(),
			rulesAutoSaveInstances: {} as Record<string, AutoSaveManager<EventTemplateRule>>,
			ruleClausesAutoSaveInstances: {} as Record<
				string,
				AutoSaveManager<EventTemplateRuleClause>
			>,
			addRuleAmount: 2,
			addMultipleRulesExpanded: false,
			viewMode: "list" as "grid" | "list" | "compact_list",
		};
	},
	methods: {
		instantiateRuleAutoSaveManager(rule: EventTemplateRule) {
			this.rulesAutoSaveInstances[rule.id] = new AutoSaveManager<EventTemplateRule>(
				rule,
				async changes =>
					await this.mainStore.partialUpdateEventTemplateRule({
						changes,
						ruleId: rule.id,
						templateId: this.modelValue.id,
						courseId: this.courseId,
						// re-fetch rules if ordering changed
						reFetch: typeof changes._ordering !== "undefined",
					}),
				changes =>
					this.mainStore.patchEventTemplateRule({
						payload: changes,
						ruleId: rule.id,
						templateId: this.modelValue.id,
					}),
				[],
				0,
			);
		},
		getActualSlotOrdering(index: number) {
			// returns the amount of exercises that precede the first one
			// targeted by the rule at given index, i.e. the sum of all the values
			// of `amount` of the rules that come before the one at given index
			return this.modelValue.rules
				.filter((_, i) => i < index)
				.map(r => r.amount)
				.reduce((a, b) => a + b, 0);
			//return this.modelValue.rules[index]._ordering ?? 0;
		},
		instantiateRuleClauseAutoSaveManager(
			ruleId: string,
			clause: EventTemplateRuleClause,
		) {
			this.ruleClausesAutoSaveInstances[clause.id] =
				new AutoSaveManager<EventTemplateRuleClause>(
					clause,
					async changes =>
						await this.mainStore.updateEventTemplateRuleClause({
							courseId: this.courseId,
							templateId: this.modelValue.id,
							ruleId,
							clause: { ...clause, ...changes },
						}),
					changes =>
						this.mainStore.patchEventTemplateRuleClause({
							ruleId,
							templateId: this.modelValue.id,
							clauseId: clause.id,
							payload: changes,
						}),
					[],
					0,
					undefined,
					setErrorNotification,
					undefined,
					true,
					false,
				);
		},

		async onRuleDragEnd(event: { oldIndex: number; newIndex: number }) {
			logAnalyticsEvent("draggedRule", { courseId: this.courseId });
			if (event.oldIndex !== event.newIndex) {
				const draggedRule = this.modelValue.rules[event.oldIndex];
				await this.onRuleUpdate(draggedRule, "_ordering", event.newIndex);
			}
		},
		async onAddRule(amount = 1) {
			await this.withLocalLoading(async () => {
				const newRule = await this.mainStore.createEventTemplateRule({
					courseId: this.courseId,
					templateId: this.modelValue.id,
					rule: getBlankEventTemplateRule(
						amount === 1 ? undefined : EventTemplateRuleType.TAG_BASED,
						amount,
					),
				});
				this.instantiateRuleAutoSaveManager(newRule);
			});
			if (amount > 1) {
				// multiple slots were added through the dropdown menu
				// restore data to defaults
				this.addMultipleRulesExpanded = false;
				this.addRuleAmount = 2;
			}
		},
		async onRuleUpdate<K extends keyof EventTemplateRule>(
			rule: EventTemplateRule,
			field: K,
			value: EventTemplateRule[K],
			reFetch = false,
		) {
			this.$emit("saving");
			try {
				await this.rulesAutoSaveInstances[rule.id].onChange({ [field]: value });
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.$emit("doneSaving");
			}
		},
		async onRuleAddClause(rule: EventTemplateRule) {
			await this.withLocalLoading(async () => {
				const newClause = await this.mainStore.createEventTemplateRuleClause({
					courseId: this.courseId,
					templateId: this.modelValue.id,
					ruleId: rule.id,
					clause: getBlankTagBasedEventTemplateRuleClause(),
				});
				this.instantiateRuleClauseAutoSaveManager(rule.id, newClause);

				// reload rule to change "satisfying" preview
				await this.withLocalLoading(
					async () =>
						await this.mainStore.getEventTemplateRule({
							courseId: this.courseId,
							templateId: this.modelValue.id,
							ruleId: rule.id,
						}),
				);
			});
		},
		async onRuleDelete(rule: EventTemplateRule) {
			if (confirm(_("event_template_editor.confirm_delete_rule"))) {
				await this.withLocalLoading(async () =>
					this.mainStore.deleteEventTemplateRule({
						courseId: this.courseId,
						templateId: this.modelValue.id,
						ruleId: rule.id,
					}),
				);
			}
		},
		async onRuleUpdateClause(rule: EventTemplateRule, clause: EventTemplateRuleClause) {
			await this.ruleClausesAutoSaveInstances[clause.id].onChange({
				tags: clause.tags,
			});

			// reload rule to update "satisfying" preview
			await this.withLocalLoading(
				async () =>
					await this.mainStore.getEventTemplateRule({
						courseId: this.courseId,
						templateId: this.modelValue.id,
						ruleId: rule.id,
					}),
			);
		},
		async onRuleDeleteClause(rule: EventTemplateRule, clauseId: string) {
			if (confirm(_("event_template_editor.confirm_delete_rule_clause"))) {
				await this.mainStore.deleteEventTemplateRuleClause({
					courseId: this.courseId,
					templateId: this.modelValue.id,
					ruleId: rule.id,
					clauseId,
				});

				// reload rule to update "satisfying" preview
				await this.withLocalLoading(
					async () =>
						await this.mainStore.getEventTemplateRule({
							courseId: this.courseId,
							templateId: this.modelValue.id,
							ruleId: rule.id,
						}),
				);
			}
		},
	},
	computed: {
		...mapStores(useMainStore),
		selectedExercises(): string[] {
			return this.modelValue.rules
				.filter(r => r.rule_type == EventTemplateRuleType.ID_BASED)
				.map(r => r.exercises as string[])
				.flat();
		},
		viewModesAsOptions(): SelectableOption[] {
			return [
				{
					value: "list",
					icons: ["view_list"],
					content: "",
				},
				{
					value: "grid",
					icons: ["view_module"],
					content: "",
				},
			];
		},
	},
});
