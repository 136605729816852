import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, renderSlot as _renderSlot, createElementBlock as _createElementBlock, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = ["tabindex"]
const _hoisted_2 = { class: "flex flex-col flex-grow w-full" }
const _hoisted_3 = {
  key: 0,
  class: ""
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinearProgress = _resolveComponent("LinearProgress")!

  return (_openBlock(), _createElementBlock("div", {
    tabindex: _ctx.focusable ? 0 : false,
    class: _normalizeClass(["flex card", {
			'card-border': !_ctx.highlighted && !_ctx.borderLess,
			'border-2': _ctx.highlighted,
			'card-hoverable hover:border-transparent hover-shadow-elevation': _ctx.hoverable,
			'card-filled': _ctx.filled,
		}])
  }, [
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        (_ctx.loading)
          ? (_openBlock(), _createBlock(_component_LinearProgress, {
              key: 0,
              class: "absolute top-0 left-0 rounded-t"
            }))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      (_ctx.$slots.header)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _renderSlot(_ctx.$slots, "header")
          ]))
        : _createCommentVNode("", true),
      (_ctx.$slots.body)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(["flex-grow", {
					'mt-3': true || (_ctx.size == 'default' && _ctx.$slots.header),
					'mt-1.5': false && _ctx.size == 'sm',
				}])
          }, [
            _renderSlot(_ctx.$slots, "body")
          ], 2))
        : _createCommentVNode("", true)
    ]),
    (_ctx.$slots.side)
      ? (_openBlock(), _createElementBlock("div", {
          key: 0,
          class: _normalizeClass(["h-full", {
				'w-40': _ctx.size == 'default' && !_ctx.expandSideOnSmall,
				'md:w-10 w-8': _ctx.size == 'sm' && !_ctx.expandSideOnSmall,
				'flex-grow md:w-52': _ctx.expandSideOnSmall,
			}])
        }, [
          _renderSlot(_ctx.$slots, "side")
        ], 2))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}