
import { getTranslatedString as _ } from "@/i18n";
import { EventParticipationSlotAssessment } from "@/models";
import { tupleExpression } from "@babel/types";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "../ui/Btn.vue";
import NumberInput from "../ui/NumberInput.vue";
import TextEditor from "../ui/TextEditor.vue";
export default defineComponent({
	name: "ParticipationSlotAssessment",
	emits: {
		toggleExpanded(value: boolean) {
			return true;
		},
		updateAssessment(payload: [keyof EventParticipationSlotAssessment, any]) {
			return true;
		},
		save(changes: { score: number | null; comment: string }) {
			return true;
		},
	},
	props: {
		assessment: {
			type: Object as PropType<EventParticipationSlotAssessment>,
			required: true,
		},
		readOnly: {
			type: Boolean,
			required: true,
		},
		maxScore: {
			type: Number,
		},
		expanded: {
			type: Boolean,
			default: false,
		},
		writeOnly: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		isSubSlot: {
			type: Boolean,
			default: false,
		},
	},
	watch: {
		assessment: {
			immediate: true,
			deep: true,
			handler(newVal: EventParticipationSlotAssessment) {
				this.dirtyComment = newVal.comment ?? "";
				this.dirtyScore = newVal.score ?? null;
			},
		},
	},
	data() {
		return {
			dirtyScore: null as null | number,
			dirtyComment: null as null | string,
		};
	},
	methods: {
		onShowAssessmentControls() {
			this.$emit("toggleExpanded", true);
		},
		onSave() {
			this.$emit("save", {
				comment: this.dirtyComment ?? "",
				score: this.dirtyScore,
			});
		},
		onHideAssessmentControls() {
			this.$emit("toggleExpanded", false);
		},
		onUndoScoreEdit() {
			if (confirm(_("event_assessment.undo_score_edit"))) {
				this.scoreProxy = null;
				this.onSave();
				this.onHideAssessmentControls();
			}
		},
	},
	computed: {
		scoreProxy: {
			get(): number | null {
				return this.dirtyScore ?? 0;
			},
			set(val: any) {
				this.dirtyScore = val;
				this.$emit("updateAssessment", ["score", val]);
			},
		},
		commentProxy: {
			get() {
				return this.dirtyComment ?? "";
			},
			set(val: string) {
				this.dirtyComment = val;
				this.$emit("updateAssessment", ["comment", val]);
			},
		},
	},
	components: { Btn, NumberInput, TextEditor },
});
