
import { defineComponent } from "@vue/runtime-core";
import LinearProgress from "./LinearProgress.vue";

export default defineComponent({
	name: "TextInput",
	props: {
		modelValue: {
			type: String,
			required: true,
		},
		rightIcon: {
			type: String,
			default: "",
		},
		maxLength: {
			type: Number,
			default: -1,
		},
		leftIcon: {
			type: String,
			default: "",
		},
		placeholder: {
			type: String,
			default: " ",
		},
		fixedLabel: {
			type: Boolean,
			default: false,
		},
		searchBar: {
			type: Boolean,
			default: false,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},
	components: { LinearProgress },
});
