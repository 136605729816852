
import EventEditorPreview from "@/components/teacher/EventEditor/EventEditorPreview.vue";
import { Event, EventState, CoursePrivilege, getBlankExam, EventType } from "@/models";
import Btn from "@/components/ui/Btn.vue";

import { defineComponent } from "@vue/runtime-core";
import {
	blockingDialogMixin,
	courseIdMixin,
	coursePrivilegeMixin,
	loadingMixin,
} from "@/mixins";
import Dialog from "@/components/ui/Dialog.vue";

import EventEditorPreviewSkeleton from "@/components/ui/skeletons/EventEditorPreviewSkeleton.vue";
import { EventSearchFilter } from "@/api/interfaces";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { DialogData } from "@/interfaces";
import { getTranslatedString as _ } from "@/i18n";
import { useMetaStore } from "@/stores/metaStore";
import { setErrorNotification } from "@/utils";

export default defineComponent({
	components: {
		EventEditorPreview,
		Btn,
		Dialog,
		EventEditorPreviewSkeleton,
	},
	name: "CourseExams",
	mixins: [courseIdMixin, loadingMixin, coursePrivilegeMixin, blockingDialogMixin],
	async created() {
		await this.withFirstLoading(
			async () =>
				await this.mainStore.getEvents({
					courseId: this.courseId,
					filters: {
						event_type: EventType.EXAM,
					} as EventSearchFilter,
				}),
		);
	},

	data() {
		return {
			CoursePrivilege,
			buttonLoading: false,
		};
	},
	methods: {
		async onCloseExam(event: Event) {
			this.blockingDialogData = {
				title: _("course_events.close_exam_for_everyone_title"),
				text:
					_("course_events.close_exam_for_everyone_body_1") +
					" " +
					event.name +
					" " +
					_("course_events.close_exam_for_everyone_body_2") +
					" " +
					_("course_events.close_exam_for_everyone_body_3") +
					" " +
					_("event_preview.monitor") +
					".",
				yesText: _("course_events.close_for_everyone"),
				noText: _("dialog.default_cancel_text"),
			};

			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			await this.withLoading(
				async () =>
					await this.mainStore.partialUpdateEvent({
						courseId: this.courseId,
						eventId: event.id,
						mutate: true,
						changes: {
							state: EventState.CLOSED,
							users_allowed_past_closure: [],
						},
					}),
				setErrorNotification,
				() => this.metaStore.showSuccessFeedback(),
			);
			this.showBlockingDialog = false;
		},
		async onReopenExam(event: Event) {
			this.blockingDialogData = {
				title: _("course_events.reopen_exam_title"),
				text: _("course_events.reopen_exam_body") + " " + event.name + "?",
				yesText: _("course_events.reopen_for_everyone"),
				noText: _("dialog.default_cancel_text"),
			};

			const choice = await this.getBlockingBinaryDialogChoice();

			if (!choice) {
				this.showBlockingDialog = false;
				return;
			}

			await this.withLoading(
				async () =>
					await this.mainStore.partialUpdateEvent({
						courseId: this.courseId,
						eventId: event.id,
						mutate: true,
						changes: {
							state: EventState.OPEN,
							users_allowed_past_closure: [],
						},
					}),
				setErrorNotification,
				() => this.metaStore.showSuccessFeedback(),
			);
			this.showBlockingDialog = false;
		},
		async onAddExam() {
			await this.withLocalLoading(async () => {
				const newExam = await this.mainStore.createEvent({
					courseId: this.courseId,
					event: getBlankExam(),
				});
				// redirect to exam editor for newly created exam
				this.$router.push({
					name: "ExamEditor",
					params: { examId: newExam.id },
				});
			});
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
	},
});
