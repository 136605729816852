
import { logAnalyticsEvent } from "@/utils";
import { defineComponent } from "@vue/runtime-core";
import Btn from "./Btn.vue";

export default defineComponent({
	components: { Btn },
	name: "CopyToClipboard",
	props: {
		value: {
			type: String,
			required: true,
		},
		iconOnly: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: "",
		},
		confirmationMessage: {
			type: String,
			default: "",
		},
		tooltip: {
			type: String,
			default: "",
		},
	},
	data() {
		return {
			showFeedback: false,
		};
	},
	methods: {
		onCopy() {
			this.showFeedback = true;
			setTimeout(() => (this.showFeedback = false), 2000);
			logAnalyticsEvent("copyToClipboard", { value: this.value });
		},
	},
});
