
import { fileViewerMixin } from "@/mixins";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "../ui/Btn.vue";
import CodeFragment from "../ui/CodeFragment.vue";
import { fileViewerProps } from "./shared";
export default defineComponent({
	name: "SourceCodeViewer",
	props: {
		...fileViewerProps,
	},
	mixins: [fileViewerMixin],
	mounted() {
		this.downloadNodeFile();
	},
	methods: {},
	computed: {
		sourceAsText() {
			// source is in base64; convert back to string
			return atob(this.source);
		},
	},
	components: { CodeFragment, Btn },
});
