
import { tagNamesToTags } from "@/api/utils";
import TagInput from "@/components/ui/TagInput.vue";
import { courseIdMixin, eventIdMixin, loadingMixin } from "@/mixins";
import { EventTemplateRuleClause, Exercise, Tag } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";

import MinimalExercisePreview from "../ExerciseEditor/MinimalExercisePreview.vue";
import Spinner from "@/components/ui/Spinner.vue";
import Btn from "@/components/ui/Btn.vue";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";

export default defineComponent({
	components: { TagInput, MinimalExercisePreview, Spinner, Btn },
	name: "TagBasedEventTemplateRuleEditor",
	async created() {
		if (this.modelValue.length === 0) {
			this.onAddClause();
		}
	},
	props: {
		modelValue: {
			type: Array as PropType<EventTemplateRuleClause[]>,
			required: true,
		},
		satisfying: {
			type: Object as PropType<{ count: number; example?: Exercise }>,
			default: () => ({
				count: 0,
			}),
		},
		showTeacherIntroductionText: {
			type: Boolean,
			default: true,
		},
		allowCreateMoreClauses: {
			type: Boolean,
			default: true,
		},
		showPreview: {
			type: Boolean,
			default: true,
		},
	},
	mixins: [courseIdMixin, eventIdMixin, loadingMixin],
	methods: {
		onAddClause() {
			this.$emit("addClause");
		},
		onAddTag(clause: EventTemplateRuleClause, newTagName: string) {
			const newTag = tagNamesToTags([newTagName])[0];
			this.$emit("updateClause", {
				...clause,
				tags: [...clause.tags, newTag],
			} as EventTemplateRuleClause);
		},
		onRemoveTag(clause: EventTemplateRuleClause, removedTagName: string) {
			const removedTag = tagNamesToTags([removedTagName])[0];
			this.$emit("updateClause", {
				...clause,
				tags: clause.tags.filter(t => t.id != removedTag.id),
			} as EventTemplateRuleClause);
		},
	},
	computed: {
		...mapStores(useMainStore),
	},
});
