
import { ExerciseTestCase } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import CodeFragment from "../ui/CodeFragment.vue";
export default defineComponent({
	name: "ExerciseTestCase",
	props: {
		testCase: {
			type: Object as PropType<ExerciseTestCase>,
			required: true,
		},
		small: {
			type: Boolean,
			default: false,
		},
		showDescription: {
			type: Boolean,
			default: true,
		},
	},
	methods: {},
	computed: {},
	components: { CodeFragment },
});
