
import { defineComponent } from "@vue/runtime-core";
import { courseIdMixin, coursePrivilegeMixin, loadingMixin } from "@/mixins";
import EventEditorPreview from "@/components/teacher/EventEditor/EventEditorPreview.vue";
import { Course, CoursePrivilege, Event, EventType, Exercise } from "@/models";
import MinimalExercisePreview from "@/components/teacher/ExerciseEditor/MinimalExercisePreview.vue";
import Btn from "@/components/ui/Btn.vue";
import EventEditorPreviewSkeleton from "@/components/ui/skeletons/EventEditorPreviewSkeleton.vue";
import MinimalExercisePreviewSkeleton from "@/components/ui/skeletons/MinimalExercisePreviewSkeleton.vue";
import TextInput from "@/components/ui/TextInput.vue";
import Toggle from "@/components/ui/Toggle.vue";
import Card from "@/components/ui/Card.vue";
import TextEditor from "@/components/ui/TextEditor.vue";

import useVuelidate from "@vuelidate/core";

import { courseValidation } from "@/validation/models";
import {
	demoTeacherTourSteps,
	teacherTourSteps,
	tourOptions,
	newMaterialModuleTourSteps,
} from "@/const";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";
import { isDemoMode, setErrorNotification } from "@/utils";

const DEMO_COURSE_DASHBOARD_TOUR_KEY = "course_dashboard_tour_taken";
const NEW_MATERIAL_MODULE_TOUR_KEY = "new_material_module_tour_taken";

import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";

export default defineComponent({
	name: "CourseDashboard",
	components: {
		EventEditorPreview,
		MinimalExercisePreview,
		Btn,
		EventEditorPreviewSkeleton,
		MinimalExercisePreviewSkeleton,
		TextInput,
		Toggle,
		Card,
		TextEditor,
		CopyToClipboard,
	},
	mixins: [courseIdMixin, loadingMixin, coursePrivilegeMixin],
	setup() {
		return { v$: useVuelidate() };
	},
	async created() {
		this.loadingEvents = true;
		this.loadingExercises = true;
		try {
			await this.mainStore.getEvents({
				courseId: this.courseId,
				filters: { event_type: EventType.EXAM },
			});
		} catch (e) {
			setErrorNotification(e);
		} finally {
			this.loadingEvents = false;
		}
		try {
			await this.mainStore.getExercises({
				courseId: this.courseId,
				fromFirstPage: true,
				filters: null,
			});
		} catch {
			this.showExercises = false;
		} finally {
			this.loadingExercises = false;
		}
	},
	props: {
		showTour: {
			type: Boolean,
			default: false,
		},
	},
	mounted() {
		if (this.showTour) {
			setTimeout(() => (this.$tours["teacherTour"] as any).start(), 500);
		}
		if (isDemoMode() && !(DEMO_COURSE_DASHBOARD_TOUR_KEY in localStorage)) {
			setTimeout(() => (this.$tours["demoTeacherTour"] as any).start(), 500);
			localStorage.setItem(DEMO_COURSE_DASHBOARD_TOUR_KEY, "true");
		}
		if (!this.showTour && !(NEW_MATERIAL_MODULE_TOUR_KEY in localStorage)) {
			setTimeout(() => (this.$tours["newMaterialModuleTour"] as any).start(), 500);
			localStorage.setItem(NEW_MATERIAL_MODULE_TOUR_KEY, "true");
		}
	},
	data() {
		return {
			editingName: false,
			dirtyCourseName: "",
			editingDescription: false,
			dirtyCourseDescription: "",
			CoursePrivilege,
			teacherTourSteps,
			demoTeacherTourSteps,
			newMaterialModuleTourSteps,
			tourOptions,
			loadingEvents: false,
			loadingExercises: false,
			showExercises: true,
		};
	},
	validations() {
		return {
			dirtyCourse: courseValidation,
		};
	},
	methods: {
		editCourseName() {
			this.dirtyCourseName = this.currentCourse.name;
			this.editingName = true;
		},
		editCourseDescription() {
			this.dirtyCourseDescription = this.currentCourse.description ?? "";
			this.editingDescription = true;
		},
		async onDoneEditingName(discard = false) {
			if (!discard) {
				await this.withLocalLoading(async () => {
					await this.mainStore.updateCourse({
						...this.currentCourse,
						name: this.dirtyCourseName,
					});
					this.editingName = false;
				});
			} else {
				this.editingName = false;
			}
		},
		async onDoneEditingDescription(discard = false) {
			if (!discard) {
				await this.withLocalLoading(async () => {
					await this.mainStore.updateCourse({
						...this.currentCourse,
						description: this.dirtyCourseDescription,
					});
					this.editingDescription = false;
				});
			} else {
				this.editingDescription = false;
			}
		},
		async onChangeCourseVisibility(value: boolean) {
			await this.withLoading(async () => {
				await this.mainStore.updateCourse({ ...this.currentCourse, hidden: value });
			});
		},
	},
	computed: {
		...mapStores(useMainStore),
		recentExams(): Event[] {
			return this.mainStore.exams?.slice(0, 3) ?? [];
		},
		recentExercises(): Exercise[] {
			return this.mainStore.exercises?.slice(0, 4) ?? [];
		},
		dirtyCourse(): Course {
			return {
				...this.currentCourse,
				name: this.dirtyCourseName,
				description: this.dirtyCourseDescription,
			};
		},
		permalink(): string {
			return (
				window.location.origin +
				this.$router.resolve({
					name: "StudentCourseDashboard",
					params: { courseId: this.courseId },
				}).fullPath
			);
		},
	},
});
