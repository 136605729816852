
import { defineComponent, PropType } from "@vue/runtime-core";
import { v4 as uuid4 } from "uuid";
import MultiIcon from "@/components/ui/MultiIcon.vue";
import { SelectableOption } from "@/interfaces";

export default defineComponent({
	name: "SegmentedControls",
	props: {
		options: {
			type: Array as PropType<SelectableOption[]>,
			required: true,
		},
		modelValue: {
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
	},
	components: {
		MultiIcon,
	},
	created() {
		this.id = uuid4();
	},
	data() {
		return {
			showFeedback: false,
			id: "",
		};
	},
	methods: {
		onInput(value: string, inputEvent: Event) {
			// eslint-disable-next-line @typescript-eslint/no-extra-semi
			(inputEvent.target as unknown as { checked: boolean }).checked = false;
			inputEvent.preventDefault();
			this.$emit("update:modelValue", value);
		},
	},
});
