import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "flex flex-col items-center w-full mx-auto mt-16" }
const _hoisted_2 = {
  style: {"font-size":"10rem"},
  class: "material-icons-outlined opacity-10"
}
const _hoisted_3 = {
  key: 0,
  class: "mt-2 mb-4 text-muted text-lg"
}
const _hoisted_4 = {
  key: 1,
  class: "opacity-40"
}
const _hoisted_5 = {
  key: 2,
  class: "mt-2 mb-4 text-muted text-lg"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Btn = _resolveComponent("Btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("p", _hoisted_2, _toDisplayString(_ctx.isMaintenanceMode ? "engineering" : _ctx.data.icon), 1),
    (_ctx.isMaintenanceMode)
      ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.$t("misc.maintenance_mode")), 1))
      : (!_ctx.hasCustomMessage)
        ? (_openBlock(), _createElementBlock("h2", _hoisted_4, _toDisplayString(_ctx.data.title), 1))
        : (_openBlock(), _createElementBlock("p", _hoisted_5, _toDisplayString(_ctx.$t("server_messages.error." + _ctx.data.content)), 1)),
    _createVNode(_component_Btn, {
      class: "mt-2",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$router.go(0)))
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t("errors.retry")), 1)
      ]),
      _: 1
    })
  ]))
}