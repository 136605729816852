import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, renderSlot as _renderSlot, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "combo-box" }
const _hoisted_2 = { class: "relative" }
const _hoisted_3 = {
  key: 0,
  style: {"font-size":"12.5px"},
  class: "absolute -bottom-5 left-0 text-muted"
}
const _hoisted_4 = { class: "" }
const _hoisted_5 = ["for"]
const _hoisted_6 = { class: "flex py-3 hover:bg-light cursor-pointer -mx-5 pl-5 pr-8" }
const _hoisted_7 = ["onInput", "onClick", "id", "value", "checked"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TextInput = _resolveComponent("TextInput")!
  const _component_DropdownMenu = _resolveComponent("DropdownMenu")!
  const _directive_wave = _resolveDirective("wave")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_DropdownMenu, {
      expanded: _ctx.expanded,
      onToggleExpanded: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onToggleExpanded())),
      loading: _ctx.shouldShowLoading
    }, {
      activator: _withCtx(() => [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_TextInput, {
            leftIcon: _ctx.leftIcon,
            modelValue: _ctx.searchText,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchText) = $event)),
            placeholder: _ctx.placeholder || undefined
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.label), 1)
            ]),
            _: 1
          }, 8, ["leftIcon", "modelValue", "placeholder"]),
          (_ctx.hint)
            ? (_openBlock(), _createElementBlock("p", _hoisted_3, _toDisplayString(_ctx.hint), 1))
            : _createCommentVNode("", true)
        ])
      ]),
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.filteredItems, (item, index) => {
            return (_openBlock(), _createElementBlock("label", {
              for: _ctx.id + '-input-' + index,
              key: item[_ctx.itemId]
            }, [
              _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_6, [
                _createElementVNode("input", {
                  onInput: ($event: any) => (_ctx.onInput(item.value, $event)),
                  onClick: ($event: any) => (item.value == _ctx.modelValue ? (_ctx.expanded = false) : null),
                  class: "w-0 h-0 opacity-0",
                  type: "radio",
                  id: _ctx.id + '-input-' + index,
                  value: item.value,
                  checked: item.value == _ctx.modelValue
                }, null, 40, _hoisted_7),
                _renderSlot(_ctx.$slots, "default", {
                  item: item,
                  searchText: _ctx.searchText
                })
              ])), [
                [_directive_wave]
              ])
            ], 8, _hoisted_5))
          }), 128)),
          (_ctx.filteredItems.length === 0 && !_ctx.isCreatableFunction(_ctx.searchText))
            ? (_openBlock(), _createElementBlock("div", _hoisted_8, [
                _renderSlot(_ctx.$slots, "noResults", { searchText: _ctx.searchText })
              ]))
            : _createCommentVNode("", true),
          (_ctx.filteredItems.length === 0 && _ctx.isCreatableFunction(_ctx.searchText))
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _withDirectives((_openBlock(), _createElementBlock("div", {
                  class: "flex py-2 hover:bg-light cursor-pointer -mx-5 px-5",
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.onCreateOption()))
                }, [
                  _renderSlot(_ctx.$slots, "createOption", { searchText: _ctx.searchText })
                ])), [
                  [_directive_wave]
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 3
    }, 8, ["expanded", "loading"])
  ]))
}