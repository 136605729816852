import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"z-index":"9999"},
  class: "fixed bottom-3 left-0"
}
const _hoisted_2 = {
  class: "w-full px-4 py-3 rounded opacity-100 shadow-popup text-lightText bg-dark bg-opacity-95 border-danger-dark",
  role: "alert"
}
const _hoisted_3 = { class: "flex items-center space-x-2" }
const _hoisted_4 = { class: "material-icons-outlined" }
const _hoisted_5 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, _toDisplayString(_ctx.icon), 1),
        _createElementVNode("div", null, [
          _createElementVNode("p", _hoisted_5, _toDisplayString(_ctx.message), 1)
        ])
      ])
    ])
  ]))
}