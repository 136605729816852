
import draggable from "vuedraggable";

import CourseTreeNode from "@/components/course_tree/node/CourseTreeNode.vue";
import { SelectableOption } from "@/interfaces";
import {
	blockingDialogMixin,
	courseIdMixin,
	coursePrivilegeMixin,
	loadingMixin,
	savingMixin,
} from "@/mixins";
import {
	CoursePrivilege,
	CourseTreeNode as ICourseTreeNode,
	CourseTreeNodeType,
	FileNode,
	getBlankAnnouncementNode,
	getBlankFileNode,
	getBlankLessonNode,
	getBlankPollNode,
	getBlankTopicNode,
	TopicNode,
} from "@/models";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import { defineComponent } from "@vue/runtime-core";
import { mapStores } from "pinia";
import { icons as courseTreeNodeTypeIcons } from "@/assets/courseTreeNodeTypeIcons";
import { getTranslatedString as _ } from "@/i18n";
import Dialog from "@/components/ui/Dialog.vue";
import { setErrorNotification, setPageWideError } from "@/utils";
import CourseTreeNodeEditor from "@/components/course_tree/editors/CourseTreeNodeEditor.vue";
import { getCourseTopicNodes } from "@/api";
import DropdownMenu from "@/components/ui/DropdownMenu.vue";
import { AutoSaveManager, FieldList } from "@/autoSave";
import VueEternalLoading from "@ts-pro/vue-eternal-loading/src/components/VueEternalLoading/VueEternalLoading.vue";
import { LoadAction } from "@ts-pro/vue-eternal-loading";
import Spinner from "@/components/ui/Spinner.vue";
import { onChangeNodePosition } from "@/components/course_tree/shared";

export default defineComponent({
	name: "CourseTree",
	props: {},
	mixins: [
		courseIdMixin,
		loadingMixin,
		blockingDialogMixin,
		savingMixin,
		coursePrivilegeMixin,
	],
	async created() {
		await this.withLoading(
			async () =>
				await this.mainStore.getCourseTopLevelNodes({
					courseId: this.courseId,
					fromFirstPage: true,
				}),
			setPageWideError,
		);
		await this.mainStore.getCourseRootId({ courseId: this.courseId });
		this.topics = await getCourseTopicNodes(this.courseId);
		this.selectedTopicId = this.mainStore.courseIdToTreeRootId[this.courseId];
	},
	watch: {
		"editingNode.id"(newVal) {
			// re-instantiate (or dispose of) auto save manager each time
			// a new node is edited (or no node is edited anymore)
			if (newVal) {
				this.instantiateAutoSaveManager(this.editingNode as ICourseTreeNode);
			} else {
				this.editingNodeAutoSaveManager = null;
			}
			this.editingNodeUnsavedChanges = {};
		},
	},
	data() {
		return {
			CourseTreeNodeType,
			createMenuExpanded: false,
			showEditorDialog: false,
			editingNode: null as null | ICourseTreeNode,
			editingNodeAutoSaveManager: null as null | AutoSaveManager<ICourseTreeNode>,
			editingNodeUnsavedChanges: {},
			autoSaveEditingNode: true,
			uploadingFile: false,
			editingTopicName: "",
			selectedTopicId: "",
			topics: [] as TopicNode[],
			draggingNode: false,
			saving: false,
			savingError: false,
			blockingSaving: false,
			draftFileNode: null as null | FileNode,
			fileUploadProgress: undefined as undefined | number,
			isInitialInfiniteLoad: false,
		};
	},
	methods: {
		// onchs() {
		// 	setTimeout(() => {
		// 		var slides = document.getElementsByClassName("dragging-element");
		// 		for (var i = 0; i < slides.length; i++) {
		// 			console.log("EL", slides.item(i));
		// 			slides.item(i)?.classList.remove("dragging-element");
		// 		}
		// 	}, 10);
		// },
		async onLoadMore({ loaded, noMore, error }: LoadAction) {
			try {
				const moreResults = await this.mainStore.getCourseTopLevelNodes({
					courseId: this.courseId,
					fromFirstPage: false,
				});
				if (!moreResults) {
					noMore();
				} else {
					loaded();
				}
			} catch {
				error();
			}
		},
		async onNodeDragStart() {
			//this.draggingNode = true;
			this.mainStore.draggingCourseTreeNode = true;
		},
		async onNodeDragEnd(event: { oldIndex: number; newIndex: number }) {
			//this.draggingNode = false;
			this.mainStore.draggingCourseTreeNode = false;
			try {
				await onChangeNodePosition(
					this.courseId,
					this.topLevelNodes,
					event.oldIndex,
					event.newIndex,
				);
			} catch (e) {
				setErrorNotification(e);
			}
		},
		async onEditorBlur() {
			this.editingNodeAutoSaveManager?.flush();
		},
		onDismissNodeEditor() {
			if (
				Object.keys(this.editingNodeUnsavedChanges).length > 0 &&
				!confirm(_("course_tree.editor_discard_unsaved_changes"))
			) {
				return;
			}
			this.showEditorDialog = false;
			this.editingNode = null;
			this.editingNodeUnsavedChanges = {};
		},
		// TODO extract shared logic with NodeDetail
		instantiateAutoSaveManager(node: ICourseTreeNode) {
			this.editingNodeAutoSaveManager = new AutoSaveManager<ICourseTreeNode>(
				node,
				async changes => {
					await this.mainStore.partialUpdateCourseTreeNode({
						courseId: this.courseId,
						nodeId: node.id,
						changes,
					});
					this.saving = false;
				},
				changes => {
					this.saving = true;
					this.savingError = false;
					this.mainStore.patchCourseTreeNode({
						courseId: this.courseId,
						nodeId: node.id,
						changes,
					});
				},
				this.editingNodeDebouncedFields as FieldList<ICourseTreeNode>,
				2500,
				undefined,
				e => {
					this.saving = false;
					this.savingError = true;
					throw e;
				},
			);
		},
		async onEditingNodeChange<K extends keyof ICourseTreeNode>(
			key: K,
			value: ICourseTreeNode[K],
			save: boolean,
		) {
			if (this.autoSaveEditingNode) {
				await this.editingNodeAutoSaveManager?.onChange({ [key]: value });
			} else {
				// update local copy of unsaved changes
				this.editingNodeUnsavedChanges = {
					...this.editingNodeUnsavedChanges,
					[key]: value,
				};
			}
			if (save) {
				await this.onEditingNodeSave();
			}
		},
		async onEditingNodeSave() {
			/**
			 * Generic utility function that saves changes to a node. This is used
			 * under a few different circumstances:
			 *
			 * - when the node exists & autosave is on: this just closes the editor
			 * dialog and show a feedback, without any actual saving operation
			 * - when the node exists & autosave is off: this saves all unsaved
			 * changes to the node, closes the editor, and shows a feedback
			 * - when the node doesn't exist yet: this creates the node, saving
			 * all unsaved changes to it
			 */
			if (!this.editingNode) {
				throw new Error(
					"onEditingNodeSave called but editingNode is " + this.editingNode,
				);
			}
			if (!this.editingNode.id) {
				/**
				 * Create node
				 * */

				// used if creating a FileNode to keep track of upload progress for the file
				const onUploadProgress = (e: { loaded: number; total: number }) => {
					this.fileUploadProgress = (e.loaded / e.total) * 100;
					// make loader indeterminate after upload completes for
					//better visual feedback to the user
					if (this.fileUploadProgress === 100) {
						setTimeout(() => {
							this.fileUploadProgress = undefined;
						}, 100);
					}
				};
				try {
					// TODO if creating a file node, don't display loader in dialog
					this.blockingSaving = true;
					await this.mainStore.createCourseTreeNode({
						courseId: this.courseId,
						node: this.proxyEditingNode as ICourseTreeNode,
						config: {
							onUploadProgress,
						},
					});
					this.editingNodeUnsavedChanges = {};
					this.onDismissNodeEditor();
					this.metaStore.showSuccessFeedback();
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.blockingSaving = false;
					this.fileUploadProgress = 0;
				}
			} else if (!this.autoSaveEditingNode) {
				/**
				 * Save changes to existing node
				 */
				try {
					this.blockingSaving = true;
					await this.editingNodeAutoSaveManager?.onChange(this.editingNodeUnsavedChanges);
					await this.editingNodeAutoSaveManager?.flush();
					this.editingNodeUnsavedChanges = {};
					this.onDismissNodeEditor();
					this.metaStore.showSuccessFeedback();
				} catch (e) {
					setErrorNotification(e);
				} finally {
					this.blockingSaving = false;
				}
			} else {
				/**
				 * Autosave is on, so changes were already saved
				 * flush any pending changes & close editor
				 */
				await this.editingNodeAutoSaveManager?.flush();
				this.onDismissNodeEditor();
				this.metaStore.showSuccessFeedback();
			}
		},
		onEditNode(node: ICourseTreeNode) {
			/* TODO make deep copy of editing node and await a blocking dialog
			meanwhile, you save all changes in the deep copied object and if the user
			saves, you flush everything in the autosave manager.

			In order to be able to do this, you need to lift up the manager here
			instead of using it in the editors. The editors will simply emit patchNode({key: val})
			*/
			this.autoSaveEditingNode = false;
			this.editingNode = node;
			this.showEditorDialog = true;
		},
		async onDeleteNode(node: ICourseTreeNode) {
			// TODO extract duplicated code with other components
			if (!confirm(_("course_tree.delete_node_confirm"))) {
				return;
			}
			await this.withLoading(
				async () =>
					await this.mainStore.deleteCourseTreeNode({
						courseId: this.courseId,
						nodeId: node.id,
					}),
				setErrorNotification,
			);
		},
		async createFileNode(file: Blob) {
			this.uploadingFile = true;
			try {
				await this.mainStore.createCourseTreeNode({
					courseId: this.courseId,
					node: { ...getBlankFileNode(this.selectedTopicId), file },
				});
				this.metaStore.showSuccessFeedback();
				this.selectedTopicId = this.mainStore.courseIdToTreeRootId[this.courseId];
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.uploadingFile = false;
			}
		},
		async onCreateNode(nodeType: CourseTreeNodeType) {
			/**
			 * TODO refactor - move the declaration of factories to top,
			 * then based on the type of the node use the correct logic
			 * whether to create it immediately or turn autosave off &
			 * open editor dialog only
			 */
			if (nodeType === CourseTreeNodeType.TopicNode) {
				this.editingNode = getBlankTopicNode(null);
				this.autoSaveEditingNode = false;
				this.showEditorDialog = true;
			} else if (nodeType === CourseTreeNodeType.FileNode) {
				// don't create node on the server just yet
				this.editingNode = getBlankFileNode(
					this.mainStore.courseIdToTreeRootId[this.courseId],
				);
				this.autoSaveEditingNode = false;
				this.showEditorDialog = true;
			} else {
				// immediately create node & open editor
				const factories = {
					[CourseTreeNodeType.LessonNode]: getBlankLessonNode,
					[CourseTreeNodeType.AnnouncementNode]: getBlankAnnouncementNode,
					[CourseTreeNodeType.PollNode]: getBlankPollNode,
				};
				await this.withLoading(async () => {
					this.editingNode = await this.mainStore.createCourseTreeNode({
						courseId: this.courseId,
						node: factories[nodeType](null),
					});
					this.autoSaveEditingNode = true;
					this.showEditorDialog = true;
				}, setErrorNotification);
			}
		},
		async onLoadComments(node: ICourseTreeNode) {
			await this.mainStore.getCourseTreeNodeComments({
				courseId: this.courseId,
				nodeId: node.id,
			});
		},
		async onLoadChildren(
			node: ICourseTreeNode,
			fromFirstPage: boolean,
			resolveFn: (moreResults: boolean) => void,
			rejectFn: (e: any) => void,
		) {
			console.log("course tree on load children", fromFirstPage);
			try {
				const moreResults = await this.mainStore.getCourseTreeNodeChildren({
					courseId: this.courseId,
					nodeId: node.id,
					fromFirstPage,
				});
				resolveFn(moreResults);
			} catch (e) {
				rejectFn?.(e);
			}
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
		proxyEditingNode() {
			if (this.autoSaveEditingNode) {
				return this.editingNode;
			}
			return {
				...this.editingNode,
				...this.editingNodeUnsavedChanges,
			} as ICourseTreeNode;
		},
		fullScreenDialog() {
			return (
				this.editingNode?.resourcetype !== CourseTreeNodeType.TopicNode &&
				this.editingNode?.resourcetype !== CourseTreeNodeType.FileNode
			); //this.editingNode?.resourcetype === CourseTreeNodeType.LessonNode;
		},
		fullHeightDialog() {
			return (
				this.fullScreenDialog &&
				this.editingNode?.resourcetype !== CourseTreeNodeType.FileNode
			); //this.editingNode?.resourcetype === CourseTreeNodeType.LessonNode;
		},
		largeDialog() {
			return (
				!this.fullScreenDialog &&
				// display a smaller dialog for topic editor, as it only includes a
				// single input field and feels less like an "editor"
				this.editingNode?.resourcetype !== CourseTreeNodeType.TopicNode
			);
		},
		editorDialogLoading() {
			return (
				this.blockingSaving &&
				// don't display loader in dialog for file nodes, as their editor
				// already shows a loader on the file upload component
				this.editingNode?.resourcetype !== CourseTreeNodeType.FileNode
			);
		},
		nodeTypesAsSelectableOptions(): SelectableOption[] {
			return (Object.keys(CourseTreeNodeType) as any[]).map(key => ({
				content: _("course_tree_node_types." + key),
				icons: courseTreeNodeTypeIcons[key],
				value: key,
			}));
		},
		topLevelNodes() {
			return this.mainStore.paginatedTopLevelCourseTreeNodes?.data ?? [];
		},
		canEditNodes() {
			return this.hasPrivileges([CoursePrivilege.MANAGE_MATERIAL]);
		},
		canCreateNodes() {
			return this.hasPrivileges([CoursePrivilege.MANAGE_MATERIAL]);
		},
		editingNodeDebouncedFields() {
			if (!this.editingNode) {
				return [];
			}
			const debouncedFields: Record<CourseTreeNodeType, string[]> = {
				AnnouncementNode: ["body"],
				TopicNode: ["name"],
				FileNode: [],
				PollNode: ["text"],
				LessonNode: ["body", "title"],
			};
			return debouncedFields[this.editingNode.resourcetype];
		},
	},
	components: {
		draggable,
		CourseTreeNode,
		DropdownMenu,
		Dialog,
		VueEternalLoading,
		CourseTreeNodeEditor,
		Spinner,
	},
});
