
import Btn from "@/components/ui/Btn.vue";

import ExerciseEditor from "@/components/teacher/ExerciseEditor/ExerciseEditor.vue";
//import ExercisePreview from "@/components/teacher/ExerciseEditor/ExercisePreview.vue";
import { Exercise } from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import { texMixin } from "@/mixins";
import { getTranslatedString } from "@/i18n";
import { getExerciseTitle } from "@/utils";
import MinimalExercisePreview from "./MinimalExercisePreview.vue";
export default defineComponent({
	name: "ExerciseEditorWrapper",
	components: {
		ExerciseEditor,
		// ExercisePreview,
		Btn,
		MinimalExercisePreview,
	},
	mixins: [texMixin],
	props: {
		exercise: {
			type: Object as PropType<Exercise>,
			required: true,
		},
		selectable: {
			type: Boolean,
			default: true,
		},
		editable: {
			type: Boolean,
			default: true,
		},
	},
	data() {
		return {
			showEditor: false,
			saving: false,
			dialog: false,
		};
	},
	//created () {},
	methods: {
		toggleExpand() {
			this.showEditor = !this.showEditor;
			if (!this.showEditor) {
				this.triggerTexRender();
			}
		},
		onClone() {
			if (
				confirm(
					getTranslatedString("exercise_editor.clone_confirm") +
						" " +
						getExerciseTitle(this.exercise) +
						"?",
				)
			) {
				this.$emit("cloneExercise");
			}
		},
	},
});
