import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = { class: "darken-on-hover" }
const _hoisted_2 = ["disabled", "maxlength", "value", "placeholder"]
const _hoisted_3 = { class: "text-base text-muted material-icons-outlined" }
const _hoisted_4 = {
  key: 2,
  class: "absolute bottom-0.5 right-1.5 -z-1"
}
const _hoisted_5 = { class: "text-base text-muted material-icons-outlined" }
const _hoisted_6 = {
  key: 0,
  class: "text-sm font-light text-danger-dark"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_LinearProgress = _resolveComponent("LinearProgress")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", {
      class: _normalizeClass([{
				'border-danger-dark focus-within:border-danger-dark': _ctx.$slots.errors,
				'py-1.5 px-2 rounded-t-sm border-b-2': !_ctx.searchBar,
				'py-2 px-3 rounded-full': _ctx.searchBar,
			}, "light-input relative z-10 bg-light hover:bg-gray-150 focus-within:border-primary duration-300 transition-border"])
    }, [
      (_ctx.loading)
        ? (_openBlock(), _createBlock(_component_LinearProgress, {
            key: 0,
            class: "absolute -bottom-1 rounded-b-sm left-0"
          }))
        : _createCommentVNode("", true),
      _createElementVNode("input", {
        disabled: _ctx.disabled,
        maxlength: _ctx.maxLength,
        type: "text",
        value: _ctx.modelValue,
        onBlur: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('blur'))),
        onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('update:modelValue', $event.target.value))),
        placeholder: _ctx.placeholder,
        class: _normalizeClass([{
					'floating-label-error': _ctx.$slots.errors?.(),
					'floating-label': !_ctx.fixedLabel,
					'pl-5': _ctx.leftIcon.length > 0,
				}, "z-10 block w-full bg-transparent appearance-none focus:outline-none"])
      }, null, 42, _hoisted_2),
      (_ctx.leftIcon.length > 0)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: _normalizeClass(['absolute -z-1', _ctx.searchBar ? 'left-3 bottom-1' : 'left-1.5 bottom-0.5'])
          }, [
            _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.leftIcon), 1)
          ], 2))
        : _createCommentVNode("", true),
      _createElementVNode("label", {
        class: _normalizeClass([{ 'top-2 left-1.5 origin-0 fixed-label': _ctx.fixedLabel }, "absolute bottom-1.5 origin-0 -z-1"])
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 2),
      (_ctx.rightIcon.length > 0)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.rightIcon), 1)
          ]))
        : _createCommentVNode("", true)
    ], 2),
    (_ctx.$slots.errors?.())
      ? (_openBlock(), _createElementBlock("div", _hoisted_6, [
          _renderSlot(_ctx.$slots, "errors")
        ]))
      : _createCommentVNode("", true)
  ]))
}