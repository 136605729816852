
const SHOW_INITIALLY_COUNT = 1;
import { getExerciseThreadRoute } from "./utils";

import {
	Exercise as IExercise,
	ExerciseSolution as IExerciseSolution,
	ExerciseSolutionState,
	getBlankExerciseSolution,
	ProgrammingExerciseType,
	programmingExerciseTypeToLanguageId,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "@/components/ui/Btn.vue";
import ExerciseSolution from "./ExerciseSolution.vue";
import ExerciseSolutionEditor from "./ExerciseSolutionEditor.vue";
import { courseIdMixin, loadingMixin, savingMixin } from "@/mixins";
import { logAnalyticsEvent, setErrorNotification } from "@/utils";
import { AutoSaveManager } from "@/autoSave";
import {
	EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_FIELDS,
	EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_TIME_MS,
} from "@/const";
import { getTranslatedString } from "@/i18n";
import { mapStores } from "pinia";
import { useMetaStore } from "@/stores/metaStore";
import { useMainStore } from "@/stores/mainStore";
export default defineComponent({
	name: "ExerciseSolutionContainer",
	props: {
		exercise: {
			type: Object as PropType<IExercise>,
			required: true,
		},
		solutions: {
			type: Array as PropType<IExerciseSolution[]>,
			required: true,
		},
		canLoadMore: {
			type: Boolean,
			default: false,
		},
		showFirst: {
			type: Array as PropType<string[]>,
			required: false,
		},
		standalone: {
			type: Boolean,
			default: false,
		},
		showTeacherControls: {
			type: Boolean,
			default: false,
		},
		showTitle: {
			type: Boolean,
			default: true,
		},
		allowAddSolution: {
			type: Boolean,
			default: true,
		},
		forceShowAll: {
			type: Boolean,
			default: false,
		},
		showExerciseThreadLink: {
			type: Boolean,
			default: false,
		},
		allowShowMore: {
			type: Boolean,
			default: true,
		},
		initialEditorContent: {
			type: String,
			default: "",
		},
	},
	mixins: [courseIdMixin, savingMixin, loadingMixin],
	methods: {
		onClose() {
			this.editingSolutionId = null;
			this.editingSolutionDeepCopy = null;
			this.autoSaveManager = null;
		},
		onShowMore() {
			if (!this.showAll && !this.forceShowAll) {
				this.showAll = true;
			} else {
				this.$emit("loadMore");
			}
		},
		async onDraftSolutionChange<K extends keyof IExerciseSolution>(
			key: K,
			value: IExerciseSolution[K],
		) {
			await this.autoSaveManager?.onChange({ [key]: value });
		},
		async onSolutionStateUpdate(
			solution: IExerciseSolution,
			newState: ExerciseSolutionState,
		) {
			this.instantiateAutoSaveManager(solution, true);
			this.publishing = true;
			try {
				logAnalyticsEvent("updatedExerciseSolutionState", {
					courseId: this.courseId,
					state: newState,
				});
				await this.autoSaveManager?.onChange({ state: newState });
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.autoSaveManager = null;
				this.publishing = false;
			}
		},
		instantiateLocalOnlyAutoSaveManager() {
			this.autoSaveManager = new AutoSaveManager<IExerciseSolution>(
				this.editingSolutionDeepCopy as IExerciseSolution,
				async changes => {
					if ("state" in changes) {
						await this.onDoneEditing();
					}
				},
				changes => {
					if (this.editingSolutionDeepCopy !== null) {
						this.editingSolutionDeepCopy = {
							...this.editingSolutionDeepCopy,
							...changes,
						};
					}
				},
				EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_FIELDS,
				EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_TIME_MS,
				undefined,
				() => (this.savingError = true),
				() => {
					this.saving = false;
					this.publishing = false;
				},
			);
		},
		instantiateAutoSaveManager(forSolution?: IExerciseSolution, revertOnFailure = false) {
			const solution = forSolution ?? (this.editingSolution as IExerciseSolution);
			this.autoSaveManager = new AutoSaveManager<IExerciseSolution>(
				solution,
				async changes => {
					if ("state" in changes) {
						this.publishing = true;
					}
					await this.mainStore.updateExerciseSolution({
						courseId: this.courseId,
						exerciseId: this.exercise.id,
						solution: { ...solution, ...changes },
					});
					if ("state" in changes) {
						this.onSolutionStateChange();
					}
				},
				changes => {
					this.saving = true;
					this.savingError = false;
					this.mainStore.setExerciseSolution({
						exerciseId: this.exercise.id,
						payload: {
							...solution,
							...changes,
						},
					});
				},
				EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_FIELDS,
				EXERCISE_SOLUTION_AUTO_SAVE_DEBOUNCE_TIME_MS,
				undefined,
				e => {
					this.savingError = true;
					setErrorNotification(e);
				},
				() => {
					this.saving = false;
					this.publishing = false;
				},
				revertOnFailure,
			);
		},
		async onAddSolution() {
			this.creatingSolution = true;
			try {
				const changed = await this.editDraftSolution();
				if (changed) {
					this.instantiateAutoSaveManager();
				}
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.creatingSolution = false;
			}
		},
		onEditSolution(solution: IExerciseSolution) {
			//this.editingSolutionId = solution.id;
			this.editingSolutionDeepCopy = JSON.parse(JSON.stringify(solution));
			this.instantiateLocalOnlyAutoSaveManager();
		},
		async onDeleteSolution(solution: IExerciseSolution) {
			if (!confirm(getTranslatedString("exercise_solution.confirm_deletion"))) {
				return;
			}
			await this.withLoading(
				async () =>
					await this.mainStore.deleteExerciseSolution({
						courseId: this.courseId,
						exerciseId: this.exercise.id,
						solutionId: solution.id,
					}),
				setErrorNotification,
			);
		},
		async editDraftSolution(): Promise<boolean> {
			if (this.editingSolution !== null) {
				return false;
			}
			if (this.draftSolutions.length > 0) {
				this.editingSolutionId = this.draftSolutions[0].id;
			} else {
				const newSolution = await this.mainStore.createExerciseSolution({
					courseId: this.courseId,
					exerciseId: this.exercise.id,
					solution: getBlankExerciseSolution(
						ExerciseSolutionState.DRAFT,
						this.initialEditorContent,
					),
				});
				this.editingSolutionId = newSolution.id;
			}
			return true;
		},
		onSolutionStateChange() {
			this.editingSolutionId = null;
			this.autoSaveManager = null;
			this.metaStore.showSuccessFeedback();
		},
		async onDoneEditing() {
			this.publishing = true;
			try {
				await this.mainStore.updateExerciseSolution({
					courseId: this.courseId,
					exerciseId: this.exercise.id,
					solution: this.editingSolutionDeepCopy as IExerciseSolution,
				});
				this.mainStore.setExerciseSolution({
					exerciseId: this.exercise.id,
					payload: this.editingSolutionDeepCopy as IExerciseSolution,
				});
				this.editingSolutionId = null;
				this.editingSolutionDeepCopy = null;
				this.autoSaveManager = null;
				this.metaStore.showSuccessFeedback();
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.publishing = false;
			}
		},
	},
	data() {
		return {
			saving: false,
			savingError: false,
			creatingSolution: false,
			publishing: false,
			editingSolutionId: null as string | null,
			autoSaveManager: null as AutoSaveManager<IExerciseSolution> | null,
			showAll: false,
			editingSolutionDeepCopy: null as IExerciseSolution | null,
			SHOW_INITIALLY_COUNT,
		};
	},
	computed: {
		...mapStores(useMetaStore, useMainStore),
		canShowMore() {
			return (
				this.allowShowMore &&
				(this.canLoadMore || this.shownSolutions.length < this.nonDraftSolutions.length)
			);
		},
		shownSolutions(): IExerciseSolution[] {
			const ret = [...this.nonDraftSolutions];
			if (this.showFirst) {
				ret.sort((a, _) =>
					(this.showFirst ?? []).map(s => String(s)).includes(String(a.id)) ? -1 : 0,
				);
			}
			return ret.filter(
				(_, i) => this.showAll || this.forceShowAll || i < SHOW_INITIALLY_COUNT,
			);
		},
		draftSolutions(): IExerciseSolution[] {
			return this.solutions.filter(s => s.state === ExerciseSolutionState.DRAFT);
		},
		nonDraftSolutions(): IExerciseSolution[] {
			return (this.solutions ?? []).filter(s => s.state !== ExerciseSolutionState.DRAFT);
		},
		editingSolution(): IExerciseSolution | null {
			if (this.editingSolutionId === null) {
				return null;
			}
			return (this.solutions ?? []).find(s => s.id == this.editingSolutionId) ?? null;
		},
		threadPermalink() {
			return this.$router.resolve(
				getExerciseThreadRoute(this.courseId, this.exercise.id),
			);
		},
		solutionType() {
			const code =
				programmingExerciseTypeToLanguageId[
					this.exercise.exercise_type as ProgrammingExerciseType
				];
			return code ?? "text";
		},
	},
	components: {
		Btn,
		ExerciseSolution,
		ExerciseSolutionEditor,
	},
});
