
import { defineComponent } from "@vue/runtime-core";
import TextInput from "@/components/ui/TextInput.vue";
import TextEditor from "@/components/ui/TextEditor.vue";
import { getBlankCourse } from "@/models";
import Toggle from "@/components/ui/Toggle.vue";
import FileUpload from "../../components/ui/FileUpload.vue";
import Btn from "@/components/ui/Btn.vue";
import useVuelidate from "@vuelidate/core";

import { loadingMixin } from "@/mixins";
import { courseValidation } from "@/validation/models";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";

export default defineComponent({
	name: "CourseCreationForm",
	components: { TextInput, TextEditor, Toggle, FileUpload, Btn },
	setup() {
		return { v$: useVuelidate() };
	},
	mixins: [loadingMixin],
	data() {
		return {
			course: getBlankCourse(),
		};
	},
	validations() {
		return {
			course: courseValidation,
		};
	},
	methods: {
		onFileUploadClick() {
			alert("Questa funzionalità non è ancora disponibile");
		},
		async onCreate() {
			await this.withLocalLoading(async () => {
				const course = await this.mainStore.createCourse(this.course);
				await this.mainStore.getCourses();
				this.$router.push({
					name: "TeacherCourseDashboard",
					params: {
						courseId: course.id,
						showTour: 1,
					},
				});
			});
			this.metaStore.showSuccessFeedback();
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
	},
});
