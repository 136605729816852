
import { getTranslatedString } from "@/i18n";
import { DataFormat, getImportedData, ImportedExerciseData } from "@/integrations";
import { SelectableOption } from "@/interfaces";
import { loadingMixin } from "@/mixins";
import { Exercise, ExerciseState } from "@/models";
import { getFileContent, setErrorNotification } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import MinimalExercisePreview from "./ExerciseEditor/MinimalExercisePreview.vue";
import Dropdown from "../ui/Dropdown.vue";
import { exerciseStateOptions } from "@/const";
import Chipset from "../ui/Chipset.vue";
import ArticleHandle from "../shared/HelpCenter/ArticleHandle.vue";
export default defineComponent({
	name: "ExerciseImporter",
	props: {},
	mixins: [loadingMixin],
	watch: {
		exercises: {
			deep: true,
			handler(newVal) {
				this.$emit("updateExercises", newVal);
			},
		},
	},
	data() {
		return {
			DataFormat,
			selectedFormat: null as DataFormat | null,
			currentStep: "initial" as "initial" | "file_chosen",
			exercises: [] as Exercise[],
			extras: {} as Record<string, any>,
			exerciseStateOptions,
		};
	},
	methods: {
		async onFileInputChange(event: { target: HTMLInputElement }) {
			if (event.target.files === null || event.target.files.length === 0) {
				return;
			}
			try {
				const file = event.target.files[0];
				const fileContents = await getFileContent(file);
				const importedData: ImportedExerciseData = await getImportedData(
					fileContents,
					this.selectedFormat as DataFormat,
				);
				if ((importedData.errors?.length ?? 0) === 0) {
					this.exercises = importedData.data;
					this.extras = importedData.extras;
					this.currentStep = "file_chosen";
				} else {
					// TODO handle errors
					setErrorNotification(importedData.errors?.[0], true);
				}
			} catch (e) {
				setErrorNotification(e);
			}
		},
		onSelect(format: DataFormat) {
			if (format === this.selectedFormat) {
				this.selectedFormat = null;
			} else {
				this.selectedFormat = format;
			}
		},
		getImageUrl(imageName: string) {
			// eslint-disable-next-line no-undef
			return require("@/assets/" + imageName);
		},
	},
	computed: {
		tagsAsOptions(): SelectableOption[] {
			if ((this.extras.tags?.length ?? 0) === 0) {
				return [];
			}
			return (this.extras.tags as string[])
				.filter(t => t.length > 0)
				.map(t => ({
					value: t,
					content: t,
					icons: this.selectedTagsProxy.includes(t) ? ["done-sm"] : [],
				}));
		},
		dataFormatsAsSelectableOptions(): SelectableOption[] {
			return [
				{
					value: DataFormat.MOODLE_XML,
					content: getTranslatedString(
						"exercise_import.formats." + DataFormat.MOODLE_XML,
					),
					description: this.getImageUrl("icons/moodle.png"),
				},
				{
					value: DataFormat.EVO_JSON,
					content: getTranslatedString("exercise_import.formats." + DataFormat.EVO_JSON),
					description: this.getImageUrl("icons/json_evo.png"),
				},
			];
		},
		importedExercisesStateProxy: {
			get() {
				return this.exercises[0]?.state;
			},
			set(val: ExerciseState) {
				for (const e of this.exercises) {
					e.state = val;
				}
			},
		},
		selectedTagsProxy: {
			get() {
				return (this.exercises[0]?.public_tags ?? []).map(t => t.name);
			},
			set(val: string[]) {
				for (const e of this.exercises) {
					e.public_tags = val.map(v => ({ name: v }));
				}
			},
		},
	},
	components: { MinimalExercisePreview, Dropdown, Chipset, ArticleHandle },
});
