
const MAX_CONTENT_HEIGHT_PX = 300;
import {
	CodeExecutionResults as ICodeExecutionResults,
	Exercise,
	ExerciseSolution,
	ExerciseSolutionState,
	getComment,
	getVote,
	ProgrammingExerciseType,
	programmingExerciseTypeToLanguageId,
	VoteType,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "@/components/ui/Btn.vue";
import TextInput from "@/components/ui/TextInput.vue";
import Avatar from "@/components/ui/Avatar.vue";
import ProcessedTextFragment from "@/components/ui/ProcessedTextFragment.vue";
import { courseIdMixin, coursePrivilegeMixin, mediaQueryMixin, texMixin } from "@/mixins";
import { setErrorNotification } from "@/utils";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";
import { getExerciseSolutionThreadRoute } from "./utils";
import { getTranslatedString as _ } from "@/i18n";
import CodeFragment from "@/components/ui/CodeFragment.vue";
import Tooltip from "@/components/ui/Tooltip.vue";
import { mapStores } from "pinia";
import { useMetaStore } from "@/stores/metaStore";
import { useMainStore } from "@/stores/mainStore";
import MessageEditor from "@/components/messaging/MessageEditor.vue";
import Message from "@/components/messaging/Message.vue";

export default defineComponent({
	name: "ExerciseSolution",
	mixins: [courseIdMixin, coursePrivilegeMixin, mediaQueryMixin, texMixin],
	props: {
		exercise: {
			type: Object as PropType<Exercise>,
			required: true,
		},
		solution: {
			type: Object as PropType<ExerciseSolution>,
			required: true,
		},
		canVote: {
			type: Boolean,
			default: true,
		},
		canChangeState: {
			type: Boolean,
			default: false,
		},
		canComment: {
			type: Boolean,
			default: true,
		},
		forceExpanded: {
			type: Boolean,
			default: false,
		},
		showTeacherControls: {
			type: Boolean,
			default: false,
		},
		publishing: {
			type: Boolean,
			default: false,
		},
	},
	created() {
		this.triggerTexRender();
	},
	mounted() {
		// TODO stop using ref and use getElementById

		// workaround for https://sentry.io/organizations/samuele/issues/3527964852/?project=6265941&query=is%3Aunresolved
		// the `content` ref might not be available yet, so loop until it is not null anymore
		this.intervalHandle = setInterval(() => {
			const contentElement = this.$refs.content as HTMLElement;
			if (!contentElement) {
				console.warn("ref element is null");
				// ref element isn't available yet
				return;
			}
			if (
				contentElement.clientHeight > this.MAX_CONTENT_HEIGHT_PX &&
				!this.forceExpanded
			) {
				this.collapsed = true;
			}
			this.calculatingHeight = false;
			clearInterval(this.intervalHandle as number);
			this.intervalHandle = null;
		}, 30);
	},
	data() {
		return {
			voting: false,
			postingComment: false,
			bookmarking: false,
			VoteType,
			ExerciseSolutionState,
			MAX_CONTENT_HEIGHT_PX,
			collapsed: false,
			showAnimation: false,
			calculatingHeight: true,
			intervalHandle: null as number | null,
			executionResults: {} as ICodeExecutionResults,
			runningCode: false,
			ws: null as null | WebSocket,
		};
	},
	methods: {
		// async runCode() {
		// 	const taskId = uuid4();
		// 	const taskMessage = {
		// 		task_id: taskId,
		// 		exercise_id: this.exercise.id,
		// 		code: this.solution.content,
		// 		action: "run_code",
		// 	};
		// 	this.runningCode = true;
		// 	this.ws = await openAuthenticatedWsConnection(
		// 		"code_runner",
		// 		s => s.send(JSON.stringify(taskMessage)),
		// 		m => {
		// 			const payload = JSON.parse(m.data);
		// 			if (payload.action === "execution_results") {
		// 				this.executionResults = JSON.parse(payload.data);
		// 				this.runningCode = false;
		// 				this.ws?.close();
		// 			}
		// 		},
		// 	);
		// },
		async onVote(voteType: VoteType) {
			if (this.voting) {
				return;
			}
			this.voting = true;
			try {
				const vote =
					(this.solution.has_upvote && voteType === VoteType.UP_VOTE) ||
					(this.solution.has_downvote && voteType === VoteType.DOWN_VOTE)
						? undefined // removing vote
						: getVote(voteType); // adding/updating vote

				await this.mainStore.createExerciseSolutionVote({
					courseId: this.courseId,
					exerciseId: this.exercise.id,
					solutionId: this.solution.id,
					vote,
				});
				if (typeof vote !== "undefined") {
					// TODO refactor animation
					this.showAnimation = true;
				}
				// TODO fix this with an action
				// eslint-disable-next-line vue/no-mutating-props
				// this.solution.votes.push(getVote(voteType));
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.voting = false;
			}
		},
		async onAddComment(message: string, resolveFn: () => void, rejectFn: () => void) {
			this.postingComment = true;
			try {
				await this.mainStore.createExerciseSolutionComment({
					courseId: this.courseId,
					exerciseId: this.exercise.id,
					solutionId: this.solution.id,
					comment: getComment(message),
				});
				resolveFn();
			} catch (e) {
				setErrorNotification(e);
				rejectFn();
			} finally {
				this.postingComment = false;
			}
		},
		async onToggleBookmark() {
			this.bookmarking = true;
			try {
				await this.mainStore.setExerciseSolutionBookmark({
					courseId: this.courseId,
					exerciseId: this.exercise.id,
					solutionId: this.solution.id,
					bookmarked: !this.solution.is_bookmarked,
				});
			} catch (e) {
				setErrorNotification(e);
			} finally {
				this.bookmarking = false;
			}
		},
		onUpdateState(newState: ExerciseSolutionState) {
			this.$emit("updateState", newState);
		},
	},
	computed: {
		...mapStores(useMetaStore, useMainStore),
		// TODO extract to utils
		solutionType() {
			const code =
				programmingExerciseTypeToLanguageId[
					this.exercise.exercise_type as ProgrammingExerciseType
				];
			return code ?? "text";
		},
		authorName(): string {
			return this.solution.user?.full_name ?? _("exercise_solution.default_author");
		},
		isOwnSolution(): boolean {
			return (this.solution.user?.id ?? "") == this.metaStore.user.id;
		},
		canEdit(): boolean {
			return this.isOwnSolution || this.hasAnyPrivileges();
		},
		permalink(): string {
			return (
				window.location.origin +
				this.$router.resolve(
					getExerciseSolutionThreadRoute(
						this.courseId,
						this.exercise.id,
						this.solution.id,
					),
				).fullPath
			);
		},
	},
	components: {
		Btn,
		Avatar,
		ProcessedTextFragment,
		CopyToClipboard,
		CodeFragment,
		Tooltip,
		MessageEditor,
		Message,
	},
});
