
import { defineComponent, PropType } from "@vue/runtime-core";
export default defineComponent({
	name: "LinearProgress",
	props: {
		progress: {
			type: Number,
			required: false,
			validator: (v: number) => v >= 0 && v <= 100,
		},
	},
	methods: {},
	computed: {
		indeterminate() {
			return typeof this.progress === "undefined";
		},
	},
});
