import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  style: {"z-index":"999999"},
  class: "fixed w-10/12 transform -translate-x-1/2 -translate-y-1/2 bg-gray-200 border rounded-md shadow-2xl bg-opacity-90 top-1/2 left-1/2 md:w-max md:ml-0"
}
const _hoisted_2 = { class: "flex items-center w-full px-6 py-8 space-x-4" }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "text-yellow-900 bg-yellow-500 icon-surrounding" }
const _hoisted_5 = { class: "ml-px material-icons-outlined" }
const _hoisted_6 = { class: "mb-0" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AnimatedIcon = _resolveComponent("AnimatedIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (!_ctx.icon)
        ? (_openBlock(), _createBlock(_component_AnimatedIcon, { key: 0 }))
        : (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createElementVNode("span", _hoisted_5, _toDisplayString(_ctx.icon), 1)
            ])
          ])),
      _createElementVNode("h3", _hoisted_6, _toDisplayString(_ctx.text || _ctx.$t("misc.success")), 1)
    ])
  ]))
}