import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "absolute top-2 z-10 left-1.5 origin-0 fixed-label" }
const _hoisted_3 = { class: "tags-input-container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VueTagsInput = _resolveComponent("VueTagsInput")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_VueTagsInput, {
        "add-only-from-autocomplete": _ctx.existingOnly,
        modelValue: _ctx.tag,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tag) = $event)),
        class: "",
        tags: _ctx.processedModelValue,
        "allow-edit-tags": true,
        placeholder: _ctx.$slots.default ? '' : _ctx.placeholder,
        onBeforeAddingTag: _cache[1] || (_cache[1] = ($event: any) => (_ctx.beforeAddingTag($event))),
        onBeforeDeletingTag: _cache[2] || (_cache[2] = ($event: any) => (_ctx.beforeDeletingTag($event))),
        "autocomplete-items": _ctx.autoCompleteItems,
        "autocomplete-min-length": 
					_ctx.alwaysShowAutocomplete ? 0 : _ctx.allowAutocomplete ? 1 : 10000000000000
				,
        "autocomplete-filter-duplicates": false
      }, null, 8, ["add-only-from-autocomplete", "modelValue", "tags", "placeholder", "autocomplete-items", "autocomplete-min-length"])
    ])
  ]))
}