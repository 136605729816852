
import {
	EventParticipationSlot,
	EventParticipationSlotAssessment,
	EventParticipationSlotSubmission,
	Exercise as IExercise,
	ExerciseSolution,
	ExerciseType,
	programmingExerciseTypes,
} from "@/models";
import { defineComponent, PropType } from "@vue/runtime-core";
import { getTranslatedString as _ } from "@/i18n";
import Timestamp from "../ui/Timestamp.vue";
import { texMixin } from "@/mixins";
import Exercise from "./Exercise/Exercise.vue";
import ParticipationSlotAssessment from "./ParticipationSlotAssessment.vue";
import { isOpenAnswerExercise, isProgrammingExercise } from "./Exercise/utils";
import ClozeExercise from "./Exercise/ClozeExercise.vue";

export default defineComponent({
	components: {
		Timestamp,
		Exercise,
		ParticipationSlotAssessment,
		ClozeExercise,
	},
	emits: {
		blur(slot: EventParticipationSlot) {
			return true;
		},
		saveAssessment(payload: {
			slot: EventParticipationSlot;
			changes: { score: number | null; comment: string };
		}) {
			return true;
		},
		updateSubmission(payload: {
			slot: EventParticipationSlot;
			payload: [keyof EventParticipationSlotSubmission, any];
		}) {
			return true;
		},
		updateAssessment(payload: {
			slot: EventParticipationSlot;
			payload: [keyof EventParticipationSlotAssessment, any];
		}) {
			return true;
		},
		setAssessmentExpanded(payload: { slot: EventParticipationSlot; payload: boolean }) {
			return true;
		},
		runCode(slot: EventParticipationSlot) {
			return true;
		},
	},
	name: "AbstractEventParticipationSlot",
	props: {
		modelValue: {
			type: Object as PropType<EventParticipationSlot>,
			required: true,
		},
		subSlot: {
			type: Boolean,
			default: false,
		},
		allowEditAssessment: {
			// should be used when accessing as a teacher to assess the slot
			type: Boolean,
			default: false,
		},
		allowEditSubmission: {
			// whether user should be able to edit the submission fields (answer text, selected choices etc.)
			// to be used by students during the participation to an event
			type: Boolean,
			default: false,
		},
		showExerciseLabel: {
			// whether the exercise label (i.e. name of the exercise) should be displayed
			// to be used when displaying the exercise preview
			type: Boolean,
			default: false,
		},
		showSolutionAndScores: {
			// whether the exercises' solutions and choices' scores should be displayed
			// to be used when reviewing a participation to a practice event
			type: Boolean,
			default: false,
		},
		showAnswer: {
			// whether the exercise's readOnlyAnswer prop should be shown, if enabled
			type: Boolean,
			default: true,
		},
		showAssessmentCard: {
			// whether the card with teacher assessment fields (scores and comments) should be displayed.
			// to be used when assessments are made available and a student displays them
			type: Boolean,
			default: false,
		},
		assessmentWriteOnly: {
			// whether the assessment controls should be displayed "stand-alone",
			// without the ability to collapse them and show the current assessment
			type: Boolean,
			default: false,
		},
		assessmentControlsVisibility: {
			// whether the card containing the input fields to assess the slot should be displayed.
			// to be used when accessing a full participation as a teacher and the user is editing
			// the assessment fields of the slot (i.e. clicked on edit button)
			type: Object as PropType<Record<string, boolean>>, // Boolean,
			default: () => {},
		},
		assessmentLoading: {
			// used when dispatching changes to the assessment slots, to disable the button
			// until the action has succeeded
			type: Boolean,
			default: false,
		},
		saving: {
			type: Boolean,
			default: false,
		},
		showTags: {
			type: Boolean,
			default: false,
		},
	},
	mixins: [texMixin],
	created() {
		this.triggerTexRender();
	},
	methods: {
		onUpdateSubmission(change: [keyof EventParticipationSlotSubmission, any]) {
			this.$emit("updateSubmission", {
				slot: this.modelValue,
				payload: change,
			});
		},
		onUpdateAssessment(change: [keyof EventParticipationSlotAssessment, any]) {
			this.$emit("updateAssessment", {
				slot: this.modelValue,
				payload: change,
			});
		},
		setAssessmentExpanded(value: boolean) {
			this.$emit("setAssessmentExpanded", {
				slot: this.modelValue,
				payload: value,
			});
		},
		onSaveAssessment(changes: { score: number | null; comment: string }) {
			this.$emit("saveAssessment", {
				slot: this.modelValue,
				changes,
			});
		},
	},
	data() {
		return {
			ExerciseType,
		};
	},
	computed: {
		exercise(): IExercise {
			return this.modelValue.exercise;
		},
		assessment(): EventParticipationSlotAssessment {
			const { comment, score, score_edited, assessment_state } = this.modelValue;
			return {
				comment,
				score,
				score_edited,
				assessment_state,
			};
		},
		submission(): EventParticipationSlotSubmission {
			const { selected_choices, answer_text, execution_results, attachment } =
				this.modelValue;
			return {
				selected_choices,
				answer_text,
				execution_results,
				attachment,
			};
		},
		isProgrammingExercise() {
			return isProgrammingExercise(this.exercise);
		},
		isOpenAnswerExercise() {
			return isOpenAnswerExercise(this.exercise);
		},
		assessmentExpanded(): boolean {
			return (
				this.assessmentWriteOnly ||
				(this.assessmentControlsVisibility?.[this.modelValue.id] ?? false)
			);
		},
		someSubSlotsPending(): boolean {
			return this.modelValue.sub_slots.some(s => s.score === null);
		},
	},
});
