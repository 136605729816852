
import { defineComponent, PropType } from "@vue/runtime-core";
import TextEditor from "@/components/ui/TextEditor.vue";
import { Exercise, EventParticipationSlotSubmission, getEmptySubmission } from "@/models";
import AbstractExercise from "./AbstractExercise.vue";
import { exerciseProps } from "./shared";
import ProcessedTextFragment from "@/components/ui/ProcessedTextFragment.vue";
export default defineComponent({
	name: "OpenAnswerExercise",
	props: {
		...exerciseProps,
	},
	methods: {},
	computed: {
		answerTextProxy: {
			get() {
				return this.submission.answer_text;
			},
			set(val: string) {
				this.$emit("updateSubmission", ["answer_text", val]);
			},
		},
	},
	components: { TextEditor, AbstractExercise, ProcessedTextFragment },
});
