import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDirective as _resolveDirective, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "relative" }
const _hoisted_2 = { class: "absolute top-2 left-1.5 origin-0 fixed-label" }
const _hoisted_3 = { class: "w-full" }
const _hoisted_4 = ["for"]
const _hoisted_5 = { class: "absolute w-12 h-12 transition-all duration-100 ease-in-out rounded-full bg-opacity-10 bg-primary radio-shadow" }
const _hoisted_6 = ["onInput", "id", "value", "checked", "disabled"]
const _hoisted_7 = { class: "flex items-start space-x-2" }
const _hoisted_8 = { class: "flex flex-col" }
const _hoisted_9 = { class: "flex items-center" }
const _hoisted_10 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_MultiIcon = _resolveComponent("MultiIcon")!
  const _directive_wave_trigger = _resolveDirective("wave-trigger")!
  const _directive_wave = _resolveDirective("wave")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("label", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
        return (_openBlock(), _createElementBlock("label", {
          for: _ctx.id + '-input-' + index,
          class: _normalizeClass(["relative flex my-0.5 max-h-screen space-x-1.5 cursor-pointer items-start radio-item", { 'radio-container': !_ctx.disabled }]),
          key: _ctx.id + '-option-' + index
        }, [
          _withDirectives(_createElementVNode("div", _hoisted_5, null, 512), [
            [_directive_wave_trigger, void 0, "radio"],
            [_directive_wave, _ctx.disabled ? false : { trigger: 'radio', color: '#666ad1' }]
          ]),
          _createElementVNode("input", {
            onInput: ($event: any) => (_ctx.onInput(option.value, $event)),
            style: {"min-width":"15px","min-height":"15px"},
            class: "mt-5px input-radio",
            type: "radio",
            id: _ctx.id + '-input-' + index,
            value: option.value,
            checked: option.value == _ctx.modelValue,
            disabled: _ctx.disabled
          }, null, 40, _hoisted_6),
          _createElementVNode("div", _hoisted_7, [
            (option.icons)
              ? (_openBlock(), _createBlock(_component_MultiIcon, {
                  key: 0,
                  class: "w-6",
                  icons: option.icons
                }, null, 8, ["icons"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_8, [
              _createElementVNode("div", _hoisted_9, [
                _createElementVNode("p", {
                  class: "",
                  innerHTML: option.content
                }, null, 8, _hoisted_10),
                _renderSlot(_ctx.$slots, "itemSide", { option: option })
              ]),
              _renderSlot(_ctx.$slots, "item", {
                description: option.description
              })
            ])
          ])
        ], 10, _hoisted_4))
      }), 128))
    ])
  ]))
}