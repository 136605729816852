
import { getCourseTopicNodes } from "@/api";
import ArticleHandle from "@/components/shared/HelpCenter/ArticleHandle.vue";
import Btn from "@/components/ui/Btn.vue";
import Dropdown from "@/components/ui/Dropdown.vue";
import DropdownMenu from "@/components/ui/DropdownMenu.vue";
import FileUpload from "@/components/ui/FileUpload.vue";
import SegmentedControls from "@/components/ui/SegmentedControls.vue";
import TextInput from "@/components/ui/TextInput.vue";
import { getTranslatedString as _ } from "@/i18n";
import { SelectableOption } from "@/interfaces";
import { courseIdMixin } from "@/mixins";
import { FileNode, TopicNode } from "@/models";
import { useMainStore } from "@/stores/mainStore";
import { setErrorNotification } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import { nodeEditorEmits, nodeEditorProps } from "../shared";
export default defineComponent({
	name: "FileNodeEditor",
	props: {
		modelValue: {
			type: Object as PropType<FileNode>,
			required: true,
		},
		...nodeEditorProps,
	},
	emits: {
		...nodeEditorEmits,
	},
	mixins: [courseIdMixin],
	async created() {
		this.loadingTopics = true;
		try {
			this.topics = await getCourseTopicNodes(this.courseId);
		} catch (e) {
			setErrorNotification(e);
		} finally {
			this.loadingTopics = false;
		}
	},
	data() {
		return {
			panes: [
				{
					content: _("course_tree.file_node_editor_file_upload"),
					value: "file_upload",
				},
				{
					content: _("course_tree.file_node_editor_url"),
					value: "url",
				},
			] as SelectableOption[],
			currentPane: "file_upload",
			// TODO extract repeated code from this editor & lesson editor
			topics: [] as TopicNode[],
			loadingTopics: false,
		};
	},
	methods: {
		onNodeChange<K extends keyof FileNode>(key: any, value: any, save = false) {
			this.$emit("patchNode", { key, value, save });
		},
		onParentChange(parentId: string) {
			this.onNodeChange("parent_id", parentId);
		},
	},
	computed: {
		...mapStores(useMainStore),
		fileProxy: {
			get() {
				return [];
			},
			set(val: Blob) {
				this.onNodeChange("file", val, true);
			},
		},
		// TODO refactor, duplicated with other editors - move up to abstract component & pass as prop
		topicsAsOptions(): SelectableOption[] {
			return [
				{
					value: this.mainStore.courseIdToTreeRootId[this.courseId],
					content: _("course_tree.no_topic"),
				},
				...this.topics.map(t => ({
					value: t.id,
					content: t.name,
				})),
			];
		},
	},
	components: { SegmentedControls, FileUpload, Btn, Dropdown, TextInput, ArticleHandle },
});
