
import { defineComponent, PropType } from "@vue/runtime-core";
export default defineComponent({
	name: "CircularProgress",
	props: {
		value: {
			type: Number,
			required: true,
		},
		total: {
			type: Number,
			required: true,
		},
		displayType: {
			type: String as PropType<"percentage" | "fraction">,
			default: "fraction",
		},
		size: {
			type: String as PropType<"sm" | "md" | "lg">,
			default: "md",
		},
	},
	methods: {},
	computed: {
		percentage() {
			return Math.floor((this.value / this.total) * 100);
		},
		sideLength() {
			return this.size === "sm" ? 3.5 : this.size === "md" ? 7 : 10;
		},
		style() {
			return {
				width: this.sideLength + "rem",
				height: this.sideLength + "rem",
				"font-size": this.sideLength / 4.25 + "rem",
				"--value": this.percentage,
			};
		},
	},
});
