import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([
			_ctx.params.value == _ctx.ParticipationAssessmentProgress.PARTIALLY_ASSESSED
				? 'text-yellow-900'
				: 'text-success',
			'pt-2 ml-1 text-lg material-icons-outlined',
		])
  }, _toDisplayString(_ctx.assessmentStateIcons[_ctx.params.value][0]), 3))
}