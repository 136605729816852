
import Btn from "@/components/ui/Btn.vue";
import CopyToClipboard from "@/components/ui/CopyToClipboard.vue";
import LinearProgress from "@/components/ui/LinearProgress.vue";
import Timestamp from "@/components/ui/Timestamp.vue";
import { courseIdMixin, nodeMixin } from "@/mixins";
import { FileNode } from "@/models";
import { useMainStore } from "@/stores/mainStore";
import { getDefaultThumbnail, getHumanFileSize, sameDay } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import FileNodeDetail from "../node_detail/FileNodeDetail.vue";
import { nodeEmits, nodeProps } from "../shared";
export default defineComponent({
	name: "FileNode",
	props: {
		node: {
			type: Object as PropType<FileNode>,
			required: true,
		},
		loadingFile: {
			type: Boolean,
			default: false,
		},
		...nodeProps,
	},
	emits: { ...nodeEmits },
	mixins: [courseIdMixin, nodeMixin],
	async created() {
		this.loadingThumbnail = true;
		try {
			await this.mainStore.loadCourseTreeNodeThumbnail({
				courseId: this.courseId,
				nodeId: this.node.id,
			});
			this.thumbnailLoaded = true;
		} finally {
			this.loadingThumbnail = false;
		}
	},
	data() {
		return {
			showDetails: false,
			loadingThumbnail: false,
			thumbnailLoaded: false,
		};
	},
	methods: {},
	computed: {
		...mapStores(useMainStore),
		thumbnailPresent() {
			return (
				!this.loadingThumbnail &&
				this.thumbnailLoaded &&
				!!this.mainStore.thumbnailByCourseNodeId[this.node.id]
			);
		},
		thumbnailSrc() {
			const thumbnail = this.mainStore.thumbnailByCourseNodeId[this.node.id];
			if (!thumbnail || !this.thumbnailLoaded) {
				return "";
			}
			return `data:image/jpeg;base64,${thumbnail}`;
		},
		defaultThumbnail() {
			return getDefaultThumbnail(this.node.mime_type);
		},
		humanReadableFileSize() {
			return getHumanFileSize(this.node.file?.size ?? 0);
		},
		updatedOnDifferentDay() {
			return !sameDay(new Date(this.node.created), new Date(this.node.modified));
		},
	},
	components: { LinearProgress, FileNodeDetail, Timestamp, CopyToClipboard, Btn },
});
