
import { getTranslatedString as _ } from "@/i18n";
import { SelectableOption } from "@/interfaces";
import { courseIdMixin, eventIdMixin, loadingMixin } from "@/mixins";
import {
	getPresets,
	ReportField,
	ReportSettings,
	ReportSettingsPreset,
	ReportType,
} from "@/reports/examParticipations";
import { getParticipationsAsCsv } from "@/reports/examParticipations";
import { forceFileDownload, setErrorNotification } from "@/utils";
import { defineComponent, PropType } from "@vue/runtime-core";
import Btn from "../ui/Btn.vue";
import Dialog from "../ui/Dialog.vue";
import RadioGroup from "../ui/RadioGroup.vue";
import SegmentedControls from "../ui/SegmentedControls.vue";
import CheckboxGroup from "../ui/CheckboxGroup.vue";
import { EventParticipation } from "@/models";
import { mapStores } from "pinia";
import { useMainStore } from "@/stores/mainStore";

const LOCAL_STORAGE_SETTINGS_KEY = "report_settings";
const CUSTOM_SETTINGS_KEY = "custom_settings";
export default defineComponent({
	name: "CsvParticipationDownloader",
	props: {},
	mixins: [loadingMixin, courseIdMixin, eventIdMixin],
	components: { Btn, Dialog, RadioGroup, SegmentedControls, CheckboxGroup },
	watch: {
		settingsAsJson(newVal, _oldVal) {
			const oldVal = JSON.parse(_oldVal);
			if (_oldVal) {
				localStorage.setItem(LOCAL_STORAGE_SETTINGS_KEY, newVal);
			}
		},
		// TODO ! find a way to keep in-sync (the one below re-fetches too often)
		// eventParticipations: {
		// 	deep: true,
		// 	handler() {
		// 		// re-fetch report data if participations' data changes
		// 		this.apiCallPromise = (async () =>
		// 			(this.participations = await this.getReportData()))();
		// 	},
		// },
	},
	created() {
		// restore last used settings
		if (LOCAL_STORAGE_SETTINGS_KEY in localStorage) {
			const settings = JSON.parse(
				localStorage.getItem(LOCAL_STORAGE_SETTINGS_KEY) as string,
			);
			this.reportType = settings.reportType ?? ReportType.CSV;
			this.selectedDownloadPreset =
				settings.selectedDownloadPreset ?? ReportSettingsPreset.MAT_AND_SCORES;
			if (CUSTOM_SETTINGS_KEY in settings) {
				Object.assign(this.availablePresets[ReportSettingsPreset.CUSTOM], {
					...this.availablePresets[ReportSettingsPreset.CUSTOM],
					...settings[CUSTOM_SETTINGS_KEY],
				});
			}
		}

		// pre-fetch data and save returned Promise so it can be awaited
		// when user actually tries to download participations
		this.apiCallPromise = (async () =>
			(this.participations = await this.getReportData()))();
	},
	data() {
		return {
			loadingReport: false,
			showDialog: false,
			downloadSettings: null as ReportSettings | null,
			selectedDownloadPreset: ReportSettingsPreset.MAT_AND_SCORES,
			availablePresets: getPresets(),
			reportType: ReportType.CSV,
			ReportSettingsPreset,
			apiCallPromise: null as Promise<any> | null,
			participations: [] as EventParticipation[],
		};
	},
	methods: {
		async onDownload() {
			this.loadingReport = true;
			try {
				const participations = await this.apiCallPromise;
				const fileContents = this.formatDataForReportType(participations);
				this.downloadReportFile(
					fileContents,
					this.mainStore.getEventById(this.eventId).name +
						(this.reportType === ReportType.CSV ? ".csv" : ".pdf"),
				);
			} finally {
				setTimeout(() => (this.loadingReport = false), 1500);
			}
		},
		async getReportData() {
			try {
				const participations = await this.mainStore.getEventParticipations({
					courseId: this.courseId,
					eventId: this.eventId,
					includeDetails: true,
					forCsv: this.reportType === ReportType.CSV,
					mutate: false,
				});
				return participations;
			} catch (e) {
				setErrorNotification(e);
				return [];
			}
		},
		formatDataForReportType(data: any) {
			if (this.reportType === ReportType.CSV) {
				return getParticipationsAsCsv(data, this.downloadSettingsProxy).replace(
					/(\\r)?\\n/g,
					"\n",
				);
			}
			// TODO implement PDF
			throw new Error("pdf unimplemented");
		},
		downloadReportFile(data: any, filename: string) {
			forceFileDownload(
				{
					data,
				},
				filename,
			);
		},
	},
	computed: {
		...mapStores(useMainStore),
		settingsAsJson(): string {
			return JSON.stringify({
				reportType: this.reportType,
				selectedDownloadPreset: this.selectedDownloadPreset,
				[CUSTOM_SETTINGS_KEY]: this.availablePresets[ReportSettingsPreset.CUSTOM],
			});
		},
		downloadSettingsProxy: {
			get() {
				return this.availablePresets[this.selectedDownloadPreset];
			},
			set(val: ReportSettings) {
				Object.assign(this.downloadSettingsProxy, val);
			},
		},
		formatsAsOptions(): SelectableOption[] {
			return [
				{
					value: ReportType.CSV,
					content: _("misc.csv"),
				},
				{
					value: ReportType.PDF,
					content: _("misc.pdf"),
				},
			];
		},
		presetsAsOptions(): SelectableOption[] {
			return [
				{
					value: ReportSettingsPreset.MAT_AND_SCORES,
					content: _("participation_downloader.mat_and_scores_title"),
				},
				{
					value: ReportSettingsPreset.FULL_NAME_AND_SCORES,
					content: _("participation_downloader.full_name_and_scores_title"),
				},
				{
					value: ReportSettingsPreset.ALL_FIELDS,
					content: _("participation_downloader.all_fields_title"),
					description: _("participation_downloader.all_fields_description"),
				},
				{
					value: ReportSettingsPreset.CUSTOM,
					content: _("participation_downloader.custom_title"),
					description: _("participation_downloader.custom_description"),
				},
			];
		},
		fieldsAsOptions(): SelectableOption[] {
			return [
				{
					value: ReportField.STUDENT_MAT,
					content: _("report_fields." + ReportField.STUDENT_MAT),
				},
				{
					value: ReportField.STUDENT_FULL_NAME,
					content: _("report_fields." + ReportField.STUDENT_FULL_NAME),
				},
				{
					value: ReportField.STUDENT_EMAIL,
					content: _("report_fields." + ReportField.STUDENT_EMAIL),
				},
				{
					value: ReportField.STUDENT_COURSE,
					content: _("report_fields." + ReportField.STUDENT_COURSE),
				},
				{
					value: ReportField.SCORE,
					content: _("report_fields." + ReportField.SCORE),
				},
				{
					value: ReportField.EXERCISES_LABEL,
					content: _("report_fields." + ReportField.EXERCISES_LABEL),
				},
				{
					value: ReportField.EXERCISES_ANSWER,
					content: _("report_fields." + ReportField.EXERCISES_ANSWER),
				},
				{
					value: ReportField.EXERCISES_SCORE,
					content: _("report_fields." + ReportField.EXERCISES_SCORE),
				},
			];
		},
	},
});
