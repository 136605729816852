import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FileUpload = _resolveComponent("FileUpload")!
  const _component_AbstractExercise = _resolveComponent("AbstractExercise")!

  return (_openBlock(), _createBlock(_component_AbstractExercise, _normalizeProps(_guardReactiveProps(_ctx.$props)), {
    submissionControls: _withCtx(() => [
      _createVNode(_component_FileUpload, {
        disabled: _ctx.readOnly,
        modelValue: _ctx.attachmentProxy,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.attachmentProxy) = $event)),
        onDownload: _ctx.onDownloadAttachment
      }, null, 8, ["disabled", "modelValue", "onDownload"])
    ]),
    _: 1
  }, 16))
}