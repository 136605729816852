
import Btn from "@/components/ui/Btn.vue";
import TextInput from "@/components/ui/TextInput.vue";
import { getTranslatedString as _ } from "@/i18n";
import { courseIdMixin, savingMixin } from "@/mixins";
import { CourseTreeNode, TopicNode } from "@/models";
import { useMainStore } from "@/stores/mainStore";
import { useMetaStore } from "@/stores/metaStore";
import { defineComponent, PropType } from "@vue/runtime-core";
import { mapStores } from "pinia";
import { nodeEditorEmits, nodeEditorProps } from "../shared";
export default defineComponent({
	name: "TopicNodeEditor",
	props: {
		modelValue: {
			type: Object as PropType<TopicNode>,
			required: true,
		},
		...nodeEditorProps,
	},
	emits: {
		...nodeEditorEmits,
	},
	mixins: [courseIdMixin, savingMixin],
	data() {
		return {};
	},
	async created() {
		await this.mainStore.getCourseRootId({ courseId: this.courseId });
	},
	methods: {
		onNodeChange<K extends keyof CourseTreeNode>(
			key: any, //K,
			value: any, //CourseTreeNode[K],
			save = false,
		) {
			// TODO extract shared behavior (mixin?)
			this.$emit("patchNode", { key, value, save });
		},
		onSave() {
			this.$emit("save");
		},
		onBlur() {
			this.$emit("blur");
		},
	},
	computed: {
		...mapStores(useMainStore, useMetaStore),
	},
	components: {
		TextInput,
		Btn,
	},
});
