import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex items-center" }
const _hoisted_2 = { class: "" }
const _hoisted_3 = {
  class: "material-icons text-lg mx-auto",
  style: {"font-size":"20px !important","padding-top":"12.5px !important"}
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        class: _normalizeClass([_ctx.hasAnswer ? 'text-success opacity-80' : 'text-muted opacity-50'])
      }, [
        _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.hasAnswer ? "done" : "remove"), 1)
      ], 2)
    ])
  ]))
}